//#region IMPORTS
import React, { Fragment, useState } from 'react'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Row, Table, Alert } from 'reactstrap';
import { BtnSaveWorkOrder, CheckTextEmail, CheckTextPrint, CheckTextWhats, FontSize, LabelPrinterPoint, PlaceHolderOption } from '../../../constant';
import { useWorkOrder } from '../../../hooks/workOrder/useWorkOrder';
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { TableWorkOrder } from '../../../components/elements/TableWorkOrder';
import { ModalViewExams } from '../../../components/workOrders/ModalViewExams';
import { ModalPatient } from '../../../components/patients/ModalPatient';
import { ModalDoctor } from '../../../components/doctors/ModalDoctor';
import { ModalSpecialDiscount } from '../../../components/workOrders/ModalSpecialDiscount';
import { ModalCommonDiscounts } from '../../../components/workOrders/ModalCommonDiscounts';
import { ModalQuoter } from '../../../components/workOrderQuoter/ModalQuoter';
import { ModalInvoice } from '../../../components/workOrderInvoice/ModalInvoice';
import { ModalRepeatProfile } from '../../../components/workOrders/ModalRepeatProfile';
import { IncomeTypeWorkOrder } from './IncomeTypeWorkOrder';
import { TableWorkOrderUpdate } from '../../../components/elements/TableWorkOrderUpdate';
import { TableSupplies } from '../../../components/elements/TableSupplies';
import { ModalDevolution } from '../../../components/workOrders/ModalDevolution';
import { ModalLiquidacion } from '../../../components/workOrders/ModalLiquidacion';
import { ModalAuthorize } from '../../../components/workOrders/ModalAuthorize';
import Select from 'react-select';
import { AvailablePayMethodByCompany } from '../../../constant/workOrder';
import './StyleWorkOrder.css';
import 'animate.css';
import { ModalUpdateMethod } from '../../../components/workOrders/ModalUpdateMethod';
import { ModalAutorization } from '../../../components/workOrders/ModalAutorization';



//#endregion

export const IndexWorkOrder = () => {
    //SE OBTIENE DIRECTO DEL TOKEN 
    let enable_whatsapp = localStorage.getItem('enable_whatsapp');
    const enable_tracking_toma = localStorage.getItem('enable_tracking_toma');
    let _gatos = localStorage.getItem('configDoctor');
    //console.log("new",_gatos);
    let _adminFinance = localStorage.getItem('admin_finance'); 
    
    const {
        modalTitle, number_format,

        //GLOBAL VARIABLES
        isEditWorkOrder, loadingEditWorkOrder,

        //#region WORK ORDER
        //VALUES
        checkTypeClient,
        listPrinterPoint, listPayMethod, arrayAgreementDiscounts,
        id_patient, id_company, id_printer_point,
        observations, observations_sample, observations_general, checkPrint, checkPartial, checkEmail, checkWhats,
        percentageDiscount, showDiscountWarning, showDiscountWarningCredit, showDiscountWarningPay,
        checkDoctor, checkInvoice, listTest, listPayMethods, listDoctors,
        listCompanies, listAgreements, listTypeService, listType,
        times, loading,
        searchExamProfileState, subTotalW, totalDiscountW, totalTaxesW, totalW, totalDevolutions,
        showMessageW, showMessageWF, showClassSD, showClass, OrderMedicalEstatus, viewExamsProfile, validationsWorkOrder, viewWorkOrder,
        enableBtnSaveWorkOrder, validationsRepeat, checkComments, ShowClassNameC, ShowDiscount, checkDiscopunt,
        devolutionsW, isPayForCompany, requiredInvoiceCompany, availableInvoicePatient,
        searchExtra, listSupplies, isFullyPaid,
        idAgreementCompany,
        //FILTER TYPE SEARCH
        typeSearch,

        //FILES PATIENT
        medical_order, handleOnChange, DeleteFile,

        //METHODS
        handleChangeTypeClient, handleInputChangeWorkOrder, handleDinamicInputWorkOrder, handleSelectValuesWorkOrder,
        handleSelectExamChange, changeSelectSamplings
        , changeValueTimeSampling,
        removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, handleAddPayMethodInputs, handleGiveChange,
        handleDeletePayMethod, handleViewExamsProfile, saveWorkOrder, cancellWorkOrder,
        handleChangeTypeFilterTest, saveUpdateWorkOrder, handleChangeExternalLaboratories,
        removeSupplies, handleChangeGeneratePay, handleChangeClient, handleChangeAgreement,

        //MODALS WORK ORDER
        toggleModalViewExams, modalViewExamsP, modalRepeatProfile, toggleRepeatProfile,
        toggleModalDevolutions, modalDevolutions,
        //#endregion

        //#region PATIENT
        //VALUES
        formValuesPatient, listBloodType, formSocialGender,
        searchPatientState, informationPatient, listStates, listMunicipalities, listTaxRegime, listUseCfdiPatient,
        modalPatient, togglePatient,
        methodPatient, loadingPatient,
        handleInputChangePatient, handleInputChangeSocialGender, handleSelectValuesPatient,
        handlePickerValuesPatient, validationsPatient, validationsSG,
        toggleModalScannQr, modalScannQrPatient, focusQrPatient, inputReadQrCode, Listsocialgender, modalSocialGender,
        isInvoiceService, setIsInvoiceService,
        viewNewSocialGender, setViewNewSocialGender,
        //METHODS
        handleChangePatient,
        handleChangeStatePatient, handleDatePatient, handleChangeTypeService,
        handleCreatePatient, saveCreatePatient,
        handleUpdatePatient, saveUpdatePatient,
        handleScannQrPatient, toggleModalSocialGender, saveSocialGender,
        handleChangeTypePerson, handleChangeTaxRegime, handleChangeSocialGender,
        //#endregion

        //#region DOCTOR
        //VALUES
        formDoctor, informationDoctor, id_work_order, workOrderId,
        modalDoctor, toggleDoctor,
        methodDoctor, loadingDoctor, validaciones1,
        handleInputChangeDoctor, nameInputsDoctor, validationsDoctor,
        handleChangeDoctor, specialties, searchDoctorState,
        id_specialty, name_doctor, professional_license, phone_doctor,
        address_doctor, email_doctor, name_specialty, isCommercialPartner,
        // ? 
        code,

        //METHODS
        handleCreateDoctor, saveCreateDoctor,
        handleUpdateDoctor, saveUpdateDoctor, handleSaveDoctor,
        //#endregion

        //#region SPECIAL DISCOUNTS
        //VALUES
        specialDiscount, special_discount_code, handleInputChangeSpecialDiscount,

        //SPECIAL DISCOUNTS METHODS
        handleModalSpecialDiscount, validateCodeSpecialDiscount,

        //MODALS SPECIAL DISCOUNTS
        modalSpecialDiscounts, toggleSpecialDiscount,
        //#endregion

        //#region COMMON DISCOUNTS
        //VALUES
        arrayCommonDiscounts, arrayNameDiscount,
        selectedCampaign, listCampaigns,

        //METHODS
        handleModalListCommonDiscounts, handleModalSocialGender,
        clearDiscount, handleChangeCampaign, removeExamProfileByCampaign,

        //MODALS COMMON DISCOUNTS
        modalCommonDiscount, toggleCommonDiscount,
        //#endregion

        //#region QUOTER
        //VALUES
        loadingQuoter, code_quoter, validationsQuoter, date_quoter,
        handleInputChangeQuoter, listQuoter, enableViewTest, viewTestQuoter,

        //METHODS
        handleGetQuoter, handleSearchQuoter, searchQuoterByCode,
        handleViewTest, handleCloseViewTest,

        //MODALS QUOTER
        modalQuoter, toggleModalQuoter, methodQuoter,
        //#endregion

        //#region INVOICE
        //VALUES
        validationsInvoice, business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice, listUseCfdi,
        loadingInvoice, id_work_order_type_service,
        //METHODS
        handleCreateInvoice, saveInvoice, cancelInvoice, enableComments, enableDiscount,

        //MODALS INVOICE
        modalInvoice, toggleModalInvoice, methodInvoice,
        //#endregion

        //#region PATIENT QR
        qrCodeAutoFocus, onKeyPressQr,
        user_unique_code, handleSelectValuesQr,
        //#endregion

        //#region SCAN INE
        setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE, handleOnChangeINEU,
        //#endregion

        //#region DEVOLUTIONS
        listDevolutions,
        handleChangeDevolution, handleCancelDevolution,
        validateDevolutions, saveDevolution,
        //#endregion

        //#region PAYMENT BILLS
        //MODALS
        modalPayBills, toggleModalPayBills,
        //MODELS
        id_method_liquided, amount_liquided, id_method_pay, amount_liq,
        handleInputChangePaymentBills, validationsPaymentBills,

        //METHODS
        handlePayBill, savePaymentBill,
        //#endregion

        //#region CANCELL WORK ORDER UPDATE
        //MODALS
        modalCancelWorkOrderUpdate, toggleModalCancellWorkOrderUpdate,

        //MODELS
        user_authorize, password_authorize, id_return_reason_woc, percentage_return,
        handleInputChangeCancelWorkOrder, totalCancellationWorkOrder,

        //VALIDATIONS
        validationsCancelWorkOrder,

        //METHODS
        handleCancelWorkOrderUpdate, handleValidateReturnAmount, saveCancelWorkOrderUpdate,
        //#endregion
        validatePercentajeSpecialDiscount, percentage,

        //CALCULO PARA FECHA DEL PACIENTE
        getAge, getBirthday, handleWheel, validPorcentejeSpecial, doctList,
        handleDeleteDoctor, validHopitalInterfaced, id_agreementBranch, validRestrictCompany,

        //#region SUPPLIES
        handleSelectSupplies, disableEdit, newDiscount, validateCommonDiscountDetail, setValidateCommonDiscountDetail,
        //#endregion SUPPLIES

        //*MODAL PAY METHOD
        modalPreviewUpdate, toggleModalUpdate, handleUpdateMethod,

        //*UPDATE METHOD
        user_method, user_password_method, id_pay_method_method, handleInputChangeMethod,

        //*SAVE PAY METHOD
        saveUpdateMethod,

        //*VALID BUTTON
        validButton,

        //*CONFIGURATION ROLES FINANCE
        configuration_role, finance,
        //!FINANCIAL METHODS
        modalAutorization, toggleModalAutorization, PreviewAutorization, handleCancelAutorization, name_User, isEdit_agremment,
        handleSendNotificationInvoices,
        //VOTRE CHOISE
        study_delivery, validTotalCredit, validatePassword, aunthentication, validatePasswordCancell, validatePasswordFinance,
        loadingPayment, loadingUpdateMethod,nim_Notification
    } = useWorkOrder();

    //!VALIDATION AGREMMENT
    let _valid_url = isEditWorkOrder ? isEdit_agremment : id_agreementBranch
    console.log("🚀 ~ IndexWorkOrder ~ id_agreementBranch:", id_agreementBranch)
    console.log("🚀 ~ IndexWorkOrder ~ isEdit_agremment:", isEdit_agremment)
    console.log("🚀 ~ IndexWorkOrder ~ isEditWorkOrder:", isEditWorkOrder)

    // id_agreementBranch

    //console.log(isEdit_agremment);
    //console.log(formDoctor);
    //console.log("doc",listDoctors);

        //  validRestrictCompany === "True" && checkTypeClient === 3
        //                                                             ? `Profiles/SearchProfileExamCompany?id_agreement=${_valid_url}`
    
    ////console.log('study_delivery: ', study_delivery)

    console.log(validRestrictCompany);
    console.log(checkTypeClient);
    console.log(_valid_url);
    
    
    

    const showButton = validPorcentejeSpecial === 'https://labcen.helen-sw.com';
    const isButtonDisabled = isEditWorkOrder ? (!enableBtnSaveWorkOrder || !showButton) : false;

    const {
        id_patient_valid,
        id_printer_point_valid,
        id_company_valid,
        listTest_valid,
        listPayMethods_valid,
        listid,
        id_work_order_type_service_valid
    } = validationsWorkOrder;

    const AlertItem = props => {
        const { item } = props;

        return (
            <Alert className="alert-dismissible"
                color={item.alertcolor}
                isOpen={true}
                target={"Alert-" + item.id}
            >
                {item.icon}
                <p>{item.alerttxt}</p>
            </Alert>
        );
    }

    const [openNodes, setOpenNodes] = useState({});
    const [openNodes1, setOpenNodes1] = useState({});
  
    const handleToggle = (index) => {
      setOpenNodes(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    };
  
    const handleToggle1 = (parentIndex, index) => {
      setOpenNodes1(prevState => ({
        ...prevState,
        [parentIndex]: {
          ...(prevState[parentIndex] || {}),
          [index]: !prevState[parentIndex]?.[index]
        }
      }));
    };

      const TreeNode = ({ obj, index, openNodes, openNodes1, handleToggle, handleToggle1 }) => {
        const isOpen = openNodes[index] || false;
      
        return (
          <div key={`node-${index}`} className='tree-node'>
            <div className='node-header' onClick={() => handleToggle(index)}>
              <i className={`fa fa-plus-square f-16 icon-Exam ${isOpen ? 'closed' : 'open'}`}></i>
              <b className='text-WorkOrder'>{obj.name.toUpperCase()}</b>
            </div>
            {isOpen && (
              <Col>
                {obj.listIndications === undefined || obj.listIndications.length === 0
                  ? <label className='PIndication'>-Sin indicaciones previas</label>
                  : <ul className='tree-list'>
                      {obj.listIndications.map((ind, i) => (
                        
                        <li key={`subItem-${i}`} onClick={() => handleToggle1(index, i)}>
                          <div>
                            <i className={ obj.id_profile !== null ? `icofont icofont-laboratory f-16 icon-Exam ${openNodes1[index] && openNodes1[index][i] ? 'closed' : 'open'}` : ` f-16 icon-Exam open'}` } >
                              <label style={{color:"#6c757d", fontFamily:"sans-serif", fontSize:"12px"}}>
                                {ind.name_exam}

                                { obj.id_profile === null &&  <ul className='tree-list'>
                            {obj.listIndications === undefined || obj.listIndications.length === 0
                                ? <li><label className='PIndication labelworkOrder'>-SIN INDICACIONES PREVIAS</label></li>
                                : obj.listIndications.map((a, c) => (
                                  <li key={`exaIndi-${c}`}>
                                    <label className='PIndication labelworkOrder'>-{a.indication}</label>
                                  </li>
                                ))
                              }
                            </ul>}  
                                </label>
                            </i>
                          </div>
                          {openNodes1[index] && openNodes1[index][i] && (
                            <div>
                            {obj.id_profile !== null && <ul className='tree-list'>
                              {ind.listIndications === undefined || ind.listIndications.length === 0
                                ? <li><label className='PIndication labelworkOrder'>-SIN INDICACIONES PREVIAS</label></li>
                                : ind.listIndications.map((a, c) => (
                                  <li key={`exaIndi-${c}`}>
                                    <label className='PIndication labelworkOrder'>-{a.indication}</label>
                                  </li>
                                ))
                              }
                            </ul>}
                            </div>                      
                          )}
                        </li>
                      ))}
                    </ul>
                }
              </Col>
            )}
          </div>
        );
      };


      console.log(idAgreementCompany);
      


    return (
        <Fragment>
            <Container fluid={true}>
                {
                    isEditWorkOrder && loadingEditWorkOrder && <>
                        <Row className='mt-2'>
                            <Col sm="12" md="12" lg="12" className='text-center'>
                                <img src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite' style={{ width: '150px' }} />
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                            <Col sm="3" md="3" lg="3" className='text-left'>
                                <p className='f-24 text-primary'><b>Cargando</b></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'></Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle"></i></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle"></i></p>
                            </Col>
                            <Col sm="1" md="1" lg="1" className='text-center'>
                                <br />
                                <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle"></i></p>
                            </Col>
                            <Col sm="2" md="2" lg="2" className='text-center'></Col>
                        </Row>
                    </>
                }
                <IncomeTypeWorkOrder
                    patientValid={id_patient_valid}
                    companyValid={id_company_valid}
                    {
                    ...{
                        //EDIT WORK ORDER 
                        isEditWorkOrder, loadingEditWorkOrder,

                        //WORK ORDER VARIABLES
                        checkTypeClient, modalScannQrPatient, handleChangeClient,
                        isPayForCompany, requiredInvoiceCompany, availableInvoicePatient,

                        //WORK ORDERS METHODS
                        handleChangeTypeClient, handleChangeGeneratePay, handleChangeAgreement,

                        //PATIENT VARIABLES
                        id_patient,
                        inputReadQrCode, focusQrPatient, user_unique_code,
                        searchPatientState, informationPatient,

                        //PATIENT METHODS
                        handleChangePatient,
                        handleScannQrPatient, handleCreatePatient, handleSelectValuesQr,
                        handleUpdatePatient,

                        onKeyPressQr, qrCodeAutoFocus,

                        //DOCTOR VARIABLES
                        searchDoctorState, informationDoctor, workOrderId, id_work_order,

                        //DOCTOR METHODS
                        handleCreateDoctor, handleChangeDoctor, handleUpdateDoctor,

                        //COMPANY VARIABLES
                        id_company,
                        listCompanies,
                        listAgreements,
                        idAgreementCompany,

                        //COMPANY METHODS
                        handleSelectValuesWorkOrder,

                        //CANCELLATION WORK ORDER 
                        handleCancelWorkOrderUpdate, doctList, handleDeleteDoctor, handleSaveDoctor, disableEdit,nim_Notification,

                        validPorcentejeSpecial
                    }
                    }
                />

                <Row className={ (checkTypeClient === 3 ?   (idAgreementCompany !==0 ? "animate__animated animate__fadeInDown" : "d-none" ) : viewWorkOrder ? 'animate__animated animate__fadeInDown' : 'd-none' )   }>
                    <Col sm="6" md="6" lg="6" xl="6">
                        <Card className='shadow  sizeModalDPAn'>
                            <CardHeader className='bg-primary p-2'>
                                <i className="fa fa-cogs f-20"></i> &nbsp; &nbsp;&nbsp; &nbsp;
                                <label className='labelSearchPatient' >Configuraciones</label>
                            </CardHeader>
                            <CardBody className='p-l-2 p-t-0 p-b-10'>
                                <Row className='m-t-5'>

                                    <Col sm="12">
                                        <br />
                                        <Label style={{fontSize : "11px"}} className='labelPriority'>
                                            {LabelPrinterPoint} <span className='f-16' style={{ color: "#0079C7" }}>*</span>
                                        </Label>
                                        <FormGroup>
                                            <Select
                                                classNamePrefix="select"
                                                name="id_printer_point"
                                                value={id_printer_point}
                                                options={listPrinterPoint}
                                                onChange={(e) => handleSelectValuesWorkOrder(e, "id_printer_point")}
                                                placeholder={PlaceHolderOption}
                                                isClearable={true}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        fontSize: '11px',
                                                    }),
                                                }}
                                            />
                                            <FormText color='danger' className='m-t-10' hidden={!id_printer_point_valid}>
                                                Selecciona una opción
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    {validHopitalInterfaced === "True" &&
                                        <Col sm="12">
                                            <FormGroup>
                                                <Label style={{fontSize : "11px"}}  className='col-form-label'>Tipo de Servicio <span className='f-16' style={{ color: "#0079C7" }}></span></Label>
                                                <Select
                                                    classNamePrefix="select"
                                                    name="id_work_order_type_service"
                                                    value={id_work_order_type_service}
                                                    options={listType}
                                                    placeholder={PlaceHolderOption}
                                                    onChange={(e) => handleSelectValuesWorkOrder(e, "id_work_order_type_service")}
                                                    isClearable={true}
                                                />
                                                <FormText color='danger' className='m-t-10' hidden={!id_work_order_type_service_valid}>Selecciona una opción</FormText>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <hr />
                                <Row className='m-t-5'>
                                    <Col xs="4" sm="4" md="4" lg="4" xl="4" >
                                        <Label style={{fontSize : "11px"}} className="labelCheckPrint">{CheckTextPrint}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkPrint" onChange={handleInputChangeWorkOrder} checked={checkPrint} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                    <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                        <Label style={{fontSize : "11px"}} className="labelCheckPrint">Resultados parciales</Label> <br />
                                        <Label className="switch disabled" disabled >
                                            <Input className='disabled' disabled type="checkbox" name="checkPartial" onChange={handleInputChangeWorkOrder} checked={checkPartial} />
                                            <span className="slider round"  ></span>
                                        </Label>
                                    </Col>
                                   
                                    {
                                        (checkTypeClient === 1 || (id_company?.value && !id_company?.require_invoice && id_company?.available_invoice_patient)) && <Col xs="4" sm="4" md="4" lg="4" xl="4" className='p-r-0'>
                                            <Label style={{fontSize : "11px"}} className="labelCheckPrint">
                                                <b>¿El paciente requiere factura?</b>
                                            </Label> <br />
                                            <Label className="switch">
                                                {/* disabled={ finance === "True" ?  isEditWorkOrder && checkInvoice  :"" }  */}
                                                <Input type="checkbox" name="checkInvoice" onChange={(e) => handleInputChangeWorkOrder(e)} checked={checkInvoice} />
                                                <span className="slider round"></span>
                                            </Label>
                                        </Col>
                                    }
                                     <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                        <Label style={{fontSize : "11px"}} className="labelCheckPrint">{CheckTextEmail}</Label> <br />
                                        <Label className="switch">
                                            <Input type="checkbox" name="checkEmail" onChange={handleInputChangeWorkOrder} checked={checkEmail} />
                                            <span className="slider round"></span>
                                        </Label>
                                    </Col>
                                    {
                                        informationDoctor.length > 0
                                            ?
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4">
                                                <Label style={{fontSize : "11px"}} className="labelCheckPrint ">Envio a médico</Label> <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="checkDoctor" onChange={handleInputChangeWorkOrder} checked={checkDoctor} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                            : ""
                                    }
                                     {
                                        enable_whatsapp === "True"
                                            ?
                                            <Col xs="4" sm="4" md="4" lg="4" xl="4" >
                                                <Label style={{fontSize : "11px"}} className="labelCheckPrint">{CheckTextWhats}</Label> <br />
                                                <Label className="switch">
                                                    <Input type="checkbox" name="checkWhats" onChange={handleInputChangeWorkOrder} checked={checkWhats} />
                                                    <span className="slider round"></span>
                                                </Label>
                                            </Col>
                                            : ""
                                    }
                                </Row>
                             
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm="6" md="6" lg="6" xl="6" className='p-l-0'>
                        <Card className='shadow sizeModalDPAn'>
                            <CardHeader className='bg-primary p-2'>
                                <i className="fa fa-comments-o f-20"></i> &nbsp; &nbsp;&nbsp;
                                <label className='labelSearchPatient' >Observaciones</label>
                            </CardHeader>
                            <CardBody className='p-l-5 p-t-10 p-b-15'>
                                <Row className='match-height m-t-10'>
                                    <Col sm="12" md="12" lg="12" xl="12">

                                    </Col>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Row>
                                            <Col sm="3" md="3" lg="3" xl="3">
                                                <Label className='f-w-600 f-12 badge badge-light-primary'>Hoja de Trabajo</Label>
                                            </Col>
                                            <Col sm="4" md="4" lg="4" xl="4">
                                            </Col>
                                            <Col sm="5" md="5" lg="5" xl="5" className='p-l-0 p-r-0' >
                                                <Label htmlFor="medical_order" className={showClassSD.ClassSD} outline color='primary' size="xs">
                                                    {showMessageWF.labelWF} <span className="icofont icofont-upload-alt"></span>
                                                    <Input type="file" accept="image/*,.pdf" id="medical_order" name="medical_order" onChange={handleOnChange} style={{ display: 'none' }} />
                                                </Label> &nbsp; &nbsp; &nbsp;
                                                <Button onClick={DeleteFile} className={showClass.ClassName} outline color="danger" size="xs" type="button">
                                                    <i className="fa fa-trash"></i>
                                                </Button>&nbsp; &nbsp;
                                            </Col>
                                        </Row>
                                        <textarea
                                            name="observations"
                                            className='form-control form-control-sm input-air-primary'
                                            rows="2"
                                            onChange={handleInputChangeWorkOrder}
                                            value={observations}
                                        >
                                        </textarea>
                                    </Col>
                                </Row>
                                <Row className='m-t-20 m-b-20 match-height'>
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <Label className='f-w-600 f-12 badge badge-light-primary'>Hoja de flebotomista</Label> <br />
                                        <textarea
                                            name="observations_sample"
                                            className='form-control form-control-sm input-air-primary'
                                            rows="2"
                                            onChange={handleInputChangeWorkOrder}
                                            value={observations_sample}
                                        >
                                        </textarea>
                                    </Col>
                                    <br /> <br /> <br />   <br /> <br />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div className={ (checkTypeClient === 3 ?   (idAgreementCompany !==0 ? "animate__animated animate__fadeInDown" : "d-none" ) : viewWorkOrder ? 'animate__animated animate__fadeInDown' : 'd-none' )   }>
                    {/* --------------- */}
                    <Row className='m-b-5'>
                        <Col sm="12" md="12" lg="12" xl="12">
                            <Card className='shadow'>
                                <CardHeader className="p-2 bg-primary">
                                    <i className="icofont icofont-laboratory f-20"></i>&nbsp; &nbsp;
                                    <label>Búsqueda de examen/perfil</label>
                                </CardHeader>
                                <CardBody className='p-3'>
                                    <Row className='p-2'>
                                        <Col sm="10"></Col>
                                        <Col sm="2">
                                            <div className='text-right'>
                                                <Button className='f-right bottonquoter' outline color='primary' size="xs" onClick={() => handleSearchQuoter()} disabled={isEditWorkOrder}>
                                                    <i className="icofont icofont-id"></i> Cargar Cotización
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className=''>
                                        <Col sm="12" md="12" lg="12">
                                            {
                                                !isEditWorkOrder && <Row className={checkTypeClient === 1 ? '' : 'd-none'}>
                                                    <Col sm="12" md="12" lg="!2">
                                                        <ButtonGroup md="12" className='btn-group-pill btn-block'>
                                                            <Button color='primary' type='button' onClick={() => handleChangeTypeFilterTest(0)} outline={typeSearch === 0 ? false : true}><i className="icofont icofont-test-tube-alt"></i> &nbsp;&nbsp; Búsqueda por Examen/Perfil</Button>
                                                            <Button color='primary' type='button' onClick={() => handleChangeTypeFilterTest(1)} outline={typeSearch === 1 ? false : true}><i className="icofont icofont-paper"></i> &nbsp;&nbsp; Búscar campañas</Button>
                                                            {/* <Button color='primary' type='button' onClick={() => handleChangeTypeFilterTest(2)} outline={typeSearch === 2 ? false : true}><i className="icofont icofont-prescription"></i> &nbsp;&nbsp; Insumos</Button> */}
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            }
                                            <Row className={checkTypeClient === 1 ? typeSearch === 0 ? 'mt-3' : 'd-none' : 'mt-3'} >
                                                <Col sm="12" md="12" >
                                                    <FormGroup>
                                                        <SearchAsync
                                                            loincState={searchExamProfileState}
                                                            name="id_profileExam"
                                                            method={handleSelectExamChange}
                                                            // url={
                                                            //     validRestrictCompany === "True"
                                                            //       ? `Profiles/SearchProfileExamCompany?id_agreement=${id_agreementBranch}`
                                                            //       : `Profiles/SearchProfileExam`
                                                            //   }
                                                            url={
                                                                validRestrictCompany === "True" && checkTypeClient === 3
                                                                    ? `Profiles/SearchProfileExamCompany?id_agreement=${_valid_url}`
                                                                    : `Profiles/SearchProfileExam`
                                                            }
                                                            maxLenght={3}
                                                            isWorkOrder={true}
                                                        />
                                                        <FormText color='danger' hidden={!listTest_valid}>Debes de seleccionar un examen o perfil</FormText>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className={checkTypeClient === 1 ? typeSearch === 1 ? 'mt-3' : 'd-none' : 'd-none'}>
                                                <Col sm="12" md="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>Campañas adecuadas para el paciente</Label>
                                                        <Select
                                                            classNamePrefix="select"
                                                            name="selectedCampaign"
                                                            value={selectedCampaign}
                                                            placeholder="Selecciona una campaña"
                                                            options={listCampaigns}
                                                            onChange={(e) => handleChangeCampaign(e)}
                                                            theme={
                                                                (theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#898989'
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className={checkTypeClient === 1 ? typeSearch === 2 ? 'mt-3' : 'd-none' : 'd-none'}>
                                                <Col sm="12" md="12">
                                                    <FormGroup>
                                                        <Label className='col-form-label'>Insumos para muestras</Label>
                                                        <SearchAsync
                                                            loincState={searchExtra}
                                                            name="id_supplies"
                                                            method={handleSelectSupplies}
                                                            url="Profiles/ListProfileSupplies"
                                                            maxLength={3}
                                                            isWorkOrder={true}
                                                        />
                                                        <FormText color='danger' hidden={!listTest_valid}>Debes de seleccionar un insumo</FormText>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row> <br />
                                    <Row className='m-t-5'>
                                        <Col sm="12" md="12">
                                            <div className='table-responsive'> <br />
                                                {
                                                    !isEditWorkOrder && listTest.length > 0 &&
                                                    <TableWorkOrder
                                                        key="tableExams"
                                                        items={listTest}
                                                        {
                                                        ...{
                                                            removeExamProfile, handleCheckUrgent, handleCheckSpecimen, handleCheckForwarded, number_format, changeSelectSamplings, changeValueTimeSampling, times, handleViewExamsProfile,
                                                            checkTypeClient, listid, enable_tracking_toma, removeExamProfileByCampaign, handleChangeExternalLaboratories
                                                        }
                                                        }
                                                    />
                                                }
                                                {
                                                    isEditWorkOrder && listTest.length > 0 &&
                                                    <TableWorkOrderUpdate
                                                        key={"tableOfExamsUpdate"}
                                                        items={listTest}
                                                        count={listTest.length}
                                                        isEditWorkOrder={isEditWorkOrder}
                                                        {
                                                        ...{
                                                            number_format, id_company, removeExamProfile, handleCheckSpecimen, handleCheckUrgent,
                                                            changeSelectSamplings, timesCurves: times, changeValueTimeSampling, handleViewExamsProfile,
                                                            handleCheckForwarded, enable_tracking_toma, checkDiscount: false, valid_commont: false,
                                                            checkTypeClient, handleChangeExternalLaboratories
                                                        }
                                                        }
                                                    />
                                                }

                                            </div>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='m-b-15'>
                        <Col sm="12" md="4" lg="4" xl="4">
                            <Card className='shadow'>
                                <CardHeader className='bg-primary p-1'>
                                    <br />
                                    &nbsp; &nbsp; <i className="icofont icofont-prescription f-20"></i> &nbsp; &nbsp; &nbsp; &nbsp;
                                    <label className='labelIndication'>Indicaciones de Estudios / Tiempo de Entrega</label>
                                </CardHeader>
                                <Row>
                            <Col sm="12" md="12" lg="12" xl="12" className='scrollWork pt-2 pb-2 pl-4  pr-2'>
                            {listTest.length > 0 && listTest.map((obj, index) => (
                                (obj.id_exam !== null || obj.id_profile !== null) ? (
                                <TreeNode
                                    key={`node-${index}`}
                                    obj={obj}
                                    index={index}
                                    openNodes={openNodes}
                                    openNodes1={openNodes1}
                                    handleToggle={handleToggle}
                                    handleToggle1={handleToggle1}
                                />
                                ) : null
                            ))}
                            </Col>
                            <Col>
                            {/* Aquí puedes añadir más contenido si es necesario */}
                            </Col>
                        </Row>
                               
                            </Card>
                        </Col>
                        <Col sm="12" md="8" lg="8" xl="8">
                            <Card className='shadow'>
                                <CardHeader className='bg-primary p-1'>
                                    <br />
                                    &nbsp; &nbsp; <i className="fa fa-usd f-20"></i>&nbsp; &nbsp;
                                    <label className='labelDateGenerales'>Información de pago</label>
                                </CardHeader>
                                <Col sm="12" style={{ padding: "24px" }} className='p-t-0' >

                                    <Row className='m-t-10 p-1'>
                                        <Col sm="12" md="12" lg="12" xl="12" className={ShowClassNameC}>
                                            <Label className='f-w-600 f-12 badge badge-light-primary'>Observaciones Generales</Label>
                                            <textarea name="observations_general" className='form-control form-control-sm input-air-primary'
                                                rows="2" onChange={handleInputChangeWorkOrder}
                                                value={observations_general}>
                                            </textarea>
                                        </Col>
                                    </Row>
                                    {showButton && isEditWorkOrder && (
                                        <Row className='m-t-10 p-3 justify-content-end'>
                                            <Col sm="12" md="12" lg="12" xl="12" className={`text-right ${ShowDiscount}`}>
                                                <Label className='f-w-600 f-12 badge badge-light-primary'>Descuento</Label>
                                                <input name="percentageDiscount" type="number" pattern="[0-9]*"
                                                    onChange={handleInputChangeWorkOrder}
                                                    value={percentageDiscount} />
                                            </Col>
                                        </Row>
                                    )}

                                    <Row className='m-t-10 p-1'>
                                        <Col xs="12" sm="6" md="3" lg="4" xl="4" className='p-l-0 p-r-0' >
                                            <Row>
                                                <Col sm="4" className='p-r-0'>
                                                    <label className='txt-secondary'>Tiempo de entrega</label>

                                                </Col>
                                                <Col sm="8" className='p-l-0'>
                                                    <input type="text" name="study_delivery" onChange={handleInputChangeWorkOrder} value={study_delivery} className="form-control form-control-sm input-air-primary" placeholder="Hora..." />

                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col xs="12" sm="6" md="3" lg="3" xl="3" style={{ textAlign: "right" }}>
                                            {
                                                (!validTotalCredit && validButton) && <Button type='button' className='btn-air-primary' outline color='primary' size='sm' onClick={handleAddPayMethodInputs}>
                                                    <i className='fa fa-plus'></i> Método de pago
                                                </Button>

                                            }
                                        </Col>
                                        <Col xs="12" sm="6" md="3" lg="3" xl="3">
                                            {
                                                specialDiscount === null ?
                                                    <Button type='button' className='float-right btn-air-primary' outline color='primary' size='sm' onClick={() => handleModalSpecialDiscount()} hidden={isEditWorkOrder} disabled={checkTypeClient === 1 ? false : true} >
                                                        <i className="fa fa-percent"></i> Descuento especial
                                                    </Button>
                                                    :
                                                    <Button type='button' disabled className='float-right btn-air-primary disabled' outline color='primary' size='sm' onClick={null} hidden={isEditWorkOrder}>
                                                        <i className="fa fa-check"></i> Descuento especial aplicado
                                                    </Button>
                                            }
                                        </Col>
                                        <Col xs="6" sm="6" md="2" lg="2" xl="2" className=''>
                                            <Button type='button' className='float-right btn-air-primary' outline={!checkComments} color='primary' size='sm' onClick={enableComments}>
                                                {`${checkComments ? 'Ocultar' : 'observaciones'}`}
                                            </Button>
                                            <p style={{ margin: '0 10px' }}></p>
                                            {showButton && isEditWorkOrder && !isFullyPaid && (
                                                <Button type='button' className='ml-auto btn-air-primary' outline={!checkDiscopunt} color='primary' size='sm' onClick={enableDiscount}>
                                                    {`${checkDiscopunt ? 'Ocultar descuento' : 'Añadir descuento'}`}
                                                </Button>
                                            )}
                                        </Col>

                                    </Row>
                                    <Col sm="12" className={validPorcentejeSpecial === "https://labcen.helen-sw.com" ? " p-l-0 p-r-0" : "d-none"}   >
                                        <br />
                                        {
                                            (arrayCommonDiscounts.length > 0 && !isEditWorkOrder && !validateCommonDiscountDetail) ?

                                                <AlertItem
                                                    item={
                                                        {
                                                            alerttxt: "EL paciente es candidato a una campaña",
                                                            alertcolor: 'danger inverse',
                                                            btnclose: 'default',
                                                            icon: <i className="icon-alert"></i>
                                                        }
                                                    }
                                                />
                                                : ""
                                        }
                                    </Col>

                                    <Row className='m-t-5 p-1'>
                                        <Col sm="12" md="6" lg="6" xl="6" className='p-l-0 mt-2'>
                                            <Row className='m-t-15'>
                                                <Table>
                                                    <thead>
                                                        <tr className='text-center border-bottom-info'>
                                                            <th className='p-1 f-12'>
                                                                <label className='text-WorkOrder'>Método de pago</label>
                                                            </th>
                                                            <th className='p-1 f-12'><label className='text-WorkOrder '>Monto</label></th>
                                                            <th className={validTotalCredit ? "d-none" : 'p-1 f-12'} colSpan={2}  >
                                                                <label className='text-WorkOrder'>Acciones</label>
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            listPayMethods.map((_paymethod, keyPayment) => {
                                                                return (
                                                                    <tr key={`method-${keyPayment}`} className={_paymethod.deleted ? 'd-none' : ''}>
                                                                        <td>
                                                                            <Input type="select" required={true} className='form-control form-control-sm input-air-primary p-1' value={_paymethod.id_pay_method} data-id={keyPayment}
                                                                                onChange={(e) => handleDinamicInputWorkOrder(e, "id_pay_method", "listPayMethods")} disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}>
                                                                                <option value={0}>Selecciona una opción</option>
                                                                                {
                                                                                    listPayMethod.map(_pMethod => {
                                                                                        if (checkTypeClient === 3 && isPayForCompany) {
                                                                                            
                                                                                            if (_pMethod.name === AvailablePayMethodByCompany) {
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                        else if (_pMethod.abbreviation !== "CONV") {

                                                                                            if (_adminFinance === "True" ) {
                                                                                                return (
                                                                                                    <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                )
                                                                                            }
                                                                                            else {
                                                                                                if (_pMethod.visibility_particular === true) {                                                                                                    
                                                                                                    return (
                                                                                                        <option value={_pMethod.id_pay_method} key={`method-${_pMethod.id_pay_method}`}>{`${_pMethod.abbreviation} - ${_pMethod.name}`}</option>
                                                                                                    )
                                                                                                }
                                                                                            }                                                                                         
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                        </td>
                                                                        <td>
                                                                            <Input type='number' name="amount" data-id={keyPayment} value={_paymethod.amount} placeholder='$ 0.0' step="any"
                                                                                className='form-control input-air-primary form-control-sm' onChange={(e) => handleGiveChange(e, "amount", "listPayMethods")}
                                                                                disabled={validTotalCredit ? true : (_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW >= 0 ? false : true)}
                                                                            />
                                                                        </td>
                                                                        <td className={validTotalCredit ? "d-none" : 'p-1 text-center'} style={{ verticalAlign: "middle" }} colSpan={2}  >
                                                                            {

                                                                                (_paymethod.fully_paid !== undefined && !_paymethod.fully_paid) ?
                                                                                    <Button type='button' className='btn-air-success' outline color='success' size='xs' onClick={() => handlePayBill(_paymethod.id_work_order_pay, _paymethod.amount)}>
                                                                                        <i className="fa fa-money"></i>
                                                                                    </Button>
                                                                                    :
                                                                                    <Button type='button' className='btn-air-danger' outline color='danger' size='xs' onClick={() => handleDeletePayMethod(keyPayment)}
                                                                                        disabled={(_paymethod.id_work_order_pay !== undefined && _paymethod.id_work_order_pay !== null) ? true : (totalW > 0 ? false : true)}
                                                                                    >
                                                                                        <i className='icofont icofont-ui-delete'></i>
                                                                                    </Button>
                                                                            }
                                                                            {
                                                                                _paymethod.fully_paid && isEditWorkOrder && <img className="img-fluid pointer" src={require("../../../assets/images/loginHelenLabs/InvoiceCFDI/Egress.svg")} style={{ width: "33%" }} onClick={handleUpdateMethod} />

                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                    {
                                                        !validTotalCredit &&
                                                        <tfoot>


                                                            <tr>
                                                                <td colSpan={3} className="text-xs-center">
                                                                    <FormText color='danger' hidden={!listPayMethods_valid}>Selecciona un metodo de pago o ingresa la cantidad correcta</FormText>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-right'>
                                                                    <b className='labelChange'>{showMessageW.labelMoney}</b>
                                                                </td>
                                                                <td>
                                                                    <b className='labelChange'>$ {`${number_format(showMessageW.amount, 2)}`}</b>
                                                                </td>
                                                            </tr>
                                                        </tfoot>

                                                    }

                                                </Table>
                                            </Row>
                                        </Col>
                                        <Col sm="12" md="6" lg="6" xl="6" className='p-r-0 mt-2'>
                                            <Row className='m-t-15' style={{ textAlignLast: 'center' }}>
                                                <Col sm="12">
                                                    <Table className="table-xs">
                                                        <thead>
                                                            <tr>
                                                                <th className='p-1 f-12' colSpan={2}>
                                                                    <b className='text-WorkOrder f-14'>Resúmen</b>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='border-bottom-info'>
                                                                <td className='f-14 text-right'><label className='labelPrices '>Subtotal</label></td>
                                                                <td className='text-right f-14'><label className='labelPrices'>$ {number_format(subTotalW, 2)}</label></td>
                                                            </tr>
                                                            {totalDiscountW >= 0 &&
                                                                <tr style={{ cursor: "pointer" }} className="border-bottom-primary">
                                                                    <td className='f-14'><label className='labelPrices'>Descuento</label>  </td>
                                                                    <td className="text-right f-14">
                                                                        <b className="text-danger text-WorkOrderDanger">(-) ${number_format(totalDiscountW, 2)}</b>
                                                                        <a onClick={handleModalListCommonDiscounts} className="f-w-600 f-12 badge badge-light-primary ml-2"><i className="fa fa-plus-circle"></i></a>
                                                                    </td>
                                                                </tr>
                                                            }

                                                            <tr className='border-bottom-info'>
                                                                <td className='f-14'><label>I.V.A</label></td>
                                                                <td className='text-right f-14'><label><b>${number_format(totalTaxesW, 2)}</b></label></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='f-14'> <b className='f-14'>Total</b></td>
                                                                <td className="text-right f-14"><b className='f-14'>$ {number_format(totalW, 2)}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td className='f-14'> <b className='f-14'>Devoluciones</b></td>
                                                                <td className="text-right f-14"><b className='f-14'>$ {number_format(totalDevolutions, 2)}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row className='m-t-15'>
                                                <Col sm="12">
                                                    {showDiscountWarning && (
                                                        <h7 style={{ color: 'red' }}>¡El porcentaje de descuento debe estar entre 0 y 100!</h7>
                                                    )}
                                                    {showDiscountWarningPay && (
                                                        <h7 style={{ color: 'red' }}>¡Excediste el crédito!</h7>
                                                    )}
                                                    {/* {showDiscountWarningCredit && (
                                                        <h7 style={{ color: 'red' }}>¡Excediste el crédito!</h7>
                                                    )} */}
                                                    {
                                                        listTest.length > 0 &&
                                                        <ButtonGroup className="btn-group-pill btn-group-sm" size='sm' style={{ width: '100%' }}>
                                                            {
                                                                isEditWorkOrder ? <Button
                                                                    className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
                                                                    outline
                                                                    color='primary-2x'
                                                                    disabled={
                                                                        validTotalCredit ? false :
                                                                            showButton === false ?
                                                                                enableBtnSaveWorkOrder :
                                                                                (showDiscountWarning || showDiscountWarningPay)
                                                                                    ? true
                                                                                    : false
                                                                    }


                                                                    onClick={() => saveUpdateWorkOrder()}
                                                                >
                                                                    <label className="labelSaveCreate">{BtnSaveWorkOrder}</label>
                                                                </Button>
                                                                    : <Button
                                                                        className={loading ? "disabled progress-bar-animated progress-bar-striped btn-air-primary" : "btn-air-primary"}
                                                                        outline
                                                                        color='primary-2x'
                                                                        disabled={validTotalCredit ? false : enableBtnSaveWorkOrder}
                                                                        onClick={() => saveWorkOrder()}
                                                                    >
                                                                        <label className="labelSaveCreate">{BtnSaveWorkOrder}</label>
                                                                    </Button>
                                                            }
                                                            <Button
                                                                className='btn-air-danger'
                                                                outline
                                                                color='danger'
                                                                onClick={() => cancellWorkOrder()}
                                                                hidden={isEditWorkOrder}
                                                            >
                                                                <label className='labelSaveCreate'>Cancelar</label>
                                                            </Button>
                                                        </ButtonGroup>
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
            <ModalPatient
                {
                ...{
                    modal: modalPatient, toggle: togglePatient, modalTitle, method: methodPatient, loading: loadingPatient,
                    validationsPatient, saveUpdateWorkOrder, validHopitalInterfaced,

                    handleDatePatient, handleChangeSocialGender,
                    handleInputChange: handleInputChangePatient, handleSelectValues: handleSelectValuesPatient, handleDate:
                        handleDatePatient, getBirthday, getAge, handleWheel, handleChangeSocialGender,

                    id_gender: formValuesPatient.id_gender, id_state: formValuesPatient.id_state, id_municipality: formValuesPatient.id_municipality,
                    id_blood_type: formValuesPatient.id_blood_type, id_social_gender: formValuesPatient.id_social_gender, id_tax_regime: formValuesPatient.id_tax_regime,
                    id_use_cfdi: formValuesPatient.id_use_cfdi, rfc: formValuesPatient.rfc, type_person: formValuesPatient.type_person, code: formValuesPatient.code,
                    paternal_surname: formValuesPatient.paternal_surname, maternal_surname: formValuesPatient.maternal_surname, name: formValuesPatient.name, birthday: formValuesPatient.birthday,
                    age: formValuesPatient.age,
                    curp: formValuesPatient.curp, address: formValuesPatient.address, cp: formValuesPatient.cp, colony: formValuesPatient.colony,
                    phone: formValuesPatient.phone, email: formValuesPatient.email, membership: formValuesPatient.membership, cardnumber: formValuesPatient.cardnumber,
                    new_social_gender: formValuesPatient.new_social_gender, expedient_number: formValuesPatient.expedient_number,
                    listStates, listMunicipalities, listBloodType, listTaxRegime, listUseCfdi: listUseCfdiPatient, listSocialGender: Listsocialgender,

                    handleChangeState: handleChangeStatePatient, handleChangeTypePerson, handleChangeTaxRegime,

                    savePatient: saveCreatePatient, saveUpdate: saveUpdatePatient,

                    isInvoiceService, setIsInvoiceService,
                    viewNewSocialGender, setViewNewSocialGender,
                    scan_ine_check: formValuesPatient.scan_ine_check, setScan_ine_check, handleOnChangeINE, DeleteINE, showClassINE,
                    img64File: formValuesPatient.img64File, handleOnChangeINEU,
                    codeP: formValuesPatient.cp,
                }
                }
            />
            <ModalDoctor
                nameInputs={nameInputsDoctor}
                addSpecialty={true}
                {
                ...{
                    modal: modalDoctor, toggle: toggleDoctor, handleInputChange: handleInputChangeDoctor, saveCreate: saveCreateDoctor,
                    saveUpdate: saveUpdateDoctor, loading: loadingDoctor, method: methodDoctor, specialties, validaciones: validationsDoctor,
                    id_specialty, name_specialty, name: name_doctor, professional_license, phone: phone_doctor, address: address_doctor,
                    email: email_doctor, isCommercialPartner, saveUpdateWorkOrder, validaciones1,
                    code
                }
                }
            />
            <ModalViewExams
                {
                ...{
                    modalViewExamsP, toggleModalViewExams, viewExamsProfile
                }
                }
            />

            <ModalSpecialDiscount
                {
                ...{
                    modal: modalSpecialDiscounts, toggle: toggleSpecialDiscount, special_discount_code, handleInputChangeSpecialDiscount, validateCodeSpecialDiscount,
                    validatePercentajeSpecialDiscount, percentage, validPorcentejeSpecial
                }
                }
            />

            <ModalCommonDiscounts
                {
                ...{
                    modal: modalCommonDiscount, toggle: toggleCommonDiscount,
                    arrayCommonDiscounts, clearDiscount,
                    arrayAgreementDiscounts, percentageDiscount, newDiscount, validPorcentejeSpecial, setValidateCommonDiscountDetail
                }
                }
            />

            <ModalQuoter
                {
                ...{
                    modal: modalQuoter, toggle: toggleModalQuoter, modalTitle, method: methodQuoter, loading: loadingQuoter,
                    handleGetQuoter, code_quoter, date_quoter, listQuoter,
                    handleInputChangeQuoter, validationsQuoter, searchQuoterByCode,
                    enableViewTest, viewTestQuoter, handleViewTest, handleCloseViewTest,
                }
                }
            />

            <ModalInvoice
                {
                ...{
                    modal: modalInvoice, toggle: toggleModalInvoice, modalTitle, loading: loadingInvoice, method: methodInvoice,
                    listUseCfdi, handleInputChange: handleInputChangeWorkOrder,
                    validationsInvoice, saveInvoice, cancelInvoice,
                    business_name, rfc, phone, email, id_use_cfdi, print_invoice, send_invoice,
                }
                }
            />
            <ModalRepeatProfile
                {
                ...{
                    modalRepeatProfile, validationsRepeat, modalTitle, toggleRepeatProfile
                }
                }
            />

            <ModalDevolution
                {
                ...{
                    toggleDevolutions: toggleModalDevolutions, modalTitle,
                    devolutionsState: devolutionsW, modalDevolutions, listTest,
                    number_format, listReturnReason: listDevolutions,
                    handleChangeReturnReason: handleChangeDevolution,
                    validateDevolutions, canceldevolution: handleCancelDevolution,
                    saveReturnReason: saveDevolution, loading
                }
                }
            />

            <ModalLiquidacion
                {
                ...{
                    modal: modalPayBills, toggle: toggleModalPayBills, handleInputChangeLiquided: handleInputChangePaymentBills,
                    saveLiquided: savePaymentBill, listPayMethod, loading,
                    validatesaveLiquided: validationsPaymentBills,
                    id_method_liquided, amount_liquided,
                    id_method_pay, amount_liq, percentageDiscount, loadingPayment
                }
                }
            />

            <ModalAuthorize
                {
                ...{
                    modal: modalCancelWorkOrderUpdate, toggle: toggleModalCancellWorkOrderUpdate,
                    user_authorize, password_authorize, id_return_reason_woc, percentage_return,
                    validationsCancelWorkOrder, totalCancellationWorkOrder, loading, listDevolutions,

                    handleInputChangeCancelWorkOrder, handleValidateReturnAmount,

                    saveCancelWorkOrderUpdate, validatePasswordCancell, aunthentication
                }
                }
            />

            <ModalUpdateMethod
                {
                ...{
                    modalPreviewUpdate, toggleModalUpdate,
                    user_method, user_password_method, id_pay_method_method, handleInputChangeMethod,
                    saveUpdateMethod, loading, modalTitle, listPayMethod, validatePassword, aunthentication, loadingUpdateMethod
                }
                }
            />

            <ModalAutorization
                {
                ...{
                    user_method, user_password_method, handleInputChangeMethod, modalAutorization,
                    toggleModalAutorization, PreviewAutorization, handleCancelAutorization, name_User, configuration_role,
                    handleSendNotificationInvoices, validatePasswordFinance
                }
                }
            />


        </Fragment>
    )

}
