

const examsValidations = (form,newValidations,_key,_idTest,_id_Profile,listExams = [], _name_profile = "") => {

    let statusValidation = -1;
    let valid_exist_test = {
        name_profile: "",
        repeat_profiles: [],
        repeat_exams: []
    };

    if (form.list_type_samples.length > 0) {

        let _findTypeSample = form.list_type_samples[_key];

        if (_findTypeSample) {
               
            if (_findTypeSample.list_determinations !== null && _findTypeSample.list_determinations.length > 0) {
                
                let _find = _findTypeSample.list_determinations.find(x => (_idTest !== null ? x.id_exam === parseInt(_idTest) : x.id_profile === parseInt(_id_Profile)));

                if (_find) {
                    newValidations = {
                        ...newValidations,
                        valid_repeat_test: true,
                        message_validation: (_idTest !== null ? `El examen: ${_find.name}, ya existe` : `El perfil: ${_find.name}, ya existe`)
                    };
                    statusValidation = 0;         
                }  
                else {
                    if (_idTest !== null) {
                        let listProfiles = _findTypeSample.list_determinations.filter(x => x.id_profile !== null);
                        listProfiles.forEach(p => {
                            if (p.listExams.find(x => x.id_exam === parseInt(_idTest))) {
                                newValidations = {
                                    message_validation: "El examen seleccionado ya existe en el perfil: " + p.name
                                };
                                statusValidation = 1;
                                return true;
                            }
                        }); 
                    }
                    else if (_idTest === null && _id_Profile !== null) {
                        let filterProfiles = _findTypeSample.list_determinations.filter(x => x.id_profile !== null);

                        filterProfiles.forEach(p => {
                            let examsRepeat = [];
                            let examsNotRepeat = [];

                            p.listExams.forEach(e => {
                                if (listExams.find(x => x.id_exam === e.id_exam)) {
                                    examsRepeat.push(e);
                                }
                            });

                            let notExist = [];
                            listExams.forEach(x => {
                                if (!examsRepeat.find(r => r.id_exam === x.id_exam)) {
                                    notExist.push(x);
                                }
                            });

                            examsNotRepeat = notExist;

                            if (examsRepeat.length > 0) {
                                valid_exist_test.repeat_profiles.push({
                                    name_profile: p.name,
                                    listExamsRepeat: examsRepeat,
                                    listExamsNotRepeat: examsNotRepeat
                                });
                            }

                            if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                                valid_exist_test.name_profile = _name_profile;
                                newValidations = {
                                    message_validation: "Hay exámenes o perfiles repetidos"
                                };

                                statusValidation = 2;
                            }
                        });

                        if (valid_exist_test.repeat_profiles.length > 0 || valid_exist_test.repeat_exams.length > 0) {
                            valid_exist_test.name_profile = _name_profile;
                            newValidations = {
                                message_validation: "Hay exámenes o perfiles repetidos"
                            };

                            statusValidation = 2;
                        }

                        let listExamsW = _findTypeSample.list_determinations.filter(x => x.id_exam !== null);

                        listExamsW.forEach(e => {
                            if (listExams.find(x => x.id_exam === parseInt(e.id_exam))) {
                                newValidations = {
                                    message_validation: `Tienes exámenes duplicados: ${e.name}, al seleccionar el perfil`
                                };

                                statusValidation = 3;

                                return true;
                            }
                        })
                    }

                }
                
                

            }  
        }    
    }
    return statusValidation;
}
    
export default examsValidations;