import React from 'react'
import { Col, Modal, ModalBody, CardBody, Button,Row } from 'reactstrap';

export const ModalValidSamplings = (props) => {

    const { handleToggleValid,modalValid,listNot} = props;


    return (
        <Modal isOpen={modalValid} toggle={handleToggleValid} keyboard={false} centered={true} >
            <ModalBody>
            <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
             
           
                <Col sm="12" style={{textAlign:"center"}}>
                <img alt="flask"  src={require("../../../assets/images/price/warningRecollected.svg")} style={{ width: "90px", height: "90px" }} />
                </Col>
                <Col sm="12" className='pt-1' style={{textAlign:"center"}}>

                {
                   listNot.length > 0 && listNot.map(_obj => {
                        console.log("🚀 ~ ModalValidSamplings ~ _obj:", _obj)

                        return(
                            <Col sm="4" className='badge-detail-micro' >
                            <Row>
                                <Col sm="3" className='p-r-0' >
                                <i className="fa fa-angle-right f-12"></i>
                                </Col>
                                <Col sm="9" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
                                {_obj.folio ?? "--"}
                                </Col>
                            </Row>
                        </Col>
                        )
                    })
                }
             
                </Col>
               <Col sm="12" className='pt-2' style={{textAlign:"center"}}>
                <b className='txt-danger f-w-500 f-14'>Esta muestra no sera reportada debe cancelarla o reportarla</b>
                </Col>
             
                 <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                <Row>
                    <Col sm="8" className='p-r-0'>
                    </Col>
                    <Col sm="2">
                    <Button color="warning text-center" type="button" onClick={handleToggleValid} >{"Aceptar"}</Button>
                    </Col>
                </Row>
                 
                </Col>   
                
            </CardBody>
            </ModalBody>
        </Modal>
    )
}