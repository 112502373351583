import React from 'react'
import { Button, ButtonGroup, Table, FormGroup, Input, Label, Row, Col, FormText } from 'reactstrap';
import Select from "react-select";

export const TableWorkOrderItem = (props) => {

    const {
        itemTest, index, removeExamProfile, number_format, handleCheckSpecimen, handleCheckForwarded, checkTypeClient, 
        handleCheckUrgent, changeSelectSamplings,
        changeValueTimeSampling, times, handleViewExamsProfile, listid, enable_tracking_toma,

        rowsSpan = null, availableRowsSpan = false, nameCampaign = "", availableByIndex = null, isEdit = false, 
        removeExamProfileByCampaign,
        uniqueKey, handleChangeExternalLaboratories, count
    } = props;
        console.log("🚀 ~ TableWorkOrderItem ~ itemTest:", itemTest)

    let ur_helen = localStorage.getItem('url_helen');



    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            zIndex: 999
        }),
    }

    let _listCurves = [];

    if (isEdit) {
        _listCurves = (itemTest.workOrderCurves !== undefined && itemTest.workOrderCurves !== null) ? itemTest.workOrderCurves.listTimeCurves : [];
    }
    else {
        _listCurves = (itemTest.curves_aux !== undefined && itemTest.curves_aux !== null) ? itemTest.curves_aux : [];
    }

    // ? CONFIGURATION DEFAULT EXTERNAL
    if (ur_helen === "https://cedimi.helen-sw.com" && itemTest.is_referenced && itemTest.listExternalLaboratories !== null && itemTest.listExternalLaboratories.length > 1) {
        const _filter = itemTest.listExternalLaboratories.filter(_find => _find.default_lab);
        itemTest.listExternalLaboratories = _filter;
    }


    return (
        <>
            <tr key={uniqueKey} className={(itemTest.discount > 0 && itemTest.auto_redeem) ? "bg-light-danger discountB" : "border-bottom-info "}>
                {
                    availableRowsSpan && availableByIndex === 0 && <td style={{ verticalAlign: "middle", width: "8%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")} rowSpan={rowsSpan}>
                        <b>{nameCampaign}</b>
                    </td>
                }
                {
                    !availableRowsSpan && availableByIndex == null && <td style={{ verticalAlign: "middle", width: "8%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")}>
                        {
                            itemTest.code
                        }
                    </td>
                }

                <td style={{ verticalAlign: "middle", width: "18%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")} >
                    {
                        availableRowsSpan ? `${itemTest.code} - ${itemTest.name}` : `${itemTest.name}`
                    }
                    {// ? CONFIGURATION DEFAULT EXTERNAL

                        ur_helen !== "https://cedimi.helen-sw.com" && itemTest.is_referenced ? <><br /><span className='badge badge-primary'>{itemTest.name_external_laboratory}</span></> : ''
                    }
                    {
                        itemTest.is_curve && <Row className='m-t-10 m-b-5'>
                            <Col sm="12" md="12" lg="12" xl="12">
                                <Input
                                    type='select'
                                    className='form-control form-control-sm'
                                    onChange={(e) => changeSelectSamplings(e, itemTest.id_profile, isEdit)}
                                    value={
                                        (
                                            isEdit
                                                ? ((itemTest.workOrderCurves !== undefined && itemTest.workOrderCurves !== null) ? itemTest.workOrderCurves.id_profile_curve_price : 0)
                                                : (itemTest.is_quotation ? (itemTest.id_profile_curve_price) : (itemTest.curves_aux !== undefined ? itemTest.curves_aux[0].id_profile_curve_price : 0))
                                        )
                                    }
                                >
                                    <option value="0">Selecciona una opción de toma</option>
                                    {
                                        itemTest.curves !== null && itemTest.curves.length > 0 && itemTest.curves.map((c, i) => {
                                            return <option value={c.id_profile_curve_price}>{c.number_sampling} Tiempos</option>
                                        })
                                    }
                                </Input>
                            </Col>
                        </Row>
                    }
                </td>
                <td style={{ verticalAlign: "middle", width: "4%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")} >
                    {itemTest.abbreviation}
                    &nbsp;
                    {itemTest.id_profile !== null ? <Button size='xs' color='primary' onClick={() => handleViewExamsProfile(itemTest.id_profile)}><i className="icofont icofont-laboratory"></i></Button> : ""}
                </td>
                <td style={{ textAlign: "center", verticalAlign: "middle", width: "8%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")} >
                    {isEdit
                        ? (
                            itemTest.is_curve
                                ? (itemTest.workOrderCurves !== null
                                    ? `$ ${number_format(itemTest.workOrderCurves.price, 2)}`
                                    : "0.00")
                                :  (itemTest.config_iva
                                    ? `$ ${number_format(itemTest.price, 2)}` 
                                    : itemTest.id_agreement !== null 
                                        ? `$ ${number_format((itemTest.price_Agremment * 1.16).toFixed(2))}` 
                                        : `$ ${number_format((itemTest.price * 1.16).toFixed(2))}`
                                    )
                        )
                        : (
                            itemTest.is_curve
                                ? (itemTest.curves_aux !== undefined && itemTest.curves_aux.length > 0
                                    ? `$ ${number_format(itemTest.price, 2)}`
                                    : null)
                                : (itemTest.config_iva
                                    ? `$ ${number_format(itemTest.price, 2)}`
                                    : (itemTest.id_agreement === null || itemTest.id_agreement === 0) 
                                        ? `$ ${number_format((itemTest.price * 1.16).toFixed(2))}`
                                        : `$ ${number_format((itemTest.price_Agremment * 1.16).toFixed(2))}`
                                )
                        )
                    }
                </td>
                <td style={{ textAlignLast: "center", verticalAlign: "middle", width: "14%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")} >
                    <div className="checkbox checkbox-primary mt-n2">
                        <Input
                            type='checkbox'
                            id={`checkboxP${(itemTest.id_profile === null ? itemTest.id_exam : itemTest.id_profile)}`}
                            checked={itemTest.is_urgent}
                            onChange={(e) => handleCheckUrgent(e, itemTest.id_exam, itemTest.id_profile)}
                        />
                        <Label for={`checkboxP${(itemTest.id_profile === null ? itemTest.id_exam : itemTest.id_profile)}`}></Label>
                    </div>
                </td>
                {
                    itemTest.id_profile !== null ?
                        <td className={enable_tracking_toma == true || enable_tracking_toma == "True" ? "d-none" : ""} style={{ textAlignLast: "", width: "14%", verticalAlign: "middle" }}>
                            {
                                itemTest.array_specimens !== null && itemTest.array_specimens.length > 0 && itemTest.array_specimens.map((x, i) => {
                                    return <div key={i}>
                                        <div className="checkbox checkbox-primary mt-n2">
                                            <Input id={`checkbox${itemTest.id_profile + "-" + i}`} checked={x.check_specimen} onChange={() => handleCheckSpecimen(null, itemTest.id_profile, x.id_specimen)} type="checkbox" />
                                            <Label for={`checkbox${itemTest.id_profile + "-" + i}`}>{x.specimen_name}</Label>
                                        </div>

                                    </div>
                                })
                            }

                        </td>
                        :
                        <td className={enable_tracking_toma == true || enable_tracking_toma == "True" ? "d-none" : ""} style={{ textAlignLast: "", width: "14%", verticalAlign: "middle" }}>
                            <div className="checkbox checkbox-primary mt-n2">
                                <Input id={`checkbox${itemTest.id_exam}`} checked={itemTest.check_specimen} onChange={() => handleCheckSpecimen(itemTest.id_exam)} type="checkbox" />
                                <Label for={`checkbox${itemTest.id_exam}`}>{itemTest.specimen_name}</Label>
                            </div>
                        </td>
                }
                {
                    itemTest.id_profile !== null ?
                        <td style={{ verticalAlign: "middle", width: "14%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")}>
                            {
                                (itemTest.array_specimens !== null && itemTest.array_specimens.length > 0) && itemTest.array_specimens.map((x, i) => {
                                    return <div key={i}>
                                        <div className="checkbox checkbox-primary mt-n2">
                                            <Input id={`checkbox_re${itemTest.id_profile + "-" + i}`} checked={x.check_forwarded} onChange={() => handleCheckForwarded(null, itemTest.id_profile, x.id_specimen)} type="checkbox" />
                                            <Label for={`checkbox_re${itemTest.id_profile + "-" + i}`}>{x.specimen_name}</Label>
                                        </div>
                                    </div>
                                })
                            }
                        </td>
                        :
                        <td style={{ verticalAlign: "middle", width: "14%" }} className={(itemTest.is_urgent === true ? "text-danger" : "")}>
                            <div className="checkbox checkbox-primary mt-n2">
                                <Input id={`checkbox_re${itemTest.id_exam}`} checked={itemTest.check_forwarded} onChange={() => handleCheckForwarded(itemTest.id_exam)} type="checkbox" />
                                <Label for={`checkbox_re${itemTest.id_exam}`}>{itemTest.specimen_name}</Label>
                            </div>
                        </td>
                }
                {
                    itemTest.id_agreement !== null && itemTest.id_agreement !== 0 &&
                    <td className={(itemTest.is_urgent === true ? "text-danger" : "")}>
                        <span>Contrato: {itemTest.contract_number}</span>
                    </td>
                }
                {
                    availableRowsSpan && availableByIndex === 0 && <td style={{ verticalAlign: "middle", width: "1%" }} rowSpan={rowsSpan}>
                        <ButtonGroup>
                            <Button
                                className=" btn-air-danger "
                                outline color="danger"
                                size="xs"
                                key={index}
                                onClick={() => removeExamProfileByCampaign(itemTest.id_common_discount)}><i className="fa fa-trash-o"></i></Button>
                        </ButtonGroup>
                    </td>
                }
                {
                    !availableRowsSpan && availableByIndex == null && <td style={{ verticalAlign: "middle", width: "1%" }} >
                        <ButtonGroup>
                            <Button
                                index
                                className=" btn-air-danger "
                                outline color="danger"
                                size="xs"
                                key={index}
                                onClick={() => removeExamProfile(itemTest.id_exam, itemTest.id_profile, index, count)}><i className="fa fa-trash-o"></i></Button>
                        </ButtonGroup>
                    </td>
                }
            </tr>
            {    // ? CONFIGURATION DEFAULT EXTERNAL
                ur_helen !== "https://cedimi.helen-sw.com" && !isEdit && itemTest.is_referenced && itemTest.listExternalLaboratories !== null && itemTest.listExternalLaboratories.length > 1 && <tr key={`External${uniqueKey}`}>
                    <td colSpan={2}>
                        <FormGroup>
                            <Label>Laboratorio de referencia</Label>
                            <Input type='select' className='form-control form-control-sm' value={itemTest.id_price} onChange={((e) => handleChangeExternalLaboratories(e, itemTest.id_exam))}>
                                <option value={0}>Selecciona una opción</option>
                                {
                                    itemTest.listExternalLaboratories.map((_external, _indexExternal) => {                                        
                                        return (
                                            <option key={`External${_external.id_price}-${_indexExternal}`} value={_external.id_price}>{_external.name_external_laboratory} - {_external.price}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </td>
                </tr>
            }
            {
                _listCurves.length > 0 && _listCurves.map((curva, llave) => {
                    return (<tr key={"curva-" + llave} style={{ height: 60 }} className="border-bottom-info">
                        <td style={{ verticalAlign: "middle", width: "10px" }}>
                            {itemTest.code}-{llave + 1}
                        </td>
                        <td style={{ verticalAlign: "middle", }}>
                            Configura el tiempo de la toma
                        </td>
                        <td colSpan={2} style={{ textAlignLast: "center" }}>
                            <Select
                                // className="form-control form-control-plaintext form-control-sm input-sm"
                                classNamePrefix="select"
                                options={times}
                                name="--"
                                // isClearable={false}
                                value={isEdit ? curva : curva.time}
                                placeholder={`Selecciona un tiempo`}
                                onChange={(e) =>
                                    changeValueTimeSampling(e, itemTest.id_profile, isEdit ? llave : curva.number_sampling_aux, isEdit)
                                }
                                styles={customStyles}
                                theme={
                                    (theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#898989'
                                        }
                                    })
                                }
                                menuPlacement="auto"
                            />
                        </td>
                        <td colSpan={4}></td>
                    </tr>)
                })
            }
        </>
    )
}
