import React from 'react'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader,Col,Row } from 'reactstrap';

export const ModalSpecialDiscount = (props) => {

    const {
        modal, toggle, special_discount_code, handleInputChangeSpecialDiscount, validateCodeSpecialDiscount,validatePercentajeSpecialDiscount,percentage,
        validPorcentejeSpecial 
    } = props;

    return (
        <Modal isOpen={modal} toggle={toggle} backdrop='static' keyboard={false} centered={true} size='lg' >
            <ModalHeader toggle={toggle} className="bg-primary" style={{textAlign : "center"}}>
               Descuentos Especiales
            </ModalHeader>
            <ModalBody>
                <Col sm="12">
                    <Row>
                        <Col sm="5" className='p-1' > 
                        <Form role="form">
                            <FormGroup className="col-12">
                                <Label className='f-w-600 f-12 badge badge-light-primary'>Ingresa el código de descuento</Label>
                                <Input
                                    name="special_discount_code"
                                    value={special_discount_code}
                                    required={true}
                                    onChange={handleInputChangeSpecialDiscount}
                                    style={{ textTransform: "uppercase" }}
                                    className='form-control form-control-sm input-air-primary'
                                />
                            </FormGroup>
                        </Form>
                        </Col>
                        <Col sm="1">
                        </Col>
                        <Col sm="5"> <br /> <br />
                        <Button  size='sm' utline color="secondary" type="button" onClick={validateCodeSpecialDiscount}>Aplicar descuento especial</Button>
                        </Col>
                    </Row>
                </Col>
                <br /> <br />
                <Col sm="12" className={(validPorcentejeSpecial === "https://labcen.helen-sw.com" || validPorcentejeSpecial === "https://cedimi.helen-sw.com" || validPorcentejeSpecial === "https://hsjs.helen-sw.com" )  ? "" : "d-none"}>
                    <Row>
                        <Col sm="5" className='p-1' > 
                        <Form role="form">
                            <FormGroup className="col-12">
                            <Label className='f-w-600 f-12 badge badge-light-primary'>Ingresa el porcentaje de descuento</Label>
                            <Input
                                name="percentage"
                                value={percentage}
                                onChange={handleInputChangeSpecialDiscount}
                                style={{ textTransform: "uppercase" }}
                                className='form-control form-control-sm input-air-primary'
                            />
                        </FormGroup>
                        </Form>
                        </Col>
                        <Col sm="1">
                        </Col>
                        <Col sm="5"> <br /> <br />
                        <Button size='sm'  outline color="primary" type="button" onClick={validatePercentajeSpecialDiscount}>Aplicar porcentaje especial</Button>
                        </Col>
                    </Row>
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button  outline color="danger" type="button" onClick={toggle}>Cerrar visualización</Button>
            </ModalFooter>
        </Modal>
    )
}
