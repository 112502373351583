import React, { useState, useEffect, Fragment, useRef } from 'react'
import '../../../../assets/css/pantalla1.css'
import '../../../../assets/css/cartaConsentimiento.css'
import { Container, Row, Col, Label} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useConsentimiento } from '../../../../hooks/tomaMuestra/useConsentimiento';
import { useLogin } from '../../../../hooks/tomaMuestra/useLogin';
import 'animate.css';
import { CanvasComponent } from '../../../canvas/CanvasComponent';
import { ModalRefusedSample } from './ModalRefusedSample';
import { sendRequest } from '../../../../hooks/requests/useRequest';
import {NameExam,hourExam,hour2Exam,activity ,activity2,medicine,medicine2,pomade,pregnancy,procedure,pomade2} from '../../../../constant/sampleConsent'




export const IndexCarta1 = () => {

    const { nimm } = useParams();


    let name2 = localStorage.getItem('flebotomisaNombre');
    let name5 = localStorage.getItem('willson');
    let _config_client = localStorage.getItem('url_helen');


    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(true);
    const [showMedicine, setShowMedicine] = useState(true);
    const [nim, setNim] = useState("");
    const [patient, setPatient] = useState("");
    const [gender, setGender] = useState(null)

    const {
        navigatePrev, navigateNext, viewTab, saveResponseQuestion, handleChange, saveUpdateCiSample,
        viewButtonsCreate,
        hour, minute, second, iniImg,
        difHourBrakfast, difMinuteBrakfast,
        saveUpdateCancell, alterQuestion, repeatFirm,
        //refused Sample
        handleSelectValuesR, id_cancellation_reazon_sample, reason, typeModalRefused, modalRefused,
        toggleRefused, handleRefusedSample, refusedPatient, validationsSG
    } = useConsentimiento();

    const { time, reload } = useLogin();

    const canvasRef = useRef(null);

    useEffect(() => {

        if (nimm !== undefined) {
            getDataFromNimPost(nimm)
        }
    }, [nimm]);

    const getDataFromNimPost = async (nimm) => {

        let body = {
            nim: nimm.substring(0, 13),
            id_departament: localStorage.getItem('depW')
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(body)
        };

        const respuesta = await sendRequest(requestOptions, "Sass/GetDataFromPatient");
        ////console.log(respuesta);

        if (respuesta.code === 200) {
            let namePatient = "";
            let nimPatient = "";
            let genderPatien = "";


            respuesta.data.lista_pruebas.forEach(element => {
                console.log("🚀 ~ getDataFromNimPost ~ element:", element)

                namePatient = element.name_patient
                nimPatient = element.nim
                genderPatien = element?.gender  ??  null
            });
            setNim(nimPatient);
            setPatient(namePatient);
            setGender(genderPatien)
        }
    }

    let _validPage = (_config_client === "https://cedimi.helen-sw.com") ?  7 : 9

    

    return (
        <Fragment>
            <Container fluid={true} className='bg-barra1 question'>
                <Col sm="12">
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-l-0"> <br />
                            <div className='box-departamento'>
                                <Col sm="12" style={{ textAlign: "-webkit-center" }}>
                                    <Row>
                                        <Col xs="2" sm="2" md="2" lg="2" xl="2">
                                            <Label className='labelToma1'>{time}</Label>
                                        </Col>
                                        <Col xs="7" sm="7" md="7" lg="7" xl="7"  >
                                            <label className='labelToma1'>Toma de Muestra&nbsp;/&nbsp;{name5}</label>
                                        </Col>

                                        <Col xs="2" sm="2" md="2" lg="2" xl="2" className='p-1' style={{ textAlign: "-webkit-right" }}>
                                            {
                                                show1 ? (<label className='rc'>{hour}:{minute}:{second}</label>) : ""
                                            }
                                        </Col>
                                        <Col xs="1" sm="1" md="1" lg="1" xl="1" className='p-2' >
                                            {
                                                show1 ? (<img className=" " src={require("../../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "27%" }} alt="" />
                                                ) : ""
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Col>


                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <Row>

                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Col sm="" style={{ position: 'absolute', zIndex: 5 }} className='float-right divMenu p-l-0' >
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12"><br /> <br />
                                            {
                                                show ?
                                                    (
                                                        <div></div>
                                                    )
                                                    :
                                                    (
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <div className='username-Active ' >
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    <Row>
                                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-0' style={{ textAlign: "-webkit-center" }} >
                                                                            <br />
                                                                            <i className="fa fa-circle f-28 icon-active"></i>
                                                                        </Col>
                                                                        <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0'> <br />
                                                                            <label className='label-menuToma p-0'>{name2}</label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </div> <br />

                                                            <div className="username-Active pointer " onClick={reload} >
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    <Row>
                                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                                                            <img
                                                                                className=" "
                                                                                src={require("../../../../assetsToma/imagenes/Recargar.png")}
                                                                                style={{ width: "43%" }}
                                                                                alt=""
                                                                            />
                                                                        </Col>
                                                                        <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                                                                            <label className="r-label-active p-0 pointer">
                                                                                {"Recargar"}
                                                                            </label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </div>{" "} <br />
                                                            <div className='username-Active pointer' onClick={() => { setShow1(!show1); }}>
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    <Row>
                                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }}> <br />
                                                                            <img className=" " src={require("../../../../assetsToma/imagenes/Cronometro.png")} style={{ width: "45%" }} alt="" />
                                                                        </Col>
                                                                        <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 '> <br />
                                                                            <label className='r-label-active p-0 pointer'>{"Cronómetro"}</label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </div><br />
                                                            <div className='username-Active pointer' onClick={handleRefusedSample}   >
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                    <Row>
                                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" className='p-l-0 p-r-0 p-t-2 ' style={{ textAlign: "-webkit-center" }} > <br />
                                                                            <img className=" " src={require("../../../../assetsToma/imagenes/CerrarBlanco.png")} style={{ width: "36%" }} alt="" />
                                                                        </Col>
                                                                        <Col xs="9" sm="9" md="9" lg="9" xl="9" className='p-l-0 p-r-0 ' > <br />
                                                                            <label className='r-label-active p-0 pointer'>{"Cancelar Toma"}</label>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </div>
                                                        </Col>

                                                    )
                                            }
                                        </Col>
                                    </Col>
                                    <Row>
                                    {_config_client === "https://cedimi.helen-sw.com" && <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === 1 ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                {/* <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" /> */}
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>1/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{NameExam}</label>
                                                </Col><br /> <br /> 
                                                <Col sm="12">
                                                <Row>
                                                <Col xs="7" sm="7" md="7" lg="7" xl="7">
                                                <textarea  style={{borderColor:" #09D4FC"}}  onChange={(e) => saveResponseQuestion(viewTab, e.target.value, 0, 0, 1)} className='border-black-informed' />
                                                  <br />
                                                  <br />
                                                 
                                                </Col>
                                                <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlignLast: "center" }}>  <br />
                                                            <button className={viewButtonsCreate ? 'button-eat-Consentimiento' : 'd-none'} onClick={() => navigateNext(viewTab)}>Aceptar</button>

                                                        </Col>
                                                    
                                                </Row>
                                                </Col>
                                              <br /> <br /> <br /> <br /><br />

                                            </div>

                                        </Col>}
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 2 : 1) ? "p-l-0 p-r-0" : "d-none"}>
                                            <div xs="12" sm="12" md="12" lg="12" xl="12">
                                                <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                    <label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                        <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                        <br />
                                                    </label>
                                                    <br /> <br />
                                                </Col>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div className='box-pagination-button-encuesta'>
                                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <Row>
                                                                <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />

                                                                </Col>
                                                                <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                    <label className='r-pagination-button' >{(_config_client === "https://cedimi.helen-sw.com" ? 2 : 1)}/{_validPage}</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </div>
                                                </Col>
                                                <br /> <br /> <br /> <br />

                                                <Col xs="12" sm="12" md="12" lg="12" xl="12"  >
                                                    <Label className='label-Question-Toma1'>{_config_client === "https://cedimi.helen-sw.com" ? hourExam : hour2Exam }</Label>
                                                </Col>
                                                <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                                                    <Row>

                                                        <Col xs="5" sm="5" md="5" lg="5" xl="5" style={{ textAlignLast: "center" }}>
                                                            <br />
                                                            <input
                                                                className='TimeEat'
                                                                type="time"
                                                                onChange={(e) => saveResponseQuestion(viewTab, e.target.value, 0, 0, 1)}
                                                            />
                                                        </Col>
                                                        <Col xs="2" sm="2" md="2" lg="2" xl="3" style={{ textAlignLast: "center" }}> <br /> <br /> <br />
                                                        {
                                                        isNaN(difHourBrakfast) ? 
                                                            <button
                                                            className={
                                                                (alterQuestion.tab === viewTab && alterQuestion.response === "No Aplica") ?
                                                                "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                            }
                                                            onClick={() => saveResponseQuestion(viewTab, "No Aplica", 0, 1)}>
                                                            {"No Aplica"}
                                                            </button>
                                                            : 
                                                            <button 
                                                            className={viewButtonsCreate ? 'button-eat-Consentimiento' : 'd-none'} 
                                                            onClick={() => navigateNext(viewTab)}>
                                                            {`Ayuno de ${difHourBrakfast}:${difMinuteBrakfast}`}
                                                            </button>
                                                        }

                                                        </Col>
                                                   
                                                    </Row>
                                                </Col>
                                         
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 3 : 2) ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>{(_config_client === "https://cedimi.helen-sw.com" ? 3 : 2)}/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{_config_client === "https://cedimi.helen-sw.com" ? activity : activity2 }</label>
                                                </Col><br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response == "Si") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Si", 0, 1)}>{"Sí"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response == "No") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No", 0, 1)}>{"No"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No Aplica") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No Aplica", 0, 1)}>{"No Aplica"}</button>
                                                        </Col>
                                                    </Row>
                                                </Col><br /> <br /> <br /> <br /><br />

                                            </div>

                                        </Col>
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 4 : 3) ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>{(_config_client === "https://cedimi.helen-sw.com" ? 4 : 3)}/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col>  <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                {
                                                    showMedicine ?
                                                        (
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                                <div className='a-line-carta'>
                                                                    <label className='label-Question-5'>
                                                                    {_config_client === "https://cedimi.helen-sw.com" ? medicine : medicine2 }
                                                                    </label>

                                                                </div> <br /> <br />

                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12"> <br />  <br /> <br />
                                                                    <Row>
                                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                                            <button
                                                                                className='button-carta-Consentimiento-Page2-vacio'
                                                                                onClick={() => { setShowMedicine(!showMedicine) }}>{"Sí"}</button>
                                                                        </Col>
                                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                                            <button
                                                                                className={
                                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No") ?
                                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                                }
                                                                                onClick={() => saveResponseQuestion(viewTab, "No", 0, 1)}>{"No"}</button>
                                                                        </Col>
                                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                                            <button
                                                                                className={
                                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No-aplica") ?
                                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                                }
                                                                                onClick={() => saveResponseQuestion(viewTab, "No-aplica", 0, 1)}>{"No Aplica"}</button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Col>
                                                        )
                                                        :
                                                        (
                                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                                                                <label className='label-Question'>{"¿Qué medicamento?"}</label>
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-2">
                                                                    <textarea
                                                                        cols="68"
                                                                        rows="5"
                                                                        className="estilotextarea"
                                                                        placeholder="Escriba aquí"
                                                                        onBlur={(e) => saveResponseQuestion(viewTab, 'Si-' + e.target.value, 0, 0)}
                                                                    >
                                                                    </textarea>
                                                                </Col>
                                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className="p-3" >
                                                                    <Row>
                                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" >
                                                                        </Col>
                                                                        <Col xs="8" sm="8" md="8" lg="8" xl="8" >
                                                                            <button className='button-carta-Consentimiento-Page2-lleno' onClick={() => navigateNext(viewTab)}>{"Siguiente"}</button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Col>
                                                        )
                                                }

                                            </div>

                                            <br /> <br />
                                        </Col>

                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 5 : 4) ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>{(_config_client === "https://cedimi.helen-sw.com" ? 5 : 4)}/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{_config_client === "https://cedimi.helen-sw.com" ? pomade : pomade2 }</label>
                                                </Col><br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response == "Si") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Si", 0, 1)}>{"Sí"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response == "No") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No", 0, 1)}>{"No"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No Aplica") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No Aplica", 0, 1)}>{"No Aplica"}</button>
                                                        </Col>
                                                    </Row>
                                                </Col><br /> <br /> <br /> <br /><br />
                                            </div>
                                        </Col>
                                        {_config_client === "https://cedimi.helen-sw.com" && gender === 1 &&<Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 6 : 5) ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col sm="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>{(_config_client === "https://cedimi.helen-sw.com" ? 6 : 6)}/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{_config_client === "https://cedimi.helen-sw.com" ? pregnancy : pregnancy }</label>
                                                </Col><br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response == "Si") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Si", 0, 1)}>{"Sí"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response == "No") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No", 0, 1)}>{"No"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No Aplica") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No Aplica", 0, 1)}>{"No Aplica"}</button>
                                                        </Col>
                                                    </Row>
                                                </Col><br /> <br /> <br /> <br /><br />
                                            </div>
                                        </Col>}

                                        {_config_client !== "https://cedimi.helen-sw.com" && <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === 5 ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>5/9</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{"¿Padece o tiene antecedentes familiares con?"}</label>
                                                </Col><br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlignLast: "center" }} >
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Diabetes")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Diabetes", 1, 0)}>Diabetes</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Hipertensión")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Hipertensión", 1, 0)} >Hipertensión</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Ninguno")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Ninguno", 1, 0)}>Ninguno</button>
                                                        </Col>
                                                    </Row>
                                                </Col><br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "-webkit-center" }} >
                                                    <button className='button-carta-Consentimiento-finalizar' onClick={() => navigateNext(viewTab)}>Siguiente</button>
                                                </Col><br /> <br /> <br /> <br />
                                            </div>
                                        </Col>}
                                        {_config_client !== "https://cedimi.helen-sw.com" &&<Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === 6 ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>6/9</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <div className='a-line-carta'>
                                                        <label className='label-BoxQuestion-Toma1'>{"¿Ha presentado eventos de mareo, vomito, desmayo "}
                                                            {" durante la toma de muestra?"}
                                                        </label>


                                                    </div>
                                                </Col><br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "Si") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Si", 0, 1)}>{"Sí"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }} >
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No", 0, 1)}>{"No"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No Aplica") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No Aplica", 0, 1)}>{"No Aplica"}</button>
                                                        </Col>
                                                    </Row>
                                                </Col><br /> <br /> <br /> <br />
                                            </div>

                                        </Col>}
                                        {
                                            // ? modificar
                                        }
                                        {_config_client === "https://cedimi.helen-sw.com" && <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === ((_config_client === "https://cedimi.helen-sw.com" && gender === 1 ) ? 7 : 6) ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>{(_config_client === "https://cedimi.helen-sw.com" ? 7 : 7)}/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{procedure}</label>
                                                </Col><br /> <br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                     
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == (gender === 1 ? viewTab : 7) && alterQuestion.response === "Si") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion((gender === 1 ? viewTab : 7), "Si", 1, 0)}>{"Sí"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == (gender === 1 ? viewTab : 7) && alterQuestion.response === "No") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion((gender === 1 ? viewTab : 7), "No", 1, 0)}>{"No"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == (gender === 1 ? viewTab : 7) && alterQuestion.response === "No Aplica") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion( (gender === 1 ? viewTab : 7) , "No Aplica", 1, 0)}>{"No Aplica"}</button>
                                                        </Col>


                                                    </Row>
                                                    <Row> <br />
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6" style={{ textAlign: "center" }}> <br /> <br />
                                                        <button className='button-carta-Consentimiento-Page14' onClick={() => saveUpdateCiSample()}>Doy mi consentimiento</button>
                                                    </Col>

                                                    </Row>
                                                </Col><br /> <br /> <br /> <br /><br />

                                            </div>

                                        </Col>}
                                        {_config_client !== "https://cedimi.helen-sw.com" && <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 7 : 7) ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className=''>
                                                                <label className='r-pagination-button'>{(_config_client === "https://cedimi.helen-sw.com" ? 7 : 7)}/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{procedure}</label>
                                                </Col><br /> <br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                     
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "Si") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Si", 0, 1)}>{"Sí"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No", 0, 1)}>{"No"}</button>
                                                        </Col>
                                                        <Col xs="4" sm="4" md="4" lg="4" xl="4" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response === "No Aplica") ?
                                                                        "button-carta-Consentimiento-Page2-lleno" : "button-carta-Consentimiento-Page2-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "No Aplica", 0, 1)}>{"No Aplica"}</button>
                                                        </Col>


                                                    </Row>
                                                    <Row>
                                              
                                                    </Row>
                                                </Col><br /> <br /> <br /> <br /><br />

                                            </div>

                                        </Col>}

                                        {_config_client !== "https://cedimi.helen-sw.com" &&<Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === 8 ? "p-l-0 p-r-0" : "d-none"}>
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Concentimiento-Toma1'>Carta de Consentimiento Informado<br />
                                                    <label className='label-Concentimiento-Toma1'>REG-009-PRE </label>
                                                    <br />
                                                </Label>
                                                <br /> <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-1'>
                                                                <label className='r-pagination-button-10'>8/{_validPage}</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br /> <br /> <br /> <br />
                                            <div className='animate__animated animate__fadeInRightBig animate__faster 1000ms'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <label className='label-BoxQuestion-Toma1'>{"Presenta alergia a (marque todas las opciones que apliquen):"}</label>
                                                </Col><br /> <br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Alcohol")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Alcohol", 1, 0)}>Alcohol</button>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Yodo")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Yodo", 1, 0)} >Yodo</button>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Ácido Acético")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Ácido Acético", 1, 0)}>Ácido Acético</button>
                                                        </Col>
                                                        <Col xs="3" sm="3" md="3" lg="3" xl="3" style={{ textAlign: "center" }}>
                                                            <button
                                                                className={
                                                                    (alterQuestion.tab == viewTab && alterQuestion.response.includes("Ninguno")) ?
                                                                        "button-carta-Consentimiento-Page12-lleno" : "button-carta-Consentimiento-Page12-vacio"
                                                                }
                                                                onClick={() => saveResponseQuestion(viewTab, "Ninguno", 1, 0)}>Ninguno</button>
                                                        </Col>
                                                    </Row>
                                                </Col><br /> <br />
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12" style={{ textAlign: "center" }} >
                                                    <button className='button-carta-Consentimiento-finalizar' onClick={() => navigateNext(viewTab)}>Siguiente</button>

                                                </Col><br /> <br /> <br /> <br />

                                            </div>

                                        </Col>}

                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className={viewTab === (_config_client === "https://cedimi.helen-sw.com" ? 8 : 9) ? "p-l-0 p-r-0" : "d-none"}>
                                            {/* anteriormente era 8 */}
                                            <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>
                                                <Label className='label-Carta-Toma1'> Carta de Consentimiento Informado <br />
                                                    <label className='label-Carta-Toma1'>REG-009-PRE</label>
                                                    <br />
                                                </Label>
                                                <br />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div className='box-pagination-button-encuesta'>
                                                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                        <Row>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                                <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} onClick={() => navigatePrev(viewTab)} style={{ width: "40%" }} alt="" />
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-1'>
                                                                <label className='r-pagination-button-10'>9/9</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            </Col> <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <label className='label-Carta-Informado'>Declaro haber recibido toda la información relacionada con los exámenes a los que seré sometido(a)
                                                    y que ha sido clara y precisa; se me explica el procedimiento que se me realizará, y he tenido la ocasión
                                                    de formular todas las preguntas que he creído convenientes en relación al procedimiento. Así mismo,
                                                    deslindo al laboratorio de cualquier decisión que yo tome derivado de mis resultados.
                                                    En conocimiento a lo anterior DOY MI CONSENTIMIENTO para la realización de estos exámenes.
                                                </label>
                                            </Col> <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-0'>
                                                <div className='border-canvas' style={{ textAlign: "-webkit-center" }}>
                                                    {
                                                        viewTab === 9 //8 anteriormente
                                                            ?
                                                            <CanvasComponent
                                                                canvasRef={canvasRef}
                                                                handleChange={handleChange}
                                                                width={820}
                                                                height={150}
                                                                brushColor={"#000"}
                                                                brushRadius={1}
                                                                lazyRadius={3}
                                                                previousImage={iniImg}
                                                            />
                                                            : ""
                                                    }
                                                </div>
                                            </Col> <br />
                                            <Col xs="12" sm="12" md="12" lg="12" xl="12" >
                                                <Row>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6" style={{ textAlign: "center" }}>
                                                        <button className='button-carta-Repetir' onClick={() => repeatFirm(canvasRef)} type="button">Repetir firma</button>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6" lg="6" xl="6" style={{ textAlign: "center" }}>
                                                        <button className='button-carta-Consentimiento-Page14' onClick={() => saveUpdateCiSample()}>Doy mi consentimiento</button>
                                                    </Col>
                                                </Row>

                                            </Col> <br />
                                        </Col>
                                    </Row>
                                    <div className='tap-top' style={{ display: "block" }} onClick={() => { setShow(!show); }}><i className="icon-angle-double-up f-24"></i>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Container >
            {
                <ModalRefusedSample
                    {
                    ...{ //refused Sample
                        handleSelectValuesR, id_cancellation_reazon_sample, reason, typeModalRefused, modalRefused, toggleRefused, name5,
                        name2, nim, patient, refusedPatient, validationsSG
                    }
                    }

                />
            }

        </Fragment >
    )
}
