import {useState, useEffect, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import { validarSesion } from '../tokens/useToken';
import { ModalCreateCommercial, ModalUpdateCommercial, ModalDeleteCommercial, NotFoundDataConn } from '../../constant';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';
import formatEmail  from '../../utils/validations/formatEmail'


export const useCommercialLines = () => {
    
    const history = useHistory();

    const [commercialLines, setCommercialLines] = useState([]);
    const [commercialLineId, setCommercialLineId] = useState(0);

    const [method, setMethod] = useState("");
    const [loading, setLoading] = useState(false);
    const [createSweet] = useSweetAlert();
    
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    const [formValues, handleInputChange, reset, handleUpdateValues] = useForm({
        long_name: "",
        short_name: "",
        rfc: "",
        theme_color: "#2295CE"
    });

    const { long_name, short_name, rfc, theme_color } = formValues;

    const [validaciones, setValidaciones] = useState({
        long_name_valid: false,
        short_name_valid: false,
        rfc_valid: false,
        theme_color_valid: false
    });

    const toggle = () => {
        setModal(!modal);
    }

    const getCommercialLines = useCallback(
        async () => {
            
            let requestOptions = {
                method: 'GET'
            };

            const respuesta = await sendRequest(requestOptions, "CommercialLine/List");

            if (respuesta.code === 200) {
                let newCommercialLine = [];
                if (respuesta.data.length >= 1) {
                    respuesta.data.forEach((item) => {
                        newCommercialLine.push({
                            id_commercial_line: item.id_commercial_line,
                            long_name: item.long_name,
                            short_name: item.short_name,
                            rfc: item.rfc,
                            theme_color: item.theme_color
                        });
                    });

                    setCommercialLines(newCommercialLine);
                    setCommercial(newCommercialLine);
                }
                else{
                    setCommercialLines([]);
                }
            }
            else if (respuesta.code === 401) {

                validarSesion(history, respuesta.code, getCommercialLines);
            }
            else if(respuesta.code === 500){
    
                createSweet("error", "error", "Razón social", "Ocurrio un problema en el servidor");
            }
            else{
    
                createSweet("error", "warning", "Razón social", respuesta.data.msg);
            }
        },
        [history]
    )

    useEffect(() => {
        
        getCommercialLines();

    }, [getCommercialLines]);

    const handleCreate = () => {
        setValidaciones({
            long_name_valid: false,
            short_name_valid: false,
            rfc_valid: false,
            theme_color_valid: false
        });

        handleUpdateValues({
            long_name: "",
            short_name: "",
            rfc: "",
            theme_color: "#2295CE"
        });

        setCommercialLineId(0);
        setMethod("create");
        setModalTitle(ModalCreateCommercial);
        toggle();
    }

    const saveCreate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;    
        }

        setLoading(true);

        let data = JSON.stringify(formValues);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'CommercialLine/Create');

        if (respuesta.code === 200) {
            
            getCommercialLines();
            reset();
            createSweet("create","success", "Exito!", "Se creo la razón social");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveCreate);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Razón social", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Razón social", respuesta.data.msg);
        }
        // else{
        //     createSweet("error","error", "Hubo un error!", NotFoundDataConn);
        //     validarSesion(history, respuesta.code, saveCreate);
        // }
    }

    const handleUpdate = (id_commercial_line) => {
        let commercialLine = commercialLines.find(c => c.id_commercial_line === parseInt(id_commercial_line));

        handleUpdateValues({
            long_name: commercialLine.long_name,
            short_name: commercialLine.short_name,
            rfc: commercialLine.rfc,
            theme_color: commercialLine.theme_color
        });

        setValidaciones({
            long_name_valid: false,
            short_name_valid: false,
            rfc_valid: false,
            theme_color_valid: false
        });

        setCommercialLineId(id_commercial_line);
        setMethod("update");
        setModalTitle(ModalUpdateCommercial);
        toggle();
    }

    const saveUpdate = async (e) => {
        e.preventDefault();

        if (validarForm()) {
            return;    
        }

        setLoading(true);

        let mValues = {
            id_commercial_line: commercialLineId,
            ...{...formValues}
        };

        let data = JSON.stringify(mValues);

        let requestOptions = {
            method: 'PUT',
            body: data,
        };

        const respuesta = await sendRequest(requestOptions, "CommercialLine/Update");

        if (respuesta.code === 200) {
            
            getCommercialLines();
            createSweet("update", "info", "Exito!", "Se actualizo la razón social");
            setLoading(false);
            toggle();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveUpdate);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Razon social", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Razon social", respuesta.data.msg);
        }
    }

    const handleDelete = (id_commercial_line) => {
        let commercialLine = commercialLines.find(c => c.id_commercial_line === parseInt(id_commercial_line));

        handleUpdateValues({
            long_name: commercialLine.long_name,
            short_name: commercialLine.short_name,
            rfc: commercialLine.rfc
        });

        setCommercialLineId(id_commercial_line);
        setMethod("delete");
        setModalTitle(ModalDeleteCommercial);
        toggle();
    }

    const saveDelete = async (e) => {
        e.preventDefault();

        setLoading(true);

        let requestOptions = {
            method: 'DELETE'
        };

        const respuesta = await sendRequest(requestOptions, `CommercialLine/${commercialLineId}`);

        if (respuesta.code === 200) {
            
            getCommercialLines();
            setLoading(false);
            createSweet("delete", "info", "Exito!", "Se elimino correctamente");
            toggle();
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, saveDelete);
        }
        else if(respuesta.code === 500){

            createSweet("error", "error", "Razón social", "Ocurrio un problema en el servidor");
        }
        else{

            createSweet("error", "warning", "Razón social", respuesta.data.msg);
        }
    }

    const validarForm = () => {
        let statusValidacion = false;
        let newValidaciones = {};

        if (formValues.long_name.length < 3) {
            newValidaciones = {
                ...newValidaciones,
                long_name_valid: true
            };
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                long_name_valid: false
            };
        }

        if (formValues.short_name.length < 2) {
            newValidaciones = {
                ...newValidaciones,
                short_name_valid: true
            };
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                short_name_valid: false
            }
        }

        if (formValues.rfc.length < 10) {
            newValidaciones = {
                ...newValidaciones,
                rfc_valid: true
            };
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                rfc_valid: false
            }
        }

        if (formValues.theme_color === "") {
            newValidaciones = {
                ...newValidaciones,
                theme_color_valid: true
            }
            statusValidacion = true;
        }
        else{
            newValidaciones = {
                ...newValidaciones,
                theme_color_valid: false
            }
        }

        setValidaciones(newValidaciones);

        return statusValidacion;
    }

    const [commercial, setCommercial] = useState([]) 

    const handleSearchCommercial = (e) => {

        let busqueda = e.target.value;
        let newMethod = [];

        commercial.forEach(element => {            

            let cadena = element.long_name.toUpperCase();
            if (cadena.indexOf(busqueda.toUpperCase()) > -1) {
                element.visibleTable = true;
                newMethod.push(element);
            }
        });

        setCommercialLines(newMethod);
    }

    return {
        commercialLines, method,
        long_name, short_name, rfc, theme_color, handleInputChange, validaciones,
        toggle, modal, modalTitle, loading,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleSearchCommercial,
    }
}
