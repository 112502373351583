import React, { Fragment } from 'react';
import { Card, Col, Container, Row, Button, CardBody, Table, ButtonGroup, Badge, Input } from 'reactstrap';
import { useCompanies } from '../../../hooks/companies/useCompanies';
import Breadcrumb from '../../../layout/breadcrumb';
import { CompaniesList, Disabled } from '../../../constant';
import { TableStriped } from '../../../components/tables/TableStriped';
import { ModalCompany } from '../../../components/companies/ModalCompany';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { ModalSendEmail } from '../../../components/companies/ModalSendEmail';
//ToolTips

export const IndexCompanies = () => {

    const {
        companies, states, companyTurn, listTaxRegime,
        method, handleInputChange, handlePickerValues, 
        handleSelectValues, validaciones,
        id_company_turn, code, name, is_maquila,
        customer_since, send_results_pacient,
        category,is_block, consecutive_number,
        results_email, logo, microsite, username, imageLogo, 
        payment_by_company, payment_by_patient, 
        require_invoice, available_invoice_patient, credit_days,
        toggle, modal, modalTitle, loading,
        handleChangePaymentBy,
        handleCreate, saveCreate,
        handleUpdate, saveUpdate,
        handleDelete, saveDelete,
        handleChangeState,
        previewLogo, imageHandler,
        handleAddCompany,
        listCompanyBusinessNames,handleDinamicInputRFC,
        arrayMunicipality,removeAlterCompanies,
        totalPageCount, currentPage, nextPage, previousPage, goToPage, handleSearchCompany,
        //new micro
        is_requirements,requirements,on_check_req_especial,password_microsite,stamp_invoice,
         // ? SEND EMAIL
        handleSendEmail,modalEmail,toggleEmail,email,saveEmail,validacionesEmail
    } = useCompanies();


    return (
        <Fragment>
            {/* <Breadcrumb parent="Catálogos" title="Listado de clientes" className="m-b-0" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {CompaniesList}
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchCompany} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {
                                        companies.length === 0 ?

                                        <div style={{ textAlign: "-webkit-center" }}>
                                        <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "30%" }} alt="" />   <br />                                         
                                        <br /> <p className="f-18">{"No se a registrado un Cliente aún."}</p>
                                        </div>
                                        :
                                        <TableStriped
                                            notMaped={["visibleTable", "id_company_turn", "is_maquila", "customer_since", "send_results_pacient", "results_email", "logo", "imgLogo",
                                                "microsite","password_microsite", "category", "is_block", "id_state", "consecutive_number", "listCompanyBusinessNames", "visibleTable","is_requirements","requirements",
                                                "payment_by_company", "payment_by_patient", "require_invoice", "available_invoice_patient", "credit_days","stamp_invoice"
                                            ]}
                                            methodsActions={true}
                                            cabeceras={[ "Código", "Nombre", "RFC", "Giro", "Categoria", "Bloqueo"]}
                                            items={companies}
                                            {...{ handleUpdate, handleDelete }}
                                            methodsAbsolutes={
                                                [
                                                    {
                                                        type: "linkId",
                                                        method: handleSendEmail,
                                                        icon: "icofont icofont-envelope",
                                                        backgroundColor: "#f8d62b",
                                                        color: "#fff",
                                                        tooltip: "Envio a correo",
                                                        disabled: "disabledCompany"
                                                    }
                                                ]
                                            }
                                            nextPage={nextPage}
                                            previousPage={previousPage}
                                            totalPageCount={totalPageCount}
                                            currentPage={currentPage}
                                            goToPage={goToPage}
                                        />                                   
                                    }
                                  
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalCompany
                {
                ...{
                    modal, toggle, modalTitle, method, loading,
                    states, companyTurn, listTaxRegime,
                    handleInputChange, handlePickerValues, handleSelectValues,validaciones,
                    id_company_turn, code, name, is_maquila, customer_since,
                    send_results_pacient, results_email, logo,
                    is_block, consecutive_number, microsite,
                    username, payment_by_company, payment_by_patient, 
                    require_invoice, available_invoice_patient, credit_days,
                    imageLogo, imageHandler,
                    saveCreate, saveUpdate, saveDelete,
                    handleChangePaymentBy,
                    handleChangeState,
                    previewLogo, category, 
                    handleAddCompany,
                    listCompanyBusinessNames, handleDinamicInputRFC, removeAlterCompanies,
                    is_requirements,requirements,on_check_req_especial,password_microsite,stamp_invoice
                }
                }
            />
            <ModalSendEmail
            {
                ...{modalEmail,toggleEmail,email,handleInputChange,saveEmail,validacionesEmail,loading}
            }

            />
        </Fragment>
    )
}
