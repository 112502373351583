import React from 'react'
import { Col, Modal, ModalBody, CardBody, Button,Row } from 'reactstrap';

export const ModalRecollected = (props) => {

    const {modalCollected,handleToggleCollected,dataCollected,saveDetailSamplingsNotCollected} = props;

    return (
        <Modal isOpen={modalCollected} toggle={handleToggleCollected} keyboard={false} centered={true} >
            <ModalBody>
            <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
             
           
                <Col sm="12" style={{textAlign:"center"}}>
                <img alt="flask"  src={require("../../../assets/images/price/warningRecollected.svg")} style={{ width: "90px", height: "90px" }} />
                </Col>
                <Col sm="12" className='pt-1' style={{textAlign:"center"}}>
                <Col sm="4" className='badge-detail-micro' >
                    <Row>
                        <Col sm="3" className='p-r-0' >
                        <i className="fa fa-angle-right f-12"></i>
                        </Col>
                        <Col sm="9" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
                        {dataCollected.folio ?? "--"}
                        </Col>
                    </Row>
                </Col>
                </Col>
                <Col sm="12" className='pt-2'>
                <b className='txt-danger f-w-500 f-14'>Esta muestra no sera reportada esta seguro de realizarlo</b>
                </Col>
                <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                <Row>
                    <Col sm="8" className='p-r-0'>
                    <Button color="warning text-center" type="button"  onClick={() => saveDetailSamplingsNotCollected(dataCollected.folio,dataCollected.id_quoter_type_sample,dataCollected.id_mic_type_sample)} >{"Aceptar"}</Button>
                    </Col>
                    <Col sm="2">
                    <Button color="danger text-center" type="button" onClick={handleToggleCollected} >{"cancelar"}</Button>
                    </Col>

                </Row>
                 
                </Col>
                
            </CardBody>
            </ModalBody>
        </Modal>
    )
}