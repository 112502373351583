 import React, { Fragment,useState } from 'react';
import { Container, Col, Row, Card, CardBody, Button, Table, ButtonGroup,Input,
    Dropdown,DropdownMenu, DropdownItem ,DropdownToggle } from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { useProfiles } from '../../../hooks/profiles/useProfiles';
import { ModalViewProfiles } from './ModalViewProfiles';
import { ModalDelete } from './ModalDelete';
import { ModalViewProfilesCommercial } from './ModalViewProfilesCommercial';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { TableStriped } from '../../../components/tables/TableStriped';




export const IndexProfile = () => {

    const {
        profiles, navigateUpdate, listProfile, handleListProfile,
        modal, toggle, modalTitle, method,
        handleDelete, saveDelete, name, abbreviation, code,
        toggle2, modal2, navigateUpdateP,
        handleListComercial, modal3, toggle3, listCommercial,
        navigateCosts,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchProfile,
        pruebas, 
        //Global
        navigateGlobal

    } = useProfiles();


   


    return (
        <Fragment>
            <Container fluid={true}> <br />
                <Row>
                    <Col sm="12">
                        <Card >
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    Listado de perfiles
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={() => navigateUpdate()}>
                                        <span className="fa fa-plus-circle"></span>
                                    </Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchProfile} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {
                                        profiles.length === 0 ?
                                        <div style={{ textAlign: "-webkit-center" }}>
                                        <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/dashboard/recepcionista.svg")}  style={{ width: "35%" }} alt="" />   <br />                                         
                                        <label className='f-w-600 f-14 badge badge-light-primary'>No se ha encontrado ningún Perfil</label>

                                        </div>
                                        :
                                        <TableStriped
                                        notMaped={["visibleTable", "profileExams", "priceProfileViewModel","is_critic"]}
                                        methodsModal={true}
                                        methodsActions={true}
                                        methodActiveDropdown={false}
                                        cabeceras={[ "Nombre", "Abreviatura","Clave","Exámenes","Precios"]}
                                        items={profiles}
                                         {...{handleDelete }}
                                        nextPage={nextPage}
                                        previousPage={previousPage}
                                        totalPageCount={totalPageCount}
                                        currentPage={currentPage}
                                        goToPage={goToPage}
                                        methodActiveModal={[
                                            {
                                                type: "ModIdM",
                                                method: handleListProfile,
                                                icon: "icofont icofont-laboratory f-12",
                                                name: "handleListProfile",
                                                color: 'secondary',
                                                tooltip: "Exámenes",
                                            },
                                            {
                                                type: "ModIdM",
                                                method: handleListComercial,
                                                icon: "icofont icofont-cur-dollar-plus f-12",
                                                name: "handleListComercial",
                                                color: 'warning',
                                                tooltip: "Precios",
                                            }
                                        ]}
                                        methodsAbsolutes={[
                                            {
                                                type: "linkId",
                                                method: navigateUpdateP,
                                                icon: "fa fa-edit f-14",
                                                backgroundColor: "#A927F9",
                                                color: "#fff",
                                                name: "navigateUpdateP",
                                               // tooltip: "Editar"
                                            },
                                            {
                                                type: "linkId",
                                                method: navigateCosts,
                                                icon: "fa fa-medkit f-14",
                                                backgroundColor: "#7366ff",
                                                color: "#fff",
                                                name: "navigateCosts",
                                                tooltip: "Asociar Kit"
                                            },
                                            {
                                                type: "linkId",
                                                method: navigateGlobal,
                                                icon: "fa fa-tint f-14",
                                                backgroundColor: "#9E114A",
                                                color: "#fff",
                                                name: "navigateGlobal",
                                                tooltip: "Referencia Global"
                                            },
                                        ]}
                                    />   
                                    }       
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalViewProfiles
                {
                ...{
                    modal, toggle, modalTitle, method, listProfile
                }
                }
            />
            <ModalViewProfilesCommercial
                {
                ...{
                    modal3, toggle3, modalTitle, method, listCommercial
                }
                }
            />
            <ModalDelete
                {
                ...{
                    modal2, toggle2, modalTitle, method, name, abbreviation, saveDelete
                }
                }
            />
        </Fragment>
    )
}
