import { sendRequest } from "../../hooks/requests/useRequest";

export async function getInformationCompany (_id_company)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Company/GetInformationCompany/${_id_company}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getInformationAgreement (_id_company)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Agreement/ListAgreementByCompany/${_id_company}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

