import React from 'react'
import { Col, Modal, ModalBody, CardBody, Button,Row,Table,Input,Label } from 'reactstrap';

export const ModalViewSampling = (props) => {
// ? CONFIGURATION 
    const {handleToggleSave,modalSave,optionsTotal,FormatNumbers,formMicSampling,listPay,optionsPayment,handleAddPayMethod,handleDeletePayMethod,handleGiveChange,handleGiveMethod,handleRequiredInvoice,saveMicroWorkorder,loadingQuoter} = props;

    return (
        <Modal isOpen={modalSave} toggle={handleToggleSave} keyboard={false} centered={true} size='lg' >
            <ModalBody className="pricingtable pl-1 pt-1 pr-0 pb-0 p-l-0 p-r-0" style={{textAlign:"left"}}>
            <CardBody className='pt-0 pl-1'>
                <Col sm="12" className='p-l-0 pb-4' >
                <hr />
                <Row>
                    <Col sm="1">
                    <img alt="flask"  src={require("../../../assets/images/price/saveSamplings.svg")} style={{ height: "60px" }} />
                    </Col>
                    <Col sm="3">
                    <label className='f-w-600 f-14' style={{color: "#564e4e", textAlign:"left"}} >Total a pagar:</label><br />
                    <span  className='txt-secondary f-w-600 f-16'>$ {FormatNumbers(optionsTotal.total,2)}</span> 
                    </Col>
                    <Col sm="2" className='p-l-0 p-r-0'>
                    <label  className='f-w-600' style={{color: "#564e4e", textAlign:"left", fontSize:"14px"}} >Requiere factura:</label><br />
                    <Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
                    <Input disabled={loadingQuoter ? true : false}  type="checkbox" name="is_invoice" onClick={handleRequiredInvoice} checked={formMicSampling.is_invoice} /><span className="slider round"></span></Label>
                    </Col>
                    <Col sm="2">
                    </Col>
                    <Col sm="4" style={{textAlign:"right"}}>
                    <Button disabled={loadingQuoter ? true : false} type='button' className='btn-air-primary' outline color='primary' size='sm' onClick={handleAddPayMethod}>
                    <i className='fa fa-plus'></i> Método de pago
                    </Button>
                    </Col>
                </Row>
               
                </Col>
                <Col sm="12" className='p-l-0'>
                <Table>
                    <thead>
                        <tr className='text-center border-bottom-info'>
                        <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Método de pago</label></th>
                        <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Monto</label></th>
                        <th className='p-1 f-12'><label className='text-WorkOrder f-14'>Acciones</label></th>
                        <th className='p-1'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            optionsPayment?.listPayMethods.map((_payMethod,_key) => {
                                return(
                                    <tr key={`method-${_key}`} className={_payMethod?.deleted ? 'd-none' : ''}>
                                        <td>
                                        <Input disabled={loadingQuoter ? true : false} type="select" required={true} className='form-control form-control-sm input-air-primary p-1' name='id_pay_method' data-id={_key}  onChange={(e) => handleGiveMethod(e, 'id_pay_method', 'listPayMethods')} value={_payMethod?.id_pay_method} >
                                        <option value={0}>Selecciona una opción</option>
                                        {
                                            listPay.map(_method => {
                                                return (
                                                    <option   value={_method?.id_pay_method} key={`method-${_method?.id_pay_method}`}>{_method?.name ?? "------"}</option>
                                                )  
                                            })
                                        }
                                        </Input>
                                        </td>
                                        <td>
                                        <Input
                                        disabled={loadingQuoter ? true : false}                                        
                                        data-id={_key}
                                        name="amount"
                                        className='form-control input-air-primary form-control-sm'
                                        placeholder='$ 0.0'
                                        value={_payMethod.amount || ''}
                                        onChange={(e) => handleGiveChange(e, 'amount', 'listPayMethods')}
                                    />
                                        </td>
                                        <td style={{textAlign:"center"}}>
                                            <i className='fa fa-trash-o txt-danger f-24 pointer' onClick={() => handleDeletePayMethod(_key)} ></i>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>                     
                </Table>
                </Col>
                <Col sm="12" style={{textAlign : "right"}} className='p-l-0 ' > <br />
                <Row>
                    <Col sm="10" className='p-r-0'>
                    <Button disabled={loadingQuoter ? true : false} color='warning'  type="button"  onClick={() => saveMicroWorkorder(1)}  >{"Aceptar"}</Button>
                    </Col>
                    <Col sm="2">
                    <Button disabled={loadingQuoter ? true : false} color= "danger text-center" type="button" onClick={handleToggleSave} >{"cancelar"}</Button>
                    </Col>
                </Row>
                </Col> 
            </CardBody>
            </ModalBody>
        </Modal>
    )
}