import React from 'react'
import {Button, Modal, ModalBody,Col, Form, Input, CardBody} from 'reactstrap';


export const ModalUpload = (props) => {

    const {UploadExams,handleOnChange,toggleModalUpload,modalUpload,handleCancellUpload} = props;


    return (
        <Modal isOpen={modalUpload} toggle={toggleModalUpload} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                <Col sm="12"> 
                    <label className='f-w-600 f-14 badge badge-light-secondary'>Selecciona la lista de precios a asignar al tarifario.</label> <br />
                        <Col sm="9"> <br />
                            <Input accept={".csv"} onChange={handleOnChange} type="file" />
                        </Col>                                    
                        </Col> 
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br /> <br />
                            <div className="btngroup">
                                <Button outline color="danger text-center" type="button" onClick={handleCancellUpload}>{"Cancelar"}</Button>
                                <Button outline color="primary text-center" type="button" onClick={UploadExams}  >{"Cargar"}</Button>  
                            </div> 
                        </Col>
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
