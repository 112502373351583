import React, { useState } from 'react';
import { Col, Form, Label, ModalBody, Row, Input, Media, Badge, FormGroup, Table, FormFeedback, Card, FormText, ButtonGroup, ModalFooter, Button, Nav, NavItem, NavLink, TabContent, TabPane, InputGroup, InputGroupAddon, InputGroupText, CardBody } from 'reactstrap';
import Datetime from 'react-datetime';
import 'moment/locale/es';
import "react-datetime/css/react-datetime.css";
import classnames from "classnames";
import './StyleCompany.css'




export const FormCompany = (props) => {

	const {
		toggle, loading, handleInputChange, handlePickerValues, handleSelectValues, validaciones, methodAction,
		states, companyTurn, listTaxRegime, handleChangeState, previewLogo, imageHandler, microsite, password_microsite, wordMicrosite, category, is_block, consecutive_number,
		payment_by_company, payment_by_patient, 
        require_invoice, available_invoice_patient, credit_days,
		handleAddCompany, send_results_pacient,
		listCompanyBusinessNames, handleDinamicInputRFC,
		id_company_turn, code, name, is_maquila, customer_since,
		logo, imageLogo, username, results_email, button,
		removeAlterCompanies, is_requirements, requirements, on_check_req_especial,
		handleChangePaymentBy,stamp_invoice
	} = props;
	
	const [showPassword, setShowPassword] = useState(false);
	
	const {
		id_company_turn_valid,
		code_valid,
		name_valid,
		is_maquila_valid,
		customer_since_valid,
		category_valid,
		is_block_valid,
		//microsite_valid,
		consecutive_number_valid,
		listCompanyBusinessNames_valid,
		results_email_valid
	} = validaciones;

	

	const [activeTab, setActiveTab] = useState(1);

	const toggleTab = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}

	const toggleShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};


	return (
		<Form className="form theme-form " role="form" onSubmit={methodAction}> <br />
			<Nav
				className="nav-fill flex-md-row nav-primary form theme-form"
				id="tabs-icons-text"
				tabs
				role="tablist"
			>
				<NavItem className='form theme-form fondo-company-data'>
					<NavLink
						aria-selected={activeTab === 1}
						className={classnames("mb-sm-3 mb-md-0 form theme-form", {
							active: activeTab === 1
						})}
						onClick={() => { toggleTab(1); }}
						href="#"
						role="tab"
					>
						Datos generales
					</NavLink>
				</NavItem>				
				<NavItem className='form theme-form fondo-company-data'>
					<NavLink
						aria-selected={activeTab === 2}
						className={classnames("mb-sm-3 mb-md-0 form theme-form", {
							active: activeTab === 2
						})}
						onClick={() => { toggleTab(2); }}
						href="#"
						role="tab"
					>
						Datos fiscales
					</NavLink>
				</NavItem>				
			</Nav>
			<Card>
				<TabContent activeTab={"tabs" + activeTab}>
					<TabPane tabId="tabs1">
						<Card className='p-2'>
							<CardBody>
								<Row>
									<Col sm="12" md="12" lg="12">
										<Row>
											<Col sm="2" style={{ textAlign: "end" }}>
												<Label htmlFor="logo" className="avatar ">
													<Media body className="img-100 rounded-circle"
														src={
															(imageLogo !== undefined && imageLogo !== null)
																? "data:image/png;base64," + imageLogo
																: (previewLogo === "")
																	? require('../../assets/images/user/16.png')
																	: previewLogo
														}
														alt="#"
													/>
													<Input type="file" id="logo" name="logo" style={{ display: 'none' }} onChange={imageHandler} />
												</Label>
											</Col>
											<Col sm="5">
												<FormGroup>
													<i className="icofont icofont-id-card f-20  icon-color-Company"></i>&nbsp; <Label className="col-form-label">Alias <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
													<Input
														type="text"
														className="form-control form-control-sm input-air-primary"
														name="name"
														value={name}
														autoComplete="nope"
														onChange={handleInputChange}
														invalid={name_valid} />
													<FormFeedback>Ingresa un nombre valido</FormFeedback>
												</FormGroup>
											</Col>
											<Col sm="5">
												<FormGroup>
													<i className="icofont icofont-barcode f-20  icon-color-Company"></i>&nbsp;<Label className="col-form-label f-14" >Código <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
													<Input
														className="form-control form-control-sm input-air-primary"
														type="text"
														name="code"
														value={code}
														autoComplete="off"
														onChange={handleInputChange}
														invalid={code_valid}
													/>
													<FormFeedback>Ingresa un nombre valido</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
											<Row>
												<Col sm="4">
													<FormGroup>
														<i className="fa fa-calendar f-20  icon-color-Company"></i>&nbsp; <Label>Cliente desde <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input type='date' placeholder='YYYY-MM-DD' name='customer_since' value={customer_since} className='form-control form-control-sm input-air-primary' onChange={handleInputChange} />
														<FormText color="danger" hidden={!customer_since_valid}>Selecciona una fecha</FormText>
													</FormGroup>
												</Col>
												<Col sm="4">
													<FormGroup>
														<i className="fa fa-bookmark-o f-20  icon-color-Company"></i>&nbsp; <Label >Giro comercial <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															className="form-control form-control-sm input-air-primary"
															type="select"
															name="id_company_turn"
															onChange={handleInputChange}
															defaultValue={id_company_turn}>
															<option value={0}>Selecciona una opción</option>
															{
																companyTurn.length > 0 && companyTurn.map((c, key) => {
																	return <option key={key} value={c.id_company_turn} selected={(id_company_turn === c.id_company_turn) ? 'selected' : ''}>{c.name}</option>
																})
															}
														</Input>
														<FormText color="danger" hidden={!id_company_turn_valid}>Selecciona una opción</FormText>
													</FormGroup>
												</Col>
												<Col sm="4">
													{
														is_block === 0 ?
															<i className="icofont icofont-lock  text-danger f-20"></i>
															: <i className="fa fa-unlock-alt text-success f-20"></i>
													} &nbsp;
													<Label>Bloquear cliente</Label><span className='f-16' style={{ color: "#0079C7" }}>*</span> <br />
													<FormGroup className="m-0">
														<Input
															type='select'
															className="form-control form-control-sm input-air-primary"
															name="is_block"
															value={is_block}
															onChange={handleInputChange}
														>
															<option value={0}>{"Si"}</option>
															<option value={1}>{"No"}</option>
														</Input>
													</FormGroup>
												</Col>
											</Row>
											<Row>
											<Col sm="4">
												<i className="fa fa-plus-square-o f-20  icon-color-Company"></i>&nbsp;
												<Label> Categorías</Label>
												<span className='f-16' style={{ color: "#0079C7" }}>*</span> <br />
												<FormGroup className="m-0">
													<Input
														type='select'
														className="form-control form-control-sm input-air-primary"
														name="category"
														value={category}
														onChange={handleInputChange}
													>
														<option value={1}>{"A - Sin limite "}</option>
														<option value={2}>{"B - Limite fijo con tolerancia"}</option>
														<option value={3}>{"C - Monto fijo"}</option>
													</Input>
												</FormGroup>
											</Col>
											<Col sm="4" style={{ textAlign: "-webkit-center" }}>
												<i className="fa fa-money f-20  icon-color-Company"></i>&nbsp;
												<Label> Crédito</Label>
												<span className='f-16' style={{ color: "#0079C7" }}>*</span> <br />
												<FormGroup className="m-0">
													<Input
														type='select'
														className="form-control form-control-sm input-air-primary"
														name="credit_days"
														value={credit_days}
														onChange={handleInputChange}
													>
														<option value={0}>{"0 días"}</option>
														<option value={7}>{"7 días"}</option>
														<option value={15}>{"15 días"}</option>
														<option value={30}>{"30 días"}</option>
														<option value={60}>{"60 días"}</option>
														<option value={90}>{"90 días"}</option>
													</Input>
												</FormGroup>
											</Col>	
											<Col sm="4">
												{
													microsite && <FormGroup className={microsite ? 'ml-3' : 'd-none'}>
														<Label className="col-form-label">Ingresa tu contraseña <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<InputGroup>
															<Input
																type={showPassword ? 'text' : 'password'}
																className="form-control form-control-sm input-air-primary"
																name="password_microsite"
																value={password_microsite}
																onChange={handleInputChange}
																placeholder="Contraseña"
															/>
															<InputGroupAddon addonType="append">
																<InputGroupText>
																	<span
																		className={`fa fa-eye${showPassword ? '-slash' : ''}`}
																		onClick={toggleShowPassword}
																		style={{ cursor: 'pointer' }}
																	/>
																</InputGroupText>
															</InputGroupAddon>
														</InputGroup>
													</FormGroup>
												}
											</Col>	
											<Col sm="4">
												{
													is_requirements && <FormGroup>
														<Label className="col-form-label">Escribe los requerimientos <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															type='text'
															placeholder="Escribe los requerimientos  aquí..."
															name="requirements"
															className='form-control form-control-sm input-air-primary'
															onChange={handleInputChange}
															value={requirements}
														/>
													</FormGroup>
												}
											</Col>									
										</Row>
										<Row className='mt-4'>
											<Col sm="3" style={{ textAlign: "-webkit-center" }} >
												<div>
													<i className="fa fa-sort-numeric-asc f-18  icon-color-Company"></i>
													<Label className='ml-1'>  Almacenar número consecutivo</Label>
													<span className='f-16' style={{ color: "#0079C7" }}>  *</span>
												</div>
												<Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
													<Input type="checkbox" name="consecutive_number" onChange={handleInputChange} checked={consecutive_number} />
													<span className="slider round"></span>
												</Label>
											</Col>
											<Col sm="3" style={{ textAlign: "-webkit-center" }}>
												<div>
													<i className="icofont icofont-envelope f-18  icon-color-Company"></i>
													<Label className="ml-1">Enviar resultados al paciente</Label>
												</div>
												<Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
													<Input type="checkbox" name="send_results_pacient" onChange={handleInputChange} checked={send_results_pacient} />
													<span className="slider round"></span>
												</Label>
											</Col>
											<Col sm="3" style={{ textAlign: "-webkit-center" }} >
												<div>
													<i className="fa fa-list f-18  icon-color-Company"></i>
													<Label className="ml-1">Requerimientos Especiales</Label>
													<span className='f-16' style={{ color: "#0079C7" }}>*</span>
												</div>
												<Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
													<Input type="checkbox" name="is_requirements" onChange={on_check_req_especial} checked={is_requirements} />
													<span className="slider round"></span>
												</Label>
											</Col>	
											<Col sm="3" style={{ textAlign: "-webkit-center" }}>
												<div>
													<i className="icofont icofont-ui-browser f-20 icon-color-Company" style={{ marginLeft: '10px' }}></i>
													<Label className='ml-1'>Micrositio</Label>
													<span className='f-16' style={{ color: "#0079C7" }}>*</span>
												</div>
												<Label className="switch">
													<Input type="checkbox" name="microsite" onChange={handleInputChange} checked={microsite} />
													<span className="slider round"></span>
												</Label>
											</Col>	
											<Col sm="3">
												{
													send_results_pacient && <FormGroup className="m-0">
														<Label className="col-form-label">Ingresa el correo para envío de resultados <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
														<Input
															className="form-control form-control-sm input-primary"
															type="email"
															name="results_email"
															autoComplete="nope"
															placeholder='Correo para envio de resultados'
															onChange={handleInputChange}
															value={results_email}
															invalid={results_email_valid}
														/>
														<FormFeedback>Ingresa un nombre valido</FormFeedback>
													</FormGroup>
												}
											</Col>									
										</Row>
										<Row className="mt-1"> 
										<Col sm="3" className='icon-rfc ' style={{ height: "3px" }}>
												</Col> <br />
											
											
										</Row>
										<Row className='mt-4'>  <br />
											<Col sm="12" md="6" lg="6">
												<span className='link text-sm text-primary badge badge-light-primary f-12'>Configuración de cobranza</span>
											</Col>
											<Col sm="12" md="6" lg="6">
												<span className='link text-sm text-primary badge badge-light-primary f-12'>Configuración de facturación</span>
											</Col>
										</Row>
										<Row>
											<Col sm="4">
												<FormGroup>
													<Label className='col-form-label'>Generar cobro a <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
													<Input type='select' value={ payment_by_company && payment_by_patient ? "0" : (payment_by_company ? "2" : "1" )  } autoComplete='off' 
														className='form-control form-control-sm input-air-primary' onChange={(e) => handleChangePaymentBy(e)}
													>
														<option value="2">A la empresa</option>
														<option value="1">A el paciente</option>
														<option value="0">Ambas partes</option>
													</Input>
													<FormFeedback>Nombre inválido</FormFeedback>
												</FormGroup>																													
											</Col>
											<Col sm="4">
												<FormGroup >
													<Label className='col-form-label'>Requiere factura la empresa <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
													<Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
														<Input type="checkbox" name="require_invoice" onChange={handleInputChange} checked={require_invoice} />
														<span className="slider round"></span>
													</Label>
												</FormGroup>
											</Col>
											
											<Col sm="4">
												<FormGroup>
													<Label className='col-form-label'>El paciente puede solicitar la factura <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
													<Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
														<Input type="checkbox" name="available_invoice_patient" onChange={handleInputChange} checked={available_invoice_patient} disabled={require_invoice} />
														<span className="slider round"></span>
													</Label>
													<FormText color='info' >El paciente puede solicitar la factura a su nombre</FormText>
												</FormGroup>
											</Col>
											<Col sm="4">
												<FormGroup>
													<Label className='col-form-label'>Visualizar Factura <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
													<Label className="switch" style={{ display: 'flex', alignItems: 'center' }}>
														<Input type="checkbox" name="stamp_invoice" onChange={handleInputChange} checked={stamp_invoice}  />
														<span className="slider round"></span>
													</Label>
												</FormGroup>
											</Col>
										</Row>									
									</Col>
								</Row>
							</CardBody>
						</Card>
					</TabPane>
					<TabPane tabId="tabs2">
						<Card>
							<Row className='mb-1'>
								<Col sm="12" className='pl-5 pt-2'>
									<p><b>* Dirección del domicilio registrado, según  tu constancia de situación fiscal</b></p>
								</Col>
							</Row>
							<div>
								{
									listCompanyBusinessNames.length >= 1 && listCompanyBusinessNames.map((obj, index) => {
										return (
											<Row style={{ placeContent: "center" }} key={index + 1}>
												<Col sm="11" className='icon-rfc ' style={{ height: "3px" }}>
												</Col> <br />
												<Col sm="11">
													<Row>
														<Col sm="2" style={{ textAlign: "-webkit-center" }}> <br />
															<h5><Badge color="primary"> <i className="fa fa-building-o f-26  "></i></Badge></h5>
														</Col>
														<Col md="5" >
															<FormGroup>
																<i className="icofont icofont-id-card f-20 icon-color-Company"></i>&nbsp; <Label className="col-form-label">Nombre / Razón social <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="text"
																	name="business_name"
																	autoComplete="nope"
																	onChange={(e) => handleDinamicInputRFC(e, "business_name", "listCompanyBusinessNames")}
																	value={obj.business_name}
																	data-id={index}
																/>
																<FormFeedback>Ingresa una direccion valida</FormFeedback>
															</FormGroup>
														</Col>
														<Col md="4">
															<FormGroup>
																<i className="fa fa-building-o f-20  icon-color-Company"></i>&nbsp;  <Label className="col-form-label">RFC <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	type="text"
																	className="form-control form-control-sm input-air-primary"
																	name="rfc"
																	value={obj.rfc}
																	autoComplete="off"
																	onChange={(e) => handleDinamicInputRFC(e, "rfc", "listCompanyBusinessNames")}
																	data-id={index}
																/>
																<FormFeedback>RFC invalido</FormFeedback>
															</FormGroup>
														</Col>
														<Col sm="1" style={{ textAlign: "-webkit-center" }}>
															<FormGroup>
																<i className={obj.is_default ? 'icofont icofont-ui-rating  text-warning f-20' : 'icofont icofont-ui-rating icon-color-Company f-20'}></i>
																&nbsp;
																<Label>Default</Label> <br />
																<Input
																	type='radio'
																	name="is_default"
																	checked={obj.is_default}
																	onChange={(e) => handleDinamicInputRFC(e, "is_default", "listCompanyBusinessNames")}
																	data-id={index}
																/>
															</FormGroup>
														</Col>
													</Row>
													<br />
													<Row >
														<Col md="3">
															<FormGroup>
																<i className="icofont icofont-id f-20  icon-color-Company"></i>&nbsp;<Label>Estado <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="select"
																	name="id_state"
																	onChange={(e) => handleChangeState(e, "id_state", "listCompanyBusinessNames")}
																	defaultValue={obj.id_state}
																	data-id={index}>
																	<option value={0}>Selecciona una opción</option>
																	{
																		states.length >= 1 && states.map((s, key) => {
																			return <option value={s.id_state} key={key} selected={(obj.id_state !== null) ? (obj.id_state === s.id_state ? 'selected' : '') : ''}>{s.name}</option>
																		})
																	}
																</Input>
																{/* <FormText color="danger" hidden={!id_state_valid}>Selecciona una opción</FormText> */}
															</FormGroup>
														</Col>
														<Col md="3">
															<FormGroup>
																<i className="icofont icofont-id f-20  icon-color-Company"></i>&nbsp;<Label >Municipio <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="select"
																	name="id_municipality"
																	onChange={(e) => handleDinamicInputRFC(e, "id_municipality", "listCompanyBusinessNames")}
																	defaultValue={obj.id_municipality}
																	data-id={index}
																>
																	<option value={0}>Selecciona una opción</option>
																	{
																		(obj.listMunicipalities !== undefined && obj.listMunicipalities.length > 0) && obj.listMunicipalities.map((m, key) => {
																			return <option value={m.id_municipality} key={key} selected={(obj.id_municipality !== null) ? (obj.id_municipality === m.id_municipality ? 'selected' : '') : ''}>{m.name}</option>
																		})
																	}
																</Input>
																{/* <FormText color="danger" hidden={!id_state_valid}>Selecciona una opción</FormText> */}
															</FormGroup>
														</Col>
														<Col md="3">
															<FormGroup>
																<i className="fa fa-university f-20 icon-color-Company"></i> &nbsp; <Label>Tipo de persona <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input type='select' name="type_person" onChange={(e) => handleDinamicInputRFC(e, 'type_person', 'listCompanyBusinessNames')} defaultValue={obj.type_person} data-id={index}>
																	<option value={0}>Selecciona una opción</option>
																	<option value={1}>Física</option>
																	<option value={2}>Moral</option>
																</Input>
															</FormGroup>
														</Col>
														<Col md="3">
															<FormGroup>
																<i className="fa fa-university f-20 icon-color-Company"></i> &nbsp; <Label>Régimen fiscal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input className='form-control form-control-sm input-air-primary'
																	type='select'
																	name='id_tax_regime'
																	onChange={(e) => handleDinamicInputRFC(e, 'id_tax_regime', 'listCompanyBusinessNames')}
																	defaultValue={obj.id_tax_regime}
																	data-id={index}
																>
																	<option value={0}>Selecciona una opción</option>
																	{
																		listTaxRegime.length > 0 && listTaxRegime.map((x, k) => {
																			return <option value={x.value} key={k} selected={obj.id_tax_regime === x.value ? true : false} >{x.label}</option>
																		})
																	}
																</Input>
															</FormGroup>
														</Col>
													</Row>
												</Col>
												<Col sm="11">
													<Row className='mt-2'>
														<Col md="3">
															<FormGroup>
																<i className="icofont icofont-id f-20  icon-color-Company"></i>&nbsp;   <Label>Código postal <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="number"
																	name="cp"
																	autoComplete="nope"
																	onChange={(e) => handleDinamicInputRFC(e, "cp", "listCompanyBusinessNames")}
																	value={obj.cp}
																	// invalid={cp_valid}
																	data-id={index}
																/>
																<FormFeedback>Ingresa un código postal valido</FormFeedback>
															</FormGroup>
														</Col>
														<Col md="3">
															<FormGroup>
																<i className="icofont icofont-id f-20  icon-color-Company"></i>&nbsp;<Label>Dirección <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="text"
																	name="address"
																	autoComplete="nope"
																	onChange={(e) => handleDinamicInputRFC(e, "address", "listCompanyBusinessNames")}
																	value={obj.address}
																	//invalid={address_valid}
																	data-id={index}

																/>
																<FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
															</FormGroup>
														</Col>
														<Col md="3">
															<FormGroup>
																<i className="icofont icofont-id f-20  icon-color-Company"></i>&nbsp;<Label>Colonia <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="text"
																	name="colony"
																	autoComplete="nope"
																	onChange={(e) => handleDinamicInputRFC(e, "colony", "listCompanyBusinessNames")}
																	value={obj.colony}
																	//invalid={colony_valid}
																	data-id={index}
																/>
																<FormFeedback>El campo debe tener 3 o más caracteres</FormFeedback>
															</FormGroup>
														</Col>

														<Col md="3">
															<FormGroup>
																<i className="icofont icofont-ui-call f-20  icon-color-Company"></i>&nbsp;  <Label>Teléfono <span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="text"
																	name="phone"
																	autoComplete="nope"
																	onChange={(e) => handleDinamicInputRFC(e, "phone", "listCompanyBusinessNames")}
																	value={obj.phone}
																	// invalid={phone_valid} 
																	data-id={index}
																/>
																<FormFeedback>Número telefonico a 10 digitos</FormFeedback>
															</FormGroup>
														</Col>
														<Col md="12">
															<FormGroup>
																<i className="icofont icofont-ui-email f-20  icon-color-Company"></i>&nbsp;<Label>Correo electrónico&nbsp; <label  className="txt-secondary"> (En caso de requerir más de un correo separar con el símbolo&nbsp;"," ) </label><span className='f-16' style={{ color: "#0079C7" }}>*</span></Label>
																<Input
																	className="form-control form-control-sm input-air-primary"
																	type="text"
																	name="email"
																	autoComplete="nope"
																	onChange={(e) => handleDinamicInputRFC(e, "email", "listCompanyBusinessNames")}
																	value={obj.email}
																	data-id={index}
																/>
																<FormFeedback>Ingresa una direccion valida</FormFeedback>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														<Col sm="4">
														</Col>
														<Col sm="8" style={{ textAlign: "-webkit-right" }}>
															{
																button === "Guardar" && listCompanyBusinessNames.length > 1 ?
																	<ButtonGroup>
																		<Button
																			className=" btn-air-danger "
																			outline color="danger"
																			size="xs"
																			key={index}
																			onClick={() => removeAlterCompanies(index)}><i className="fa fa-trash-o"></i></Button>
																	</ButtonGroup>
																	:
																	""
															}
														</Col>


													</Row>
													<br />
													<br />

													{/* <hr /> */}
												</Col>
											</Row>
										)
									})
								}
								<Col sm="12" style={{ textAlign: "right" }}>
									<Button type='button' className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={() => handleAddCompany()}><span className="fa fa-plus-circle"></span>&nbsp; Agregar razón social</Button>
								</Col>
							</div>
						</Card>
					</TabPane>
				</TabContent>
			</Card>
			<ModalFooter>
				<Button size="sm" outline color="primary" type="submit" className={loading ? "disabled progress-bar-animated progress-bar-striped" : ""}>
					{button}
				</Button>
				<Button size="sm" className="btn-air-light" outline color="danger" type="button" onClick={toggle}>Cancelar</Button>
			</ModalFooter>
		</Form >
	)
}
