import React, { useState } from 'react';
import { Card, Col, Row, CardHeader, CardBody, Button } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useGlobalReferenceValue } from '../../../../hooks/micro/useGlobalReferenceValue';

export const GlobalReferenceValue = (props) => {
    const { examId } = props;

    const [editorRef, setEditorRef] = useState(null);

    const {
        globalReferenceValue,
        handleChangeGlobalReference,
        saveGlobalReferenceValue,
        deleteGlobalReferenceValue,
        formGlobalReference,
    } = useGlobalReferenceValue(examId);

    return (
        <Row className="pt-1 position-relative">
            <Col sm="12" md="12" lg="12" xl="12">
                <Card>
                    <CardHeader className="p-3 bg-secondary">
                        <h6 className="mb-0">
                            <i className="fa fa-align-justify"></i>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            Valor de referencia global
                        </h6>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <label className="f-w-500">
                                    <span className="text-info">*</span> Si deseas agregar una imagen, solo selecciona tu imagen de tu galería{' '}
                                    <code>(ctrl + c)</code>, posteriormente pega la imagen en el editor <code>(ctrl + v)</code>
                                </label>
                            </Col>
                        </Row>
                        <Row className="m-t-2 pt-3">
                            <Col sm="12" md="12" lg="12" xl="12" className="p-l-0 p-r-0">
                                {globalReferenceValue !== '' && (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={globalReferenceValue}
                                        config={{
                                            toolbar: [
                                                'undo', 'redo', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
                                                'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells'
                                            ],
                                            table: {
                                                contentToolbar: [
                                                    'tableColumn', 'tableRow', 'mergeTableCells'
                                                ]
                                            }
                                        }}
                                        onReady={(editor) => {
                                            setEditorRef(editor);
                                        }}
                                        onChange={(evt, editor) => {
                                            console.log(evt)
                                            console.log(editor)
                                            console.log(editorRef)
                                            handleChangeGlobalReference(editorRef);
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="m-t-15">
                            <Col xl="8"></Col>
                            <Col xl="2" className="p-r-0">
                                {formGlobalReference.globalReferenceValue !== null && (
                                    <Button
                                        outline
                                        color="primary"
                                        type="button"
                                        onClick={() => saveGlobalReferenceValue(examId)}
                                    >
                                        Guardar
                                    </Button>
                                )}
                            </Col>
                            <Col xl="2" style={{ textAlign: 'end' }} className="p-r-0">
                                {formGlobalReference.globalReferenceValue !== null && (
                                    <Button
                                        outline
                                        color="danger"
                                        type="button"
                                        onClick={() => deleteGlobalReferenceValue()}
                                    >
                                        Eliminar
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
