import React from 'react'
import { useState, useEffect } from 'react';
import { Container, Row, Col, Label, Media, Card } from 'reactstrap';
import '../../../../assets/css/pantalla1.css'
import '../../../../assets/css/cartaConsentimiento.css'
import { ComponentAlternativeQyuestion } from './ComponentAlternativeQyuestion';
import '../../../../assets/css/Toma1.css'
import { Rating } from 'react-simple-star-rating'



export const ComponentQuestin = (props) => {

    const {
        items, navigatePrev, saveQuestion, questionOpen, showAlternativeQ, handleEnd } = props

    return (
        <>
            <div>
                {
                    items.length > 0 ?
                        items.map((data, llave) => {

                            let rowsum = 10
                            let divtam = data.listReagents.length
                            let sizeDiv = (rowsum / divtam);

                            return (
                                <div xs="12" sm="12" md="12" lg="12" xl="12"   key={`Question-${llave}`} className={(data.visibility === true) ? "" : (data.visibility && showAlternativeQ ? "" : "d-none")}>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0'  >
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className="text-center">
                                            <label className='label-Encuesta-Toma1'>Encuesta de Satisfacción <br />
                                                <label className='label-Encuesta-Toma1'>del cliente</label> <br />
                                            </label> <br /> <br />
                                        </Col>
                                       
                                        <Col>
                                            <div className='box-pagination-button-encuesta'>
                                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Row>
                                                        <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-2 pintar-icon-encuesta' style={{ textAlign: "-webkit-center" }}>
                                                            {
                                                                llave == 0 ? "" :
                                                                    <img className="pointer " src={require("../../../../assetsToma/imagenes/Regresar.png")} style={{ width: "40%" }} onClick={() => navigatePrev(llave)} />
                                                            }
                                                        </Col>
                                                        <Col xs="6" sm="6" md="6" lg="6" xl="6" className='p-1' style={{ textAlign: "-webkit-center" }}>
                                                            <label className='r-pagination-button'>{llave + 1}/{items.length}</label>
                                                        </Col>
                                                    </Row>
                                                </Col> <br />
                                            </div>
                                        </Col> <br /> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                            <label className='label-Question-Toma1' >{data.question}</label>
                                            <br />
                
                                        </Col> <br /> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-l-0 p-r-0' >
                                            {
                                                data.name_type === "cerrada" ?
                                                    <Row>
                                                        {
                                                            data.listReagents.map((o, i) => {

                                                                let validStars = i == 0 ? 1 : i == 1 ? 2 : i ==2 ? 3 : i == 3 ? 4 : i == 4 ? 5 : 0
                                                              
                                                                return (
                                                                    <Col sm={sizeDiv} key={`list-${i}`} style={{textAlign : "center"}} className='' >
                                                                        {
                                                                            <Row style={{ "place-content": "center" }}>
                                                                                {
                                                                                    o.background_image === null ?
                                                                                        <div>
                                                                                            <button
                                                                                                className={((showAlternativeQ === true) ? "d-none" : o.selected_response === false ? "confirm-Button-1-encuesta-vacio" : "confirm-Button-1-encuesta-lleno")}
                                                                                                value={o.selected_response}
                                                                                                onClick={() => saveQuestion(llave, i, "", "", (o.sub_nivel == false ? 1 : 0))}>{o.reagent}
                                                                                            </button>
                                                                                        </div>
                                                                                        :
                                                                                        <div>
                                                                                            <label htmlFor="logo" className='avatar'></label>
                                                                                            {
                                                                                                o.selected_response === false ?
                                                                                                <div>
                                                                                                     <Media
                                                                                                        body className='img-100 rounded-circle '
                                                                                                        onClick={() => saveQuestion(llave, i, "", "", 1)}

                                                                                                        src={(o.background_image !== undefined) ? "data:image/png;base64," + o.background_image : ""}
                                                                                                        /> <br />
                                                                                                   
                                                                                                      {/* <Rating
                                                                                                        onClick={() => saveQuestion(llave, i, "", "", 1)}
                                                                                                        iconsCount={validStars}
                                                                                                        initialValue={0}
                                                                                                       // iconsCount={1}    
                                                                                                        /> */}
                                                                                                       </div>
                                                                                                    :
                                                                                                    <div>
                                                                                                     <Media
                                                                                                        body className='imgSampleStars rounded-circle '
                                                                                                        src={(o.cover_background_image !== undefined) ? "data:image/png;base64," + o.cover_background_image : ""}
                                                                                                        onClick={() => saveQuestion(llave, i, "", "", 1)}

                                                                                                        /> <br />

                                                                                                         {/* <Rating
                                                                                                            onClick={() => saveQuestion(llave, i, "", "", 1)}
                                                                                                            iconsCount={validStars}
                                                                                                            initialValue={validStars}
                                                                                                        /> */}
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                }
                                                                            </Row>
                                                                        }
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                    :
                                                    <div>
                                                        <textarea
                                                            className='comentariosDinamicos'
                                                            // className='estilotextareaEncuestaCat'
                                                            placeholder='Escriba aquí'
                                                            name=""
                                                            id=""
                                                            onBlur={(e) => questionOpen(llave, e.target.value)}
                                                        >
                                                        </textarea>


                                                        <Col sm="12" style={{ textAlign: "-webkit-center" }}>
                                                            <button onClick={handleEnd} className="confirm-Button-finalizar-encuesta">{"Enviar Encuesta"}</button>

                                                        </Col>





                                                        {/* y solo validas si tu (llave + 1 ) == dinamica.length */}




                                                        {/* esto es para el boton de siguiente si es que aun hay valores */}

                                                        {/* <button onClick={() =>  navigateNext(llave)  } >Siguiente</button> */}

                                                    </div>

                                            }
                                        </Col>
                                        <Col style={{ textAlign: "-webkit-center" }}>
                                            {
                                                data.listReagents.map((o, i) => {
                                                    return (
                                                        <div key={`Reagents-${i}`}>
                                                            {
                                                                (o.alternativeQuestions.length > 0 && o.alternativeQuestions[0].visibility)
                                                                    ?
                                                                    <Col>
                                                                        {
                                                                            o.sub_nivel === true ?
                                                                                <ComponentAlternativeQyuestion
                                                                                    items={o}
                                                                                    {
                                                                                    ...{ llave, i, saveQuestion }
                                                                                    }
                                                                                /> : ""
                                                                        }
                                                                    </Col>
                                                                    : ""
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Col> <br /> <br /> <br /> <br />
                                </div>
                            )
                        })
                        :
                        <div>
                            <Col md="12" className='text-center p-l-0 p-r-0'>
                                <Card className='shadow' style={{ height: "380px" }}>
                                    <Row className='mt-2'> <br />
                                        <Col xs="12" sm="12" md="12" lg="12" xl="12" className='text-center'>  <br /> <br /> <br /> <br />
                                            <img src={require("../../../../assets/images/loginHelenLabs/HelenLabs.png")} className='animate__animated animate__pulse animate__infinite ' style={{ width: '130px' }} />
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col sm="3" md="3" lg="3" className='text-center'></Col>
                                        <Col sm="2" md="2" lg="2" className='text-left p-r-0'>
                                            <p className='f-18 text-primary animate__animated animate__pulse animate__infinite'> <b>Cargando</b></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slow animate__infinite text-primary'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="1" md="1" lg="1" className='text-center'>
                                            <br />
                                            <p className='animate__animated animate__fadeInDown animate__slower animate__infinite text-info'><i className="fa fa-circle f-14"></i></p>
                                        </Col>
                                        <Col sm="2" md="2" lg="2" className='text-center'></Col>
                                    </Row>
                                </Card>
                            </Col>
                        </div>


                    // <div>
                    //     <Col sm="12">
                    //         <Row>
                    //             <Col sm="5">
                    //             <img className="imgSatisfactionQ" src={require("../../../../assets/images/loginHelenLabs/TomaDeMuestra/MessageToma.svg")} alt="message" />   <br />                                         
                    //             </Col>
                    //             <Col sm="7"> <br /> <br /> <br /> <br />
                    //             <label className='label-SatisfactionQuestion'>No se a cargado ninguna encuesta de satisfacción.</label>
                    //             </Col>
                    //         </Row>
                    //     </Col>
                    //      <br />
                    // </div>
                }
            </div>
        </>
    )
}
