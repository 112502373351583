import React from 'react'
import {Button, Modal, ModalBody,Col, Form, Input, CardBody, Media } from 'reactstrap';



export const ModalSendEmail = (props) => {

    const {modalEmail,toggleEmail,email,handleInputChange,saveEmail,validacionesEmail,loading} = props;


    const {
		email_valid
	} = validacionesEmail;
    
    return (
        <Modal isOpen={modalEmail} toggle={toggleEmail} backdrop='static' keyboard={false} centered={true}>
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                 
                         <Media><Media className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/loginHelenLabs/User/email.svg")} alt="" />
                                <Media body>
                                    <h6 className="font-primary f-w-600">{"Envio de accesos a micrositio"}</h6> <br /><span className="d-block"><span><span className=" f-14 pt-4">
                                        { "En caso de requerir más de un correo separar con el símbolo"} <label className='txt-secondary'>{","}</label>
                                    <Input
                                    type="text"
                                    name="email"
                                    value={email}
                                    required={true}
                                    autoComplete="nope"
                                    onChange={handleInputChange}
                                    invalid={email_valid} 
                                    className="form-control form-control-sm input-air-primary"
                                />
                                   </span></span></span>
                                </Media>
                        </Media>
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                            <div className="btngroup">
                                <Button disabled= {loading ? true : false} color="danger text-center" onClick={toggleEmail} type="button" >{"Cancelar"}</Button>
                                <Button disabled= {loading ? true : false} color="primary text-center" onClick={() =>saveEmail()} type="button" >{"Enviar"}</Button> 
                            </div> 
                        </Col>
                     
                    
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
