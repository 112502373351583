import React, { Fragment } from 'react'
import { externalLaboratories } from '../../../constant';
import { Card, Col, Container, Row, Button, CardBody,Input} from 'reactstrap';
import Breadcrumb from '../../../layout/breadcrumb';
import { useLaboratories } from '../../../hooks/laboratoriesExternal/useLaboratories';
import { ModalLaboratories } from '../../../components/laboratories/ModalLaboratories';
import { TableStriped } from '../../../components/tables/TableStriped';


export const IndexLaboratories = () => {


    const {
        modal, toggle, modalTitle, method, loading,
        handleInputChange, validaciones,
        states, municipalities, handleChangeState,
        id_external_laboratory, id_state, id_municipality, name_state, name_municipality, code,
        name, business_name, rfc, phone, address, colony, cp, email, web_site,
        saveCreate, saveUpdate, saveDelete,
        externalLaboratory, handleCreate, handleUpdate, handleDelete,
        navigateUpdate,
        totalPageCount, currentPage, nextPage, previousPage, goToPage,handleSearchExternal,
        downloadExcel

    } = useLaboratories();

    
    return (
        <Fragment>
            {/* <Breadcrumb parent="Catálogos" title="Listado de laboratorios externos" className="m-b-0" /> */}
            <Container fluid={true}> <br />
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody className="p-1">
                                <div className="ribbon ribbon-clip ribbon-primary">
                                    {externalLaboratories}
                                </div>
                                <div className="text-right" style={{ margin: 5, paddingBottom: 10 }}>
                                    <Button className="btn btn-pill btn-air-success" color="secondary" size="sm" onClick={handleCreate}><span className="fa fa-plus-circle"></span></Button>
                                </div>
                                <div className="row">
                                    <div className="offset-8 col-4 pt-1 pb-2">
                                        <Input placeholder='Busqueda...' className='form-control' onChange={handleSearchExternal} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {
                                        externalLaboratory.length === 0 ? 
                                        <div style={{ textAlign: "-webkit-center" }}>
                                        <img className="img-fluid" src={require("../../../assets/images/loginHelenLabs/Alerts/Alert.svg")}  style={{ width: "20%" }} alt="" />   <br />                                         
                                        <br /> <p className="f-18">{"No se ha registrado un Laboratorio Externo aún."}</p>
                                        </div>
                                        :
                                            <TableStriped
                                            notMaped={[ "visibleTable", "name_state", "name_municipality", "id_state", "id_municipality", "phone", "colony", "cp", "web_site","address"]}
                                            methodsActions={true}
                                            cabeceras={["Código", "Nombre del Laboratorio", "Razón Social", "RFC", "Correo electrónico"]}
                                            items={externalLaboratory}
                                            {...{ handleUpdate, handleDelete }}
                                            methodsAbsolutes={
                                                [
                                                    {
                                                        type: "linkId",
                                                        method: navigateUpdate,
                                                        icon: "icofont icofont-laboratory",
                                                        backgroundColor: "#6c6c6c",
                                                        color: "#fff",
                                                        tooltip: "Exámenes"
                                                    },
                                                    {
                                                        type: "linkId",
                                                        method: downloadExcel,
                                                        icon: "fa fa-file-excel-o",
                                                        backgroundColor: "#51bb25",
                                                        color: "#fff",
                                                        tooltip: "Descargar"
                                                    }
                                                ]   
                                            }
                                            nextPage={nextPage}
                                            previousPage={previousPage}
                                            totalPageCount={totalPageCount}
                                            currentPage={currentPage}
                                            goToPage={goToPage}
                                        />
                                    }
                                </div>
                                <br />
                                <br />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalLaboratories
                {
                ...{
                    modal, toggle, modalTitle, method, loading,
                    handleInputChange,
                    id_external_laboratory, id_state, id_municipality, name_state, name_municipality, code,
                    name, business_name, rfc, phone, address, colony, cp, email, web_site,
                    saveCreate, saveUpdate, saveDelete, validaciones,
                    states, municipalities, handleChangeState, handleUpdate, handleDelete
                }
                }
            />
        </Fragment>
    )
}
