import { sendRequest } from "../../hooks/requests/useRequest";
import { ConfigServer } from '../../data/config';


export async function getAllClients (_type)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Company/ListTypeCompaniesMicro/${_type}?is_micro=true`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getInformationPatient (_id_patient,_uuid)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Patient/FindPatientId/${_id_patient}${_uuid}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getAllTypeSample (_type)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `TypeSamples/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getAllExam (_id_exam,_branch,_typeCommercialLine,_mIdPatient,_mIdClient,_alternative_method)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Exam/GetPriceFromExamMicro/${_id_exam},${_branch},${_typeCommercialLine},${_mIdPatient},${_mIdClient},0,true${_alternative_method}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getAllProfile (_id_exam,_branch,_typeCommercialLine,_mIdPatient,_mIdClient,_alternative_method)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Profiles/FindProfileIdMicro/${_id_exam},${_branch},${_typeCommercialLine},${_mIdPatient},${_mIdClient}?validation_strict=true&is_membership=false${_alternative_method}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


export async function getAllRules (_type)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `CatNom/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


export async function saveQuoter({
    id_company, id_patient,id_agreement, date_sample, is_sampling, id_branch, observations,list_type_samples,roleUser
}) {
    const body = {
        id_company,
        id_patient, 
        id_agreement,
        date_sample,
        is_sampling,
        id_branch, 
        observations,
        list_type_samples,
        roleUser
    };


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "QuoterDeterminations"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
            codeQuoter: response?.data?.code,
            id_quoter: response?.data?.id_quoter
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            codeQuoter: response?.data?.code,
            id_quoter: response?.data?.id_quoter,
            code: response?.code ,

        };
}


export async function dowloadPdf({
    id_quoter, print_result, send_email, email_client,tax
}) {
    const body = {
        id_quoter,
        print_result, 
        send_email,
        email_client,
        tax
    };
    console.log("🚀 ~ body:", body)


    

    let raw = JSON.stringify(body);
    let token = localStorage.getItem("token");

    await fetch(ConfigServer.serverUrl + "/api/QuoterDeterminations/CreateDocumentQuoter", {
        method: 'POST',
        body: raw,
        headers: new Headers({
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        })
    })
        .then(response => {

            if (response.status !== 200) {
                return;
            }

            return response.blob();
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Cotizacion.pdf")
            document.body.appendChild(link);
            link.style.display = 'none';
            link.click();
          
        });
}


export async function getInformationQuatation (_code)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `QuoterDeterminations/FindQuoter/${_code}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function updateQuoter({
    id_company, id_patient, date_sample, is_sampling, id_branch, observations,list_type_samples,id_quoter,updatedatesample
}) {
    const body = {
        id_company,
        id_patient, 
        date_sample,
        is_sampling,
        id_branch, 
        observations,
        list_type_samples,
        id_quoter,
        updatedatesample
    };


    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "QuoterDeterminations/Update"
    );

    if (response.code === 200)
        return {
            code: response?.code ,
          
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response?.code ,
        };
}

export async function getListQuatations (_query)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `QuoterDeterminations/FindQuoters?${_query}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getInformationQuoterDeterminations (_code)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `QuoterDeterminations/DetailQuoter/${_code}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}






