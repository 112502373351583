

const formatEmail = (_email) => {
    console.log(_email);
    

    let statusValidation = false;
    let _message = "";

    if (_email !== "") {

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let emails = _email.split(',');

        for (let email of emails) {
            email = email.trim(); 
            if (!emailRegex.test(email)) {
                _message = ("error", "warning", "Advertencia", `El correo '${email}' no cuenta con una estructura válida.`);
                statusValidation = true;
            }
        } 
    }
    console.log(_message);
    
   
    return {
        status: statusValidation,
        message: _message
    } 
}
    
export default formatEmail;