import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendRequest } from '../../hooks/requests/useRequest';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { Col,Row } from 'reactstrap';
import './styleInputPa.css';

export const SearchAsync = ({
    value, name, method, loincState, url, maxLenght, placeholder = "Buscar(Min. 3 caracteres)",
    cacheOptions = true, selectSize = '', mDisabled = false, mMultiple = false, isWorkOrder = false
}) => {


    // ? VALID TYPE FILTER INFO PATIENT
    let _validPatient = url === "Patient/SearchPatient" ? true : false
    
    const [data, setData] = useState([]);
    const [defaultValue, setDefaultValue] = useState([]);
    const history = useHistory();

    let versionLayout = localStorage.getItem('layout_version');
    let _cliente = localStorage.getItem('url_helen');

    

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 999
        })
    }

    useEffect(() => {
        if (loincState.value) {

            if (mMultiple) {
                let defValue = defaultValue;

                let findValue = defValue.find(x => x.value === loincState.value);
                let findIndexValue = defValue.findIndex(x => x.value === loincState.value);
                console.log(findIndexValue);
                

                if (findValue) {
                    findValue.label = loincState.label;

                    defValue[findIndexValue] = findValue;
                }
                else {

                    defValue.push(loincState);
                }
                setDefaultValue([...defValue]);
                setData([...defValue])
            }
            else {
                setDefaultValue([loincState]);
                setData([loincState]);
            }

        } else {
            setDefaultValue([]);
            setData([]);
        }
    }, [loincState]);

    const searchPeople = async (searchQuery = "") => {
        if (searchQuery.trimStart().length < maxLenght) {
            setData([]);
            return [];
        }

        let dataResult = [];

        let requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        //Se usa https://helen-sw.com/api/ porque la libreria pide una URL Absoluta
        let uri = new URL('https://helen-sw.com/api/'+url);

        uri.searchParams.append('query', searchQuery);
        if(isWorkOrder) uri.searchParams.append('isWorkOrder', 'true');

        //console.log(uri.toString());

        var newUrl = uri.toString().replace('https://helen-sw.com/api/','');

        const respuesta = await sendRequest(requestOptions, newUrl);
        if (respuesta.code === 200) {

            respuesta.data.map(function (obj) {
                dataResult.push({ label: obj.component, value: obj.id_component });
            });

            if (!mMultiple) {
                setDefaultValue([]);
            }

            setData(dataResult);
        }
        /*else {
            validarSesion(history, respuesta.code, searchPeople);
        }*/

        return dataResult;
    }

    const promiseOptions = inputValue =>
        new Promise(resolve => {
            resolve(searchPeople(inputValue));
        });

    const _loadSuggestions = (query, callback) => {
        searchPeople(query)
            .then(resp => callback(resp));
    };


    const loadSuggestions = debounce(_loadSuggestions, 1000);


    const handleInputChange = (e, { action }) => {
        let target = {};

        switch (action) {
            case "select-option":

                if (mMultiple) {
                    setDefaultValue([...e]);
                }
                else {
                    setDefaultValue([{ ...e }]);
                }

                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: mMultiple ? e : e.value
                    }
                }
                break;
            case "remove-value":

                setDefaultValue([...e]);

                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: e
                    }
                }
                break;
            case "clear":

                if (mMultiple) {

                    setDefaultValue([...e]);
                }
                else {
                    setDefaultValue([]);
                }

                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: e
                    }
                }
                break;
            case "pop-value":
                target = {
                    target: {
                        name: name,
                        type: "select",
                        value: e
                    }
                }
                break;
            default:
                break;
        }
        method(target);
    }
    const formatOptionLabel = (option) => {
        console.log(option);
        

        const { label } = option;
        const [name, curp,edad,sexo,visit] = label.split(' - ');
       
        return (
          <Col sm="12" className='pt-3'>
            <Row>
              <Col sm="12">
              <Row>
                <Col sm="1"  className={sexo === "Sexo: Masculino " ? 'badge-detail-Patient p-r-0 p-l-0' : 'badge-detail-Patient-M p-r-0 p-l-0' } >
                {
                    sexo === "Sexo: Masculino " ? <i style={{fontSize:"13px"}} className="icofont icofont-boy f-14"></i> : <i style={{fontSize:"13px"}} className="icofont icofont-girl"></i>
                }
                </Col>
                <Col className='' sm="7">
                <span className='f-w-500 pt-1'>{name}</span>
                </Col>
                {_cliente === "https://grupo-acosta.helen-sw.com" && <Col sm="3">
                    <span style={{fontSize:"11px"}} className=''>{visit}</span>
                </Col>}
              </Row>
              </Col>
              <Col sm="4" className='pt-2'>
              <span style={{fontSize:"11px"}} className=''>{curp}</span>

              </Col>
              <Col sm={_cliente === "https://grupo-acosta.helen-sw.com" ? 4 : 5} className='pt-2 p-r-0 p-l-0'>
              <span style={{fontSize:"11px"}} className=''>{edad}</span>

              </Col>
              <Col sm={_cliente === "https://grupo-acosta.helen-sw.com" ? 3 : 3}  className='pt-2 '>
              <span style={{fontSize:"11px"}} className=''>{sexo}</span>
              </Col>



            </Row>
          </Col>
        );
      };


 

    return (
        <AsyncSelect
            isClearable
            onChange={handleInputChange}
            name="loinc_num"
            cacheOptions={cacheOptions}
            loadOptions={loadSuggestions}
            defaultOptions={data}
            value={defaultValue}
            placeholder={placeholder}
            classNamePrefix="select"
            noOptionsMessage={() => "0 resultados"}
            loadingMessage={() => "Cargando..."}
            isDisabled={mDisabled}
            isMulti={mMultiple}
            theme={
                (theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary25: (versionLayout === 'dark-only') ? '#46afe59e' : "#deebff"
                    }
                })
            }
            {...(_validPatient ? { formatOptionLabel: formatOptionLabel } : {})} //?INFO PATIENT

        />
    )

}