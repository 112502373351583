import { useState, useEffect, useMemo, useRef } from 'react';
import { sendRequest } from '../requests/useRequest';
import { validarSesion } from '../tokens/useToken';
import { useHistory, useParams } from 'react-router-dom';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { useForm } from '../forms/useForm';
import { useDebounce } from '../debounce/useDebounce';
import { usePaginationBackend } from '../pagination/usePaginationBackend';

export const useAddAgreement = () => {

    const history = useHistory();

    const [loadingAgreementTest, setLoadingAgreementTest] = useState(false);

    //ROUTE ID

    const { agreementId } = useParams();

    //LOCAL VARIABLES
    let id_commercial_line_l = localStorage.getItem('commercialLineId');
    let id_branch = localStorage.getItem('branchId');

    //#region SHOW DISPLAY MESSAGE
    const [createSweet] = useSweetAlert();
    //#endregion SHOW DISPLAT MESSAGE
    //realizar un efecto asyncrono para cargar los datos     

    useEffect(() => {

        if (agreementId !== undefined && parseInt(agreementId) > 0) {
            setGlobalAction("update");

            getAgreement(agreementId);

            getAgreementTestPagination(agreementId, id_branch, id_commercial_line_l);
        }
        else {
            setGlobalAction("create");
        }

    }, [agreementId]);


    //#region MODALS
    const [loading, setLoading] = useState(false);
    const [loadingAgreement, setLoadingAgreement] = useState(false);

    const [modalTitle, setModalTitle] = useState("");

    const [modalSections, setModalSections] = useState(false);

    const [methodSections, setMethodSections] = useState("");



    const toggleModalSections = () => {
        setModalSections(!modalSections);
    }

    //#endregion

    //#region AGREEMENT

    //#region MODELS
    //LIST
    const [listCompanies, setListCompanies] = useState([]);
    const [listPayForms, setListPayForms] = useState([]);
    const [listFinancingMethods, setListFinancingMethods] = useState([]);
    const [listUseCfdi, setListUseCfdi] = useState([]);
    const [listSellers, setListSellers] = useState([]);
    const [listCommercialLines, setListCommercialLines] = useState([]);
    const [ListBranchesTree, setListBranchesTree] = useState([]);
    const [listDoctors, setListDoctors] = useState([]);

    //MODELS
    const [informationCompany, setInformationCompany] = useState({});
    const [informationDoctor, setInformationDoctor] = useState({});
    const [examProfileState, setExamProfileState] = useState({});
    const [globalPercentage, setGlobalPercentage] = useState(0);
    const [globalAction, setGlobalAction] = useState("");
    const [typeFilterAgreement, setTypeFilterAgreement] = useState(1);

    //PAGINATION MODEL
    const [totalRecords, setTotalRecords] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [prevPage, setPrevPage] = useState(null);
    const [nextPage, setNextPage] = useState(null);
    const [resultCount, setResultCount] = useState(0);
    const [results, setResults] = useState([]);

    //PAGINATION MODEL
    const [pageSizeSelectDoctor, setPageSizeSelectDoctor] = useState(null);
    const [loadingPaggining, setLoadingPaggining] = useState(false);

    //REFS
    const treeViewRef = useRef(null);

    //#region FILTERS
    const [searchQuery, setSearchQuery] = useState("");
    const debounceValue = useDebounce(searchQuery, 500);
    //#endregion END FILTERS

    //SANITARY
    const [check_sanitary, setcheck_sanitary] = useState(false);
    //FORM VALUES
    const [formAgreement, handleInputChangeA, , handleUpdateValuesA, handleSelectValuesA, , handleDinamicInputA] = useForm({
        id_agreement: null,
        id_company: 0,
        id_doctor: 0,
        id_commercial_line: 0,
        id_pay_method: 0,
        id_financing_methods: 0,
        id_seller: 0,
        id_use_cfdi: 0,
        contract_number: "",
        validity: "",
        sales_name: "",
        sales_phone: "",
        sales_email: "",
        payment_name: "",
        payment_phone: "",
        payment_email: "",
        observations: "",
        swift_code: "",
        is_global: false,
        payment_periodicity: 7,
        credit_limit: 0,
        percentage_credit_limit: 0,
        checkSpecimenQuantity: false,
        quantity_sample: 0,
        list_agrement_test: [],
        sample_periodicity: 1,
        is_micro: false,
        is_pay_for_compamy: true,
        required_invoice_company: true,
        charge_emergency: true,
        border_tax:  "16",
        address_sample_taking:""

    });

    const {
        id_agreement,
        id_company,
        id_doctor,
        id_pay_method,
        id_financing_methods,
        id_commercial_line,
        id_seller,
        id_use_cfdi,
        contract_number,
        validity,
        sales_name,
        sales_phone,
        sales_email,
        payment_name,
        payment_phone,
        payment_email,
        observations,
        swift_code,
        is_global,
        payment_periodicity,
        credit_limit,
        percentage_credit_limit,
        checkSpecimenQuantity,
        quantity_sample,
        list_agrement_test,
        sample_periodicity,
        is_micro,
        is_pay_for_compamy,
        required_invoice_company,
        charge_emergency,
        border_tax,
        address_sample_taking
    } = formAgreement;
    //#endregion

    //#region INPUTS VALIDATIONS
    const [validationsAgreement, setValidationsAgreement] = useState(
        {
            id_company_valid: false,
            id_doctor_valid: false,
            id_pay_method_valid: false,
            id_financing_methods_valid: false,
            id_commercial_line_valid: false,
            id_use_cfdi_valid: false,
            id_seller_valid: false,
            contract_number_valid: false,
            validity_valid: false,
            sales_name_valid: false,
            sales_phone_valid: false,
            sales_email_valid: false,
            payment_name_valid: false,
            payment_phone_valid: false,
            payment_email_valid: false,
            payment_periodicity_valid: false,
            credit_limit_valid: false,
            percentage_credit_limit_valid: false,
            list_agrement_test_valid: false,
            quantity_sample_valid: false,
            branch_tree_valid: false
        }
    )

    const [visibilityInputs, setVisibilityInputs] = useState({
        view_contact_number: true,
        view_validity: true,
        view_is_global: true,
        view_payment_periodicity: true,
        view_id_pay_method: true,
        view_id_financing_methods: true,
        view_credit_limit: true,
        view_percentage_credit_limit: true,
        view_id_use_cfdi: true,
        view_checkSpecimenQuantity: true,
        view_quantity_sample: true,
        view_sales_div: true,
        view_payment_div: true,
        view_id_seller: true,
        view_observations: true,
        view_swift_code: true
    });
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        getPayForms();
        getFinancingMethods();
        getCommercialLines();
        getSellers();
        getUseCfdi();
        
        if (globalAction === "create" || agreementId === undefined) {
            getBranches();
        }

    }, []);

    useEffect(() => {

        handleClearValues();

        if (typeFilterAgreement === 1) {
            getCompanies();
        }
        else {
            getDoctors(1, true, true);
        }

    }, [typeFilterAgreement]);

    useEffect(() => {

        if (pageSizeSelectDoctor !== null && loadingPaggining) {
            getDoctors((pageSizeSelectDoctor), false, loadingPaggining);
        }

    }, [pageSizeSelectDoctor, loadingPaggining]);


    //#endregion

    //#region GET METHODS
    const getCompanies = async () => {

        const _list = await _handleRequest("GET", "Company/List", "Clientes");

        if (_list !== null && _list.length > 0) {
            let _list_info_company = [];

            _list.forEach(_company => {
                _list_info_company.push(
                    {
                        label: _company.code + " " + _company.name,
                        value: _company.id_company,
                        rfc: _company.rfc,
                        address: _company.address + " " + _company.cp + " , " + _company.colony,
                        phone: _company.phone,
                        email: _company.results_email,
                        company_turn: _company.name_company_turn,
                        category: _company.category,
                        agreement_global: _company.agreement_global
                    }
                );
            });

            setListCompanies(_list_info_company);
        }
        else {
            setListCompanies([]);
        }
    }

    const getDoctors = async (_next_page, _first_loading = false, _loadin_pagining = false) => {

        if (_loadin_pagining) {
            if (_next_page !== null) {
                const _list = await _handleRequest("GET", `Doctors/List?EnablePagination=true&Page=${_next_page}&Size=10&only_commercial_partner=true`, 'Doctores');

                if (_list !== null && _list.results.length > 0) {
                    let _listDoctor = listDoctors;

                    _list.results.forEach(_doctor => {
                        _listDoctor.push({
                            label: _doctor.name,
                            value: _doctor.id_doctor,
                            name_specialty: _doctor.name_specialty,
                            professional_license: _doctor.professional_license,
                            phone: _doctor.phone,
                            address: _doctor.address,
                            email: _doctor.email,
                            isCommercialPartner: _doctor.isCommercialPartner
                        });
                    });

                    setLoadingPaggining(false);
                    setPageSizeSelectDoctor(_list.next_page);

                    setListDoctors([..._listDoctor]);
                }
            }
        }
    }

    const getPayForms = async () => {

        const _list = await _handleRequest("GET", "PayMethod/List", "Formas de pago");

        if (_list !== null && _list.length > 0) {
            let _list_pay_form = [];

            _list.forEach(_payform => {
                _list_pay_form.push(
                    {
                        id_pay_method: _payform.id_pay_method,
                        name: _payform.name,
                        abbreviation: _payform.abbreviation
                    }
                );
            });

            setListPayForms(_list_pay_form);
        }
        else {
            setListPayForms([]);
        }
    }

    const getFinancingMethods = async () => {
        const _list = await _handleRequest("GET", "FinancingMethods/GetFinancing", "Metodos financieros");

        if (_list !== null && _list.length > 0) {
            let _list_financing_method = [];

            _list.forEach(_fmethod => {
                _list_financing_method.push(
                    {
                        id_financing_methods: _fmethod.id_financing_methods,
                        name: _fmethod.name
                    }
                );
            });

            setListFinancingMethods(_list_financing_method);
        }
        else {
            setListFinancingMethods([]);
        }
    }

    const getCommercialLines = async () => {

        const _list = await _handleRequest("GET", "CommercialLine/List", "Razones Sociales");

        if (_list !== null && _list.length > 0) {
            let _list_commercial = [];

            _list.forEach(_line => {

                _list_commercial.push(
                    {
                        value: _line.id_commercial_line,
                        label: _line.long_name
                    }
                );
            });

            setListCommercialLines(_list_commercial);
        }
        else {
            setListCommercialLines([]);
        }
    }

    const getSellers = async () => {

        const _list = await _handleRequest("GET", "Seller/List", "Vendedores");

        if (_list !== null && _list.length > 0) {
            let _list_seller = [];

            _list.forEach(_seller => {
                _list_seller.push(
                    {
                        value: _seller.id_seller,
                        label: _seller.name
                    }
                );
            });

            setListSellers(_list_seller);
        }
        else {
            setListSellers([]);
        }
    }

    const getBranches = async () => {

        const _list = await _handleRequest("GET", "CommercialLine/ListCommercialBranch", "Sucursales");

        if (_list !== null && _list.length > 0) {

            let _list_branches = [];

            _list.forEach(_branch => {
                _list_branches.push(_branch);
            });

            setListBranchesTree(_list_branches);
        }
        else {
            setListBranchesTree([]);
        }
    }

    const getSections = async () => {

        const _list = await _handleRequest("GET", "Section/List/false", "Secciones");

        if (_list !== null && _list.length > 0) {

            let _list_sections = [];

            _list.forEach(_section => {
                _list_sections.push(
                    {
                        id_section: _section.id_section,
                        name: _section.name,
                        abbreviation: _section.abbreviation,
                        state: false
                    }
                );
            });

            //setListSections(_list_sections);
        }
        // else {
        //     setListSections([]);
        // }
    }

    const getUseCfdi = async () => {

        const _list = await _handleRequest("GET", "UseCfdi/List", "Uso de CFDI");

        if (_list !== null && _list.length > 0) {

            let _list_use_cfdi = [];

            _list.forEach(_cfdi => {
                _list_use_cfdi.push(
                    {
                        id_use_cfdi: _cfdi.id_use_cfdi,
                        code: _cfdi.code,
                        description: _cfdi.description
                    }
                );
            });

            setListUseCfdi(_list_use_cfdi);
        }
        else {
            setListUseCfdi([]);
        }
    }
    //*valid prices and csv
    const [priceTarifarion, setPriceTarifarion] = useState([]);
    const [profileT, setProfileT] = useState([]);


    const getExam = async (_id_exam, _csv, datatable2,_code) => {
     //console.log(id_commercial_line.value);

        let _csvC = _csv;
        let _csvP = datatable2;
        let _arrayTarifario = []

        const _list = await _handleRequest("GET", `Agreement/GetInformationExam/${_id_exam},${id_branch},${id_commercial_line.value}${id_company.value !== undefined ? '?id_company=' + id_company.value : '?id_doctor=' + id_doctor.value}`, "Exámenes");
        //console.log("--");
        if (_list !== null) {

            let _list_test = list_agrement_test;

            if (_list.price >0 ) {
    
                let _percentage = 0;
                let _percentage_price = 0;
                let _subtotal = 0;
    
                if (globalPercentage !== 0 || globalPercentage !== "") {
                    _percentage = (globalPercentage !== 0) ? globalPercentage : 0;
                    _percentage_price = ((_percentage * parseFloat(_list.price)) / 100);
                    _subtotal = parseFloat(_list.price) - _percentage_price;
                }
    
                _list_test.push(
                    {
                        id_test: Date.now() + _list.id_exam,
                        id_agreement_test: null,
                        id_profile: null,
                        id_exam: _list.id_exam,
                        code: _list.code,
                        name: _list.name,
                        price: _list.price,
                        production_cost: _list.production_price + _list.production_profit_price,
                        is_curve: false,
                        listCurves: null,
                        cant_sample: 0,
                        listAgreementTestRanges: [
                            {
                                id_range: Date.now() + _list.id_exam,
                                id_agreement_test_range: null,
                                alertClass: "",
                                discountPesos: _percentage_price,
                                min: 1,
                                max: 1,
                                percentage: _percentage,
                                sale: _csvC === undefined ? _subtotal : _csvP
                            }
                        ]
                    }
                );             
            }
            
            handleUpdateValuesA({
                ...formAgreement,
                list_agrement_test: [..._list_test]
            });

            setExamProfileState({}); 
        }
        else{
            _arrayTarifario.push({
                code:_code
            })
            setPriceTarifarion(_arrayTarifario) 
        }
    }

    const getProfiles = async (_id_profile, _csv, _priceCsv,_code) => {

        let _csvC = _csv
        let _priceCsvP = _priceCsv
        let _arrayTarifario = []


        const _list = await _handleRequest("GET", `Agreement/GetInformationProfile/${_id_profile},${id_branch},${id_commercial_line.value}${id_company.value !== undefined ? '?id_company=' + id_company.value : '?id_doctor=' + id_doctor.value}`, "Perfiles");

        if (_list !== null) {

            let _percentage = 0;
            let _percentage_price = 0;
            let _subtotal = 0;


            let _list_test = list_agrement_test;

            if (!_list.is_curve) {

                if (globalPercentage !== 0 || globalPercentage !== "") {
                    _percentage = (globalPercentage !== 0) ? globalPercentage : 0;
                    _percentage_price = ((_percentage * parseFloat(_list.price)) / 100);
                    _subtotal = parseFloat(_list.price) - _percentage_price;
                }
            }

            let _list_curves = null;

            if (_list.is_curve) {
                _list_curves = _list.listCurves;

                _list_curves.map(_curve => {
                    _curve.listAgreementTestRanges = [
                        {
                            id_range: Date.now() + _curve.id_profile_curve_price,
                            id_agreement_test_range: null,
                            alertClass: "",
                            discountPesos: _percentage_price,
                            min: 1,
                            max: 1,
                            percentage: _percentage,
                            sale: _subtotal
                        }
                    ];
                });
            }

            _list_test.push(
                {
                    id_test: Date.now() + _list.id_profile,
                    id_agreement_test: null,
                    id_exam: null,
                    id_profile: _list.id_profile,
                    code: _list.code,
                    name: _list.name,
                    price: _list.price,
                    production_cost: _list.production_price + _list.production_profit_price,
                    is_curve: _list.is_curve,
                    listCurves: _list_curves,
                    listAgreementTestRanges: [
                        {
                            id_range: Date.now() + _list.id_profile,
                            id_agreement_test_range: null,
                            alertClass: "",
                            discountPesos: _percentage_price,
                            min: 1,
                            max: 1,
                            percentage: _percentage,
                            sale: _csvC === undefined ? _subtotal : _priceCsvP

                        }
                    ],

                }
            );

            handleUpdateValuesA(
                {
                    ...formAgreement,
                    list_agrement_test: [..._list_test]
                }
            );

            setExamProfileState({});
        }
        else{
            _arrayTarifario.push({
                code:_code
            })
            setProfileT(_arrayTarifario)

        }
    }

    //---------------------------------UPDATE AGREMENT---------------------------

    const getAgreement = async (_id_agreement) => {

        setLoadingAgreement(true);

        const _obj_agreement = await _handleRequest("GET", `Agreement/GetAgreementId/${_id_agreement}`, "Convenios");
        ////console.log(_obj_agreement);
        console.log("🚀 ~ getAgreement ~ _obj_agreement:", _obj_agreement)

        if (_obj_agreement !== null) {

            let _type_filter = 1;

            if (_obj_agreement.id_company !== null) {
                setTypeFilterAgreement(1);
                _type_filter = 1;
            }
            else if (_obj_agreement.id_doctor !== null) {
                setTypeFilterAgreement(2);
                _type_filter = 2;
            }

            let _list_branches = 0;
            if (_obj_agreement.commercialBranchTreeView.length > 0) {
                _list_branches = _obj_agreement.commercialBranchTreeView.map(_branch => {

                    ////console.log(_branch);
                    _branch.expanded = true;

                    return _branch
                });
            }

            setListBranchesTree([..._list_branches]);
            
            handleUpdateValuesA({
                ...formAgreement,
                id_agreement: _obj_agreement.id_agreement,
                id_company: _obj_agreement.id_company === null ? 0 : {
                    label: _obj_agreement.code_company + " " + _obj_agreement.name_company,
                    value: _obj_agreement.id_company
                },
                id_doctor: _obj_agreement.id_doctor === null ? 0 : {
                    label: _obj_agreement.name_doctor,
                    value: _obj_agreement.id_doctor
                },
                id_commercial_line: {
                    value: _obj_agreement.id_commercial_line,
                    label: _obj_agreement.name_commercial_line
                },
                id_pay_method: _obj_agreement.id_pay_method,
                id_financing_methods: _obj_agreement.id_financing_methods === null ? 0 : _obj_agreement.id_financing_methods,
                id_seller: _obj_agreement.id_seller === null ? 0 : {
                    label: _obj_agreement.seller_name,
                    value: _obj_agreement.id_seller
                },
                id_use_cfdi: _obj_agreement.id_use_cfdi === null ? 0 : _obj_agreement.id_use_cfdi,
                contract_number: _obj_agreement.contract_number,
                validity: _obj_agreement.validity,
                sales_name: _obj_agreement.sales_name === null ? "" : _obj_agreement.sales_name,
                sales_phone: _obj_agreement.sales_phone === null ? "" : _obj_agreement.sales_phone,
                sales_email: _obj_agreement.sales_email === null ? "" : _obj_agreement.sales_email,
                payment_name: _obj_agreement.payment_name === null ? "" : _obj_agreement.payment_name,
                payment_phone: _obj_agreement.payment_phone === null ? "" : _obj_agreement.payment_phone,
                payment_email: _obj_agreement.payment_email === null ? "" : _obj_agreement.payment_email,
                observations: _obj_agreement.observations,
                swift_code: _obj_agreement.swift_code === null ? "" : _obj_agreement.swift_code,
                is_global: _obj_agreement.is_global === null ? false : _obj_agreement.is_global,
                payment_periodicity: _obj_agreement.payment_periodicity === null ? 7 : _obj_agreement.payment_periodicity,
                credit_limit: _obj_agreement.credit_limit === null ? 0 : _obj_agreement.credit_limit,
                percentage_credit_limit: _obj_agreement.percentage_credit_limit === null ? 0 : _obj_agreement.percentage_credit_limit,
                checkSpecimenQuantity: _obj_agreement.checksamplecant,
                quantity_sample: _obj_agreement.cant_sample,
                is_micro: _obj_agreement.is_micro,
                sample_periodicity: _obj_agreement.sample_periodicity,
                is_pay_for_compamy: _obj_agreement.is_pay_for_company,
                required_invoice_company: _obj_agreement.required_invoice_company,
                charge_emergency: _obj_agreement.charge_emergency,
                border_tax: _obj_agreement.border_tax === false ? "16" :  "8",
                address_sample_taking:  _obj_agreement.address_sample_taking === null ? "" : _obj_agreement.address_sample_taking

            });            

            if (_obj_agreement.id_company !== null) {
                setInformationCompany(
                    {
                        address: _obj_agreement.address,
                        company_turn: _obj_agreement.name_company_turn,
                        email: _obj_agreement.email_company,
                        name: _obj_agreement.name_company,
                        phone: _obj_agreement.phone_company,
                        rfc: _obj_agreement.rfc_company,
                        agreement_global: _obj_agreement.agreement_global
                    }
                );
            }
            else if (_obj_agreement.id_doctor !== null) {
                setInformationDoctor(
                    {
                        address: _obj_agreement.address_doctor === null ? "Domicilio no registrado" : _obj_agreement.address_doctor,
                        specialty: _obj_agreement.name_specialty === null ? "Sin especialidad registrada" : _obj_agreement.name_specialty,
                        email: _obj_agreement.email_doctor === null ? "Sin correo regristrado" : _obj_agreement.email_doctor,
                        phone: _obj_agreement.phone_doctor === null ? "Sin teléfono registrado" : _obj_agreement.phone_doctor,
                        professional_license: _obj_agreement.professional_license === null ? "Sin licencia registrada" : _obj_agreement.professional_license
                    }
                )
            }

            switch (_type_filter) {
                case 1:
                    setVisibilityInputs({
                        view_contact_number: true,
                        view_validity: true,
                        view_is_global: true,
                        view_payment_periodicity: true,
                        view_id_pay_method: true,
                        view_id_financing_methods: true,
                        view_credit_limit: true,
                        view_percentage_credit_limit: true,
                        view_id_use_cfdi: true,
                        view_checkSpecimenQuantity: true,
                        view_quantity_sample: true,
                        view_sales_div: true,
                        view_payment_div: true,
                        view_id_seller: true,
                        view_observations: true,
                        view_swift_code: true
                    })
                    break;

                default:
                    setVisibilityInputs({
                        view_contact_number: true,
                        view_validity: true,
                        view_is_global: true,
                        view_payment_periodicity: false,
                        view_id_pay_method: true,
                        view_id_financing_methods: false,
                        view_credit_limit: false,
                        view_percentage_credit_limit: false,
                        view_id_use_cfdi: false,
                        view_checkSpecimenQuantity: false,
                        view_quantity_sample: false,
                        view_sales_div: false,
                        view_payment_div: false,
                        view_id_seller: false,
                        view_observations: true,
                        view_swift_code: false
                    })
                    break;
            }

            setLoadingAgreement(false);
        }

        setLoadingAgreement(false);
    }
     
    //---------------------------------UPDATE AGREMENT---------------------------

    const getAgreementTestPagination = async (_idAgreement, _idBranch, _idCommercialLine, filters = null) => {
        //console.log('loadingTest',loadingAgreementTest);
        if(loadingAgreementTest == true) return;
        setLoadingAgreementTest(true);
        //console.log('loadingTest',loadingAgreementTest);
        const _obj_test = await _handleRequest("GET", `Agreement/GetAgreementTestId/${_idAgreement}/${_idBranch}/${_idCommercialLine}?EnablePagination=true&Page=${actualPage}&Size=10${searchQuery.length > 0 ? `&search=${searchQuery}` : ""}${filters !== null ? filters : ""}`, "Paginacion")
        
        if (_obj_test !== null) {
            setTotalRecords(_obj_test.total_records);
            setTotalPages(_obj_test.total_pages);
            setPrevPage(_obj_test.prev_page);
            setNextPage(_obj_test.next_page);
            setResultCount(_obj_test.result_count);
            setResults(_obj_test.results);
        }
        //console.log('loadingTest',loadingAgreementTest);
        setLoadingAgreementTest(false);
        //console.log('loadingTest',loadingAgreementTest);   
    }

    const goNextPage = () => {
        let actual_pagina = actualPage;
        actual_pagina++;
        setActualPage(actual_pagina);
    }

    const goPreviousPage = () => {
        let actual_pagina = actualPage;
        actual_pagina--;
        setActualPage(actual_pagina);
    }

    const goToPage = (page) => {
        setActualPage(page);
    }

    const searchAsync = () => {
        if (agreementId !== undefined) {
            getAgreementTestPagination(agreementId, id_branch, id_commercial_line_l);
        }
    }

    useEffect(() => {
       if (agreementId !== undefined) {
            getAgreementTestPagination(agreementId, id_branch, id_commercial_line_l);

       }
    }, [actualPage])

    useEffect(() => {
        if (actualPage > totalPages) {
            goToPage(totalPages);
        }
    }, [totalPages]);

    useEffect(() => {       
        //console.log("Que lleva-------");     

        if (results.length > 0  ) {

            let _list_test_ranges = [];

            if (results.length > 0 ) {

                _list_test_ranges = results.map(_test => {

                    let _price = 0;
                    let _production_cost = (_test.production_price + _test.production_profit_price);

                    if (_test.is_curve) {
                        if (_test.listCurves !== null && _test.listCurves.length > 0) {

                            _test.listCurves.map(_curve => {
                                _price = _curve.price;

                                _curve.listAgreementTestRanges.map(_range => {
                                    let _percentage = _range.percentage;
                                    let _calculate_percentage = ((_price * _percentage) / 100);
                                    let _discount_pesos = 0;
                                    let _sale = 0;
                                    let _alert_class = "";

                                    let _subtotal = (_price - _calculate_percentage).toFixed(2);

                                    if (_production_cost > _subtotal) {
                                        _alert_class = "text-danger";
                                    }

                                    _discount_pesos = _calculate_percentage;
                                    _sale = _subtotal;

                                    _range.alertClass = _alert_class;
                                    _range.discountPesos = _discount_pesos;
                                    _range.sale = _sale;

                                    return _range;
                                })
                            })
                        }
                    }
                    else {
                        _price = parseFloat(_test.price);

                        _test.listAgreementTestRanges.map(_range => {

                           let _price_venta = _range.price
                           let _percentage1 = (1 - (_price_venta / _price)) * 100;

                            let _percentage = 0;
                            let _calculate_percentage = ((_percentage * _price ) / 100);
                            let _discount_pesos = 0;
                            let _sale = 0;
                            let _alert_class = "";

                            let _subtotal = (_price - _calculate_percentage).toFixed(2);

                            // if (_production_cost > _subtotal) {
                            //     _alert_class = "text-danger";
                            // }

                            _discount_pesos = _calculate_percentage;
                           // _sale = _subtotal;
                            _sale = _range.price;

                            _range.alertClass = _alert_class;
                          //  _range.discountPesos = _discount_pesos;
                            _range.discountPesos = _percentage1;
                            _range.percentage = _percentage1;


                            _range.sale = _sale;

                            return _range;
                        });
                    }

                    _test.production_cost = _production_cost;

                    return _test;
                } )
            }

            handleUpdateValuesA({
                ...formAgreement,
                list_agrement_test: _list_test_ranges,
            });    

        }

    }, [results]);

    useEffect(() => {
        searchAsync();
    }, [debounceValue]);
    
    //#endregion


    //#region HANDLE METHODS

    const handleClearValues = () => {
        setListCompanies([]);
        setListDoctors([]);
        setLoadingPaggining(false);
        setPageSizeSelectDoctor(null);
        setInformationCompany({});
        setInformationDoctor({});
    }

    const handleChangeTypeFilter = (_filter) => {
        setTypeFilterAgreement(_filter);

        switch (_filter) {
            case 1:
                setVisibilityInputs({
                    view_contact_number: true,
                    view_validity: true,
                    view_is_global: true,
                    view_payment_periodicity: true,
                    view_id_pay_method: true,
                    view_id_financing_methods: true,
                    view_credit_limit: true,
                    view_percentage_credit_limit: true,
                    view_id_use_cfdi: true,
                    view_checkSpecimenQuantity: true,
                    view_quantity_sample: true,
                    view_sales_div: true,
                    view_payment_div: true,
                    view_id_seller: true,
                    view_observations: true,
                    view_swift_code: true
                })
                break;

            default:
                setVisibilityInputs({
                    view_contact_number: true,
                    view_validity: true,
                    view_is_global: true,
                    view_payment_periodicity: false,
                    view_id_pay_method: true,
                    view_id_financing_methods: false,
                    view_credit_limit: false,
                    view_percentage_credit_limit: false,
                    view_id_use_cfdi: false,
                    view_checkSpecimenQuantity: false,
                    view_quantity_sample: false,
                    view_sales_div: false,
                    view_payment_div: false,
                    view_id_seller: false,
                    view_observations: true,
                    view_swift_code: false
                })
                break;
        }
    }

    const handleScrollPaginingDoctor = () => {
        setLoadingPaggining(true);
    }

    const handleChangeCompany = (e) => {
        handleSelectValuesA(e, 'id_company');

        if (e.value !== undefined) {
            setInformationCompany(
                {
                    address: e.address,
                    company_turn: e.company_turn,
                    email: e.email === null ? "" : e.email,
                    name: e.label,
                    phone: e.phone,
                    rfc: e.rfc,
                    agreement_global: e.agreement_global
                }
            );
        }
    }

    const handleChangeDoctor = (e) => {
        handleSelectValuesA(e, 'id_doctor');

        if (e.value !== undefined) {
            setInformationDoctor({
                address: e.address === null ? "Domicilio no registrado" : e.address,
                specialty: e.name_specialty === null ? "Sin especialidad registrada" : e.name_specialty,
                email: e.email === null ? "Sin correo regristrado" : e.email,
                phone: e.phone === null ? "Sin teléfono registrado" : e.phone,
                professional_license: e.professional_license === null ? "Sin licencia registrada" : e.professional_license
            });
        }
        else {
            setInformationCompany({});
        }
    }

    const handleSelectTest = (e) => {
        setExamProfileState(e);

        let _test = e.target.value;

        if (formAgreement.id_commercial_line.value === undefined) {
            createSweet("warning", "warning", "Debes de seleccionar una razón social");
            setExamProfileState({});
            return;
        }

        if (_test !== 0 && _test !== null) {

            let _test_split = _test.split('-');

            let _id_test = _test_split[0];
            let _type_test = _test_split[1];

            let _valid_exist_test = list_agrement_test.find(x => (_type_test === "0" ? x.id_exam === parseInt(_id_test) : x.id_profile === parseInt(_id_test)));

            if (_valid_exist_test !== undefined) {
                createSweet("warning", "warning", "Ya existe el examen seleccionado");
                setExamProfileState({});
                return;
            }
            else {
                if (_type_test === "0") {
                    getExam(_id_test);
                }
                else {
                    getProfiles(_id_test);
                }
            }
        }
    }

    const handleChangeRange = (e, _id_range, _is_curve, _id_exam = null, _id_profile = null, _id_profile_curve_price = null) => {
        let _list = list_agrement_test;

        let _value = e.target.value;
        let _name_input = e.target.name;

        let _find_test = _list.find(x => (_id_exam !== null ? x.id_exam === _id_exam : x.id_profile === _id_profile));

        if (_find_test !== undefined) {
            if (_id_profile !== null && _is_curve) {

                _find_test.listCurves.map(_curve => {
                    if (_curve.id_profile_curve_price === _id_profile_curve_price) {
                        _curve.listAgreementTestRanges.map(_range => {
                            if (_range.id_range === _id_range) {
                                switch (_name_input) {
                                    case "min":
                                        _range.min = _value;
                                        break;
                                    case "max":
                                        _range.max = _value;
                                        break;
                                    default:
                                        _range.percentage = _value;
                                        break;
                                }
                            }

                            return _range;
                        });
                    }
                });
            }
            else {
                _find_test.listAgreementTestRanges.map(_range => {
                    if (_range.id_range === _id_range) {
                        switch (_name_input) {
                            case "min":
                                _range.min = _value;
                                break;
                            case "max":
                                _range.max = _value;
                                break;
                            default:
                                _range.percentage = _value;
                                break;
                        }
                    }
                    return _range;
                })
            }
        }

        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [..._list]
        });
    }

    const handleChangePercentage = (e, _id_range, _id_exam = null, _id_profile = null, _id_profile_curve_price = null) => {
        let _value = e.target.value;

        let _list = list_agrement_test;

        let _find_test = _list.find(x => (_id_exam !== null ? x.id_exam === _id_exam : x.id_profile === _id_profile));

        if (_find_test !== undefined) {
            let _price = 0;
            let _production_cost = parseFloat(_find_test.production_cost);
            let _subtotal = 0;

            if ((_id_exam !== null) || (_id_profile !== null && !_find_test.is_curve)) {
                _price = parseFloat(_find_test.price);
            }
            else if (_id_profile !== null && _find_test.is_curve) {
                let _find_curve = _find_test.listCurves.find(x => x.id_profile_curve_price === _id_profile_curve_price);

                if (_find_curve !== undefined) {
                    _price = parseFloat(_find_curve.price);
                }
            }

            let _percentage = _value !== '' ? parseFloat(_value) : 0;
            let _calculate_percentage = ((_percentage * _price) / 100).toFixed(2);
            let _discount_pesos = 0;
            let _sale = 0;
            let _alert_class = "";

            _subtotal = (_price - _calculate_percentage).toFixed(2);
            _discount_pesos = _calculate_percentage;
            _sale = _subtotal;

            if ((_id_exam !== null || _id_profile !== null) && !_find_test.is_curve) {
                _find_test.listAgreementTestRanges.map(_range => {
                    if (_range.id_range === _id_range) {
                        _range.percentage = _value;
                        _range.alertClass = _alert_class;
                        _range.discountPesos = _discount_pesos;
                        _range.sale = _sale;
                    }
                    return _range;
                });
            }
            else if (_id_profile !== null && _find_test.is_curve) {
                let _find_curve = _find_test.listCurves.find(x => x.id_profile_curve_price === _id_profile_curve_price);

                if (_find_curve !== undefined) {

                    _find_curve.listAgreementTestRanges.map(_range => {
                        if (_range.id_range === _id_range) {
                            _range.percentage = _value;
                            _range.alertClass = _alert_class;
                            _range.discountPesos = _discount_pesos;
                            _range.sale = _sale;
                        }
                        return _range;
                    });
                }
            }
        }

        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [..._list]
        });
    }

    const handleChangePrice = (e, _id_range, _id_exam = null, _id_profile = null, _id_profile_curve_price = null) => {
        let _value = e.target.value;

        let _list = list_agrement_test;

        let _find_test = _list.find(x => (_id_exam !== null ? x.id_exam === _id_exam : x.id_profile === _id_profile));

        if (_find_test !== undefined) {

            let _price = 0;
            let _production_cost = parseFloat(_find_test.production_cost);
            let _alert_class = "";

            if ((_id_exam !== null || _id_profile !== null) && !_find_test.is_curve) {
                _price = parseFloat(_find_test.price);
            }
            else if (_id_profile !== null && _find_test.is_curve) {
                let _find_curve = _find_test.listCurves.find(x => x.id_profile_curve_price === _id_profile_curve_price);

                if (_find_curve !== undefined) {
                    _price = parseFloat(_find_curve.price);
                }
            }

            let _price_whish = _value !== '' ? parseFloat(_value) : 0;

            let _calculate_percentage = ((_price_whish * 100) / _price).toFixed(2);

            let _percentage = 100 - _calculate_percentage;

            let _discount_pesos = (_price - _price_whish).toFixed(2);

            // if (_production_cost > _value) {
            //     _alert_class = "text-danger";
            // }

            if ((_id_exam !== null || _id_profile !== null) && !_find_test.is_curve) {
                //SAVE PERCENTAGE
                _find_test.listAgreementTestRanges.map(_rangeE => {
                    if (_rangeE.id_range === _id_range) {
                        _rangeE.percentage = _percentage;
                        _rangeE.alertClass = _alert_class;
                        _rangeE.discountPesos = _discount_pesos;
                        _rangeE.sale = _value;
                    }

                    return _rangeE;
                })
            }
            else if (_id_profile !== null && _find_test.is_curve) {
                let _find_curve = _find_test.listCurves.find(x => x.id_profile_curve_price === _id_profile_curve_price);

                if (_find_curve !== undefined) {
                    _find_curve.listAgreementTestRanges.map(_rangeC => {
                        if (_rangeC.id_range === _id_range) {
                            _rangeC.percentage = _percentage;
                            _rangeC.alertClass = _alert_class;
                            _rangeC.discountPesos = _discount_pesos;
                            _rangeC.sale = _value;
                        }

                        return _rangeC;
                    })
                }
            }
        }

        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [..._list]
        });
    }

    const handleDeleteRange = (_id_range, _position, _id_exam = null, _id_profile = null, _id_profile_curve_price = null) => {
        let _list = list_agrement_test;

        if (_position > 0) {
            let _find_test = _list.find(x => (_id_exam !== null ? x.id_exam === _id_exam : x.id_profile === _id_profile));

            if (_find_test !== undefined) {
                if ((_id_exam !== null || _id_profile !== null) && !_find_test.is_curve) {

                    let _filter_test_ranges = _find_test.listAgreementTestRanges.filter(_range => {
                        if (_range.id_range !== _id_range) {
                            return true;
                        }
                    });

                    _find_test.listAgreementTestRanges = _filter_test_ranges;
                }
                else if (_id_profile && _find_test.is_curve) {
                    _find_test.listCurves.map(_curve => {
                        if (_curve.id_profile_curve_price === _id_profile_curve_price) {
                            let _list_filter_ranges = _curve.listAgreementTestRanges.filter(_range => {
                                if (_range.id_range !== _id_range) {
                                    return true;
                                }
                            });

                            _curve.listAgreementTestRanges = _list_filter_ranges;
                        }
                    });
                }

                handleUpdateValuesA({
                    ...formAgreement,
                    list_agrement_test: [..._list]
                });
            }
        }
    }

    const handleAddRange = (_id_exam = null, _id_profile = null, _id_profile_curve_price = null) => {
        let _list = list_agrement_test;

        let _find_test = _list.find(x => (_id_exam !== null ? x.id_exam === _id_exam : x.id_profile === _id_profile));

        if (_find_test !== undefined) {

            if ((_id_exam !== null || _id_profile !== null) && !_find_test.is_curve) {
                let _price = _find_test.price;
                let _total_ranges = _find_test.listAgreementTestRanges.length;
                let _max_range = _find_test.listAgreementTestRanges[_total_ranges - 1].max;

                _find_test.listAgreementTestRanges.push({
                    id_range: Date.now() + (_find_test.id_exam !== null ? _find_test.id_exam : _find_test.id_profile),
                    id_agreement_test_range: null,
                    alertClass: "",
                    discountPesos: 0,
                    min: (parseInt(_max_range) + 1),
                    max: (parseInt(_max_range) + 1),
                    percentage: 0,
                    sale: _price
                });
            }
            else if (_id_profile !== null && _find_test.is_curve) {
                _find_test.listCurves.map(_curve => {
                    if (_curve.id_profile_curve_price === _id_profile_curve_price) {
                        let _price = _curve.price;
                        let _total_ranges = _curve.listAgreementTestRanges.length;
                        let _max_range = _curve.listAgreementTestRanges[_total_ranges - 1].max;

                        _curve.listAgreementTestRanges.push({
                            id_range: Date.now() + _find_test.id_profile,
                            id_agreement_test_range: null,
                            alertClass: "",
                            discountPesos: 0,
                            min: (parseInt(_max_range) + 1),
                            max: (parseInt(_max_range) + 1),
                            percentage: 0,
                            sale: _price
                        })
                    }

                    return _curve;
                });
            }
        }

        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [..._list]
        });
    }

    //*DELETE AGREEMENT TEST
     //#region MODALS
     const [modalCancellation, setModalCancellation] = useState(false);
     const [method, setMethod] = useState("");
     const [informationExams, setInformationExams] = useState([])

     const toggleModalCancellation = () => {
         setModalCancellation(!modalCancellation);
     }
     //#endregion

    const handleTest = (_id_agreement_test) => {

        let _findTest = list_agrement_test.find(x => x.id_agreement_test === _id_agreement_test);

        if (_findTest !== undefined) {                    
            setLoading(false);
            setModalTitle("Eliminar Estudio");
            toggleModalCancellation();
            setMethod("cancellation");
            
            setInformationExams({
                name: _findTest.name,
                price: _findTest.price,
                id_agreement_test: _findTest.id_agreement_test
            });
        }
    }

    //*UPDATE TEST
    const handleDeleteTest  = async (_id_agreement_test) => {

        let _response = await _handleRequest("DELETE", `Agreement/DeleteTest/${_id_agreement_test}`, "Convenios");

        if (_response !== null) {

            let _array = list_agrement_test;
            let _find = _array.filter(_find => _find.id_agreement_test !== _id_agreement_test )

            if (_find) {
                handleUpdateValuesA({
                    ...formAgreement,
                    list_agrement_test:[..._find]
                })
            }

            toggleModalCancellation();
            createSweet("success", "success", "Se elimino correctamente");
        }
    }


     //*DELETE CREATE TEST
     const handleDeleteTestCreate  =  (id_exam = null, id_profile = null) => {
       
        let _arrayExams = list_agrement_test

        if (id_exam !== null) {
            let _index = -1;
        
            for (let i = 0; i < _arrayExams.length; i++) {
                if (_arrayExams[i].id_exam === id_exam) {
                    _index = i;
                    break;
                }
            }
        
            if (_index !== -1) {
                _arrayExams.splice(_index, 1);
            }
        }
        else {

            let _index = -1;
        
            for (let i = 0; i < _arrayExams.length; i++) {
                if (_arrayExams[i].id_profile === id_profile) {
                    _index = i;
                    break;
                }
            }
        
            if (_index !== -1) {
                _arrayExams.splice(_index, 1);
            } 
        }

        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test:[..._arrayExams]
        })
    }
    //*END DELETE AGREEMENT TEST


   

    const handleGoBack = () => {
        history.goBack();
    }

    const handleCalculateGlobalPercentage = (e) => {
        setGlobalPercentage(e.target.value);

        let _value = e.target.value;
        let _list = list_agrement_test;

        let _price = 0;
        let _production_cost = 0;
        let _subtotal = 0;

        _list.map(_test => {
            _price = 0;
            _production_cost = 0;
            _subtotal = 0;

            let _percentage = 0;
            let _calculate_percentage = 0;
            let _discount_pesos = 0;
            let _sale = 0;
            let _alert_class = "";

            if ((_test.id_exam !== null || _test.id_profile !== null) && !_test.is_curve) {

                _price = parseFloat(_test.price);
                _production_cost = parseFloat(_test.production_cost);

                _percentage = _value !== '' ? parseFloat(_value) : 0;
                _calculate_percentage = ((_percentage * _price) / 100).toFixed(2);

                _subtotal = (_price - _calculate_percentage).toFixed(2);

                // if (_production_cost > _subtotal) {
                //     _alert_class = "text-danger";
                // }

                _discount_pesos = _calculate_percentage;
                _sale = _subtotal;

                _test.listAgreementTestRanges.map(_range => {
                    _range.percentage = _value;
                    _range.alertClass = _alert_class;
                    _range.discountPesos = _discount_pesos;
                    _range.sale = _sale;

                    return _range;
                });
            }
            else if (_test.id_profile !== null && _test.is_curve) {
                _test.listCurves.map(_curve => {

                    _price = parseFloat(_curve.price);
                    _production_cost = parseFloat(_test.production_cost);

                    _percentage = _value !== '' ? parseFloat(_value) : 0;
                    _calculate_percentage = ((_percentage * _price) / 100).toFixed(2);
                    _subtotal = (_price - _calculate_percentage).toFixed(2);

                    // if (_production_cost > _subtotal) {
                    //     _alert_class = 'text-danger';
                    // }

                    _discount_pesos = _calculate_percentage;
                    _sale = _subtotal;

                    _curve.listAgreementTestRanges.map(_range => {
                        _range.percentage = _value;
                        _range.alertClass = _alert_class;
                        _range.discountPesos = _discount_pesos;
                        _range.sale = _sale;

                        return _range;
                    });

                    return _curve;
                });
            }

            return _test;
        });


        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [..._list]
        });
    }

    const handleSearch = (e) => {
        let _searchWord = e.target.value;

        setSearchQuery(_searchWord);
    }

    //#region csv exam and profile

    //*VALID PRICES AND CSV

    const [modalListEgress, setModalListEgress] = useState(false);
    const [listCode, setListCode] = useState([]);
    const [listRepit, setListRepit] = useState([])
    const [datacsv, setDatacsv] = useState([]);


    const toggleModalListEgress = () => {
        setModalListEgress(!modalListEgress);
    }

    const handleCsvLoading = async () => {

        let _listCSV = datacsv;

        var dataTable = [];

        _listCSV.data.forEach(_data => {
            dataTable.push(_data)
        });

        if (dataTable.length > 0) {

            await getSearchCode(dataTable).then(x => {

            });
        }
    }

    //*SEARCH CODE CSV

    const getSearchCode = async (dataTable) => {
        let _examsAndProfile = dataTable;
        let _epCode = [];
        let _arrayExist = [];
        let _arrayNotExist = [];

        _examsAndProfile.forEach((_key) => {
            _epCode.push({
                id: _key[0],
                code: _key[1],
            });
        });
    
        await Promise.all(
            _epCode.map(async (_value) => {
                if (_value.id !== undefined && _value.id !== "") {
                    let _value1 = _value.id === undefined || _value.id === " " ? 0 : _value.id;
    
                    let _list = await _handleRequest("GET", `Profiles/SearchCodeProfileExam?query=${_value1}`);
    
                    if (_list.length > 0) {
                        var _tesCode = _list;
    
                        _tesCode.forEach((_id) => {
                            let _test_split = _id.id_code.split('-');
                            let _csv = "csv";
                            let _id_test = _test_split[0];
                            let _type_test = _test_split[1];
    
                            let _valid_exist_test = list_agrement_test.find((x) => (_type_test === "0" ? x.id_exam === parseInt(_id_test) : x.id_profile === parseInt(_id_test)));
    
                            if (_valid_exist_test !== undefined) {
                                _arrayNotExist.push({
                                    code: _value.id,
                                })
                                setExamProfileState({});
                            } else {
                                if (_type_test === "0") {
                                    getExam(_id_test, _csv, _value.code,_value.id);
                                } else {
                                    getProfiles(_id_test, _csv, _value.code,_value.id);
                                }
                            }
                        });
                    } else {
                        _arrayExist.push({
                            code: _value.id,
                        });
                    }
                }
            })
        );
    
        setListCode(_arrayExist);
        setListRepit(_arrayNotExist);
        toggleModalListEgress();
    };


    //#endregion csv exam and profile

    //*CALCULATE PERCENTAGE

    const handlemove = (e, _id_range, _id_exam = null, _id_profile = null, _id_profile_curve_price = null) => {
        let _list = list_agrement_test;

        _list.map(_test => {

            if ((_test.id_exam !== null || _test.id_profile !== null) && !_test.is_curve) {

                let _price = _test.price;
                let _subtotal = 0;

                _test.listAgreementTestRanges.map(_range => {

                    let _value = _range.sale
                    var porcentaje = ((_price - _value) / _price) * 100;

                    let _percentage = _value !== '' ? parseFloat(_value) : 0;

                    let _calculate_percentage = ((_percentage * _price) / 100).toFixed(2);
                    let _discount_pesos = 0;
                    let _sale = 0;

                    _subtotal = (_price - _calculate_percentage).toFixed(2);
                    _discount_pesos = _calculate_percentage;
                    _sale = _subtotal;
                    _range.percentage = porcentaje;
                    _range.sale = _value;

                    return _range;
                });
            }

        })

        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [..._list]
        });

        toggleModalListEgress()
    }
    //#endregion
   console.log("estrellas",formAgreement);
   

    //#region METHODS BY POST
    const saveOrUpdateAgreement = async () => {

        const api = treeViewRef.current.api;
        const selected_branch = api.getItems();
        setLoading(true);


        if (validationsAgreementForm()) {
            setLoading(false);
            return;
        }


        let _body = {
            id_agreement: formAgreement.id_agreement,
            id_company: typeFilterAgreement === 1 ? formAgreement.id_company.value : null,
            id_doctor: typeFilterAgreement === 2 ? formAgreement.id_doctor.value : null,
            id_pay_method: formAgreement.id_pay_method,
            id_financing_methods: typeFilterAgreement === 1 ? formAgreement.id_financing_methods : null,
            id_commercial_line: formAgreement.id_commercial_line.value,
            id_seller: typeFilterAgreement === 1 ? formAgreement.id_seller.value : null,
            id_use_cfdi: typeFilterAgreement === 1 ? formAgreement.id_use_cfdi : null,
            contract_number: formAgreement.contract_number,
            validity: formAgreement.validity,
            payment_terms: "",
            sales_name: typeFilterAgreement === 1 ? formAgreement.sales_name : null,
            sales_phone: typeFilterAgreement === 1 ? formAgreement.sales_phone : null,
            sales_email: typeFilterAgreement === 1 ? formAgreement.sales_email : null,
            payment_name: typeFilterAgreement === 1 ? formAgreement.payment_name : null,
            payment_phone: typeFilterAgreement === 1 ? formAgreement.payment_phone : null,
            payment_email: typeFilterAgreement === 1 ? formAgreement.payment_email : null,
            observations: formAgreement.observations,
            swift_code: typeFilterAgreement === 1 ? formAgreement.swift_code : null,
            is_global: formAgreement.is_global,
            payment_periodicity: typeFilterAgreement === 1 ? formAgreement.payment_periodicity : null,
            credit_limit: typeFilterAgreement === 1 ? formAgreement.credit_limit : null,
            percentage_credit_limit: typeFilterAgreement === 1 ? formAgreement.percentage_credit_limit : null,
            cant_sample: typeFilterAgreement === 1 ? formAgreement.quantity_sample : 0,
            checksamplecant: typeFilterAgreement === 1 ? formAgreement.checkSpecimenQuantity : false,
            listAgreementTest: formAgreement.list_agrement_test,
            commercialBranchTreeView: selected_branch,
            company_category: typeFilterAgreement === 1 ? informationCompany.category : null,
            sample_periodicity: typeFilterAgreement === 1 ? formAgreement.sample_periodicity : null,
            is_micro: formAgreement.is_micro,
            is_pay_for_company: formAgreement.is_pay_for_compamy,
            required_invoice_company: formAgreement.required_invoice_company,
            charge_emergency: formAgreement.charge_emergency,
            border_tax : formAgreement.border_tax === "16" ? false : true, //? CONFIGURATION IVA
            address_sample_taking: formAgreement.address_sample_taking 
        };
        console.log("🚀 ~ saveOrUpdateAgreement ~ _body:", _body)

       const _response = await _handleRequest("POST", "Agreement/Create", "Crear convenio", _body);
 
        if (_response !== null) {
            setLoading(false);

            createSweet("success", "success", globalAction === "update" ? "Se actualizo correctamente" : "Se creo correctamente");

            setTimeout(() => {

                if (globalAction === "update") {
                window.location.href = `${process.env.PUBLIC_URL}/convenios`
                }
                else {
                    let value = "" + _response.id_agreement + "";
                    history.push(`../convenios/${value}/editar-convenio`);
                }
                

            }, 1000);
        }

        setLoading(false);

    }
    //#endregion

    //*CSV
    const [file, setFile] = useState("");


    const handleOnChange = (e) => {
        setFile(e.target.files[0]);

    };

     //SUBIR ARCHIVO CSS
     const [view_exams, setView_exams] = useState(false)
     const ExamIndication = async (e) => {

        e.preventDefault();
        setView_exams(true)

        var formData = new FormData();

        let id_agreement = formAgreement.id_agreement

        formData.append('id_agreement', id_agreement);
        formData.append('file', file);

        let requestOptions = {
            method: 'POST',
            body: formData
        };

         const respuesta = await sendRequest(requestOptions, "Agreement/MassiveLoad", 'multipart/form-data');
         //console.log(respuesta);
        if (respuesta.code === 200) {
            setView_exams(false)

            createSweet("create", "info", "Exito!", "Estudios actualizadas");

            setTimeout(() => {
             window.location.reload()
            }, 1000);
           
        }
      
    }

 

   
    //#region VALIDATIONS
    const validationsAgreementForm = () => {
        let statusValidation = false;
        let newValidations = {};
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        if (typeFilterAgreement === 1) {
            if (formAgreement.id_company.value === undefined) {
                newValidations = {
                    ...newValidations,
                    id_company_valid: true
                };

                statusValidation = true;
            }
            else {
                newValidations = {
                    ...newValidations,
                    id_company_valid: false
                };
            }
        }

        if (typeFilterAgreement === 2) {
            if (formAgreement.id_doctor.value === undefined) {
                newValidations = {
                    ...newValidations,
                    id_doctor_valid: true
                };

                statusValidation = true;
            }
            else {
                newValidations = {
                    ...newValidations,
                    id_doctor_valid: false
                };
            }
        }

        if (formAgreement.id_commercial_line.value === undefined) {
            newValidations = {
                ...newValidations,
                id_commercial_line_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_commercial_line_valid: false
            }
        }

        if (formAgreement.id_pay_method === "" || formAgreement.id_pay_method === "0" || formAgreement.id_pay_method === 0) {
            newValidations = {
                ...newValidations,
                id_pay_method_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                id_pay_method_valid: false
            }
        }

        if (typeFilterAgreement === 1) {

            if (formAgreement.id_financing_methods === "" || formAgreement.id_financing_methods === "0" || formAgreement.id_pay_method === 0) {
                newValidations = {
                    ...newValidations,
                    id_financing_methods_valid: true
                };

                statusValidation = true;
            }
            else {
                newValidations = {
                    ...newValidations,
                    id_financing_methods_valid: false
                };
            }
            if (formAgreement.id_use_cfdi === "" || formAgreement.id_use_cfdi === "0" || formAgreement.id_use_cfdi === 0) {
                newValidations = {
                    ...newValidations,
                    id_use_cfdi_valid: true
                };

                statusValidation = true;
            }
            else {
                newValidations = {
                    ...newValidations,
                    id_use_cfdi_valid: false
                };
            }

            if (formAgreement.id_seller.value === undefined) {
                newValidations = {
                    ...newValidations,
                    id_seller_valid: true
                };

                statusValidation = true;
            }
            else {
                newValidations = {
                    ...newValidations,
                    id_seller_valid: false
                };
            }

            if (formAgreement.payment_periodicity === "") {
                newValidations = {
                    ...newValidations,
                    payment_periodicity_valid: true
                };

                statusValidation = true;
            }
            else {
                newValidations = {
                    ...newValidations,
                    payment_periodicity_valid: false
                }
            }

            if (formAgreement.id_financing_methods !== "" && formAgreement.id_financing_methods !== "0") {
                if (formAgreement.id_financing_methods == 3) {
                    if (formAgreement.credit_limit === "" || formAgreement.credit_limit === "0" || formAgreement.credit_limit === 0) {
                        newValidations = {
                            ...newValidations,
                            credit_limit_valid: true
                        };

                        statusValidation = true;
                    }
                    else {
                        newValidations = {
                            ...newValidations,
                            credit_limit_valid: false
                        };
                    }
                }
            }

            if (informationCompany.category !== undefined && informationCompany.category === 2) {
                if (formAgreement.percentage_credit_limit === "" || formAgreement.percentage_credit_limit === 0) {
                    newValidations = {
                        ...newValidations,
                        percentage_credit_limit_valid: true
                    };

                    statusValidation = true;
                }
                else {
                    newValidations = {
                        ...newValidations,
                        percentage_credit_limit_valid: false
                    };
                }
            }


            if (formAgreement.checkSpecimenQuantity) {

                if (formAgreement.quantity_sample === "" || formAgreement.quantity_sample === "0" || formAgreement.quantity_sample === 0) {
                    newValidations = {
                        ...newValidations,
                        quantity_sample_validity: true
                    };

                    statusValidation = true;
                }
                else {
                    newValidations = {
                        ...newValidations,
                        quantity_sample_validity: false
                    };
                }
            }
        }

        if (formAgreement.contract_number.length <= 2) {
            newValidations = {
                ...newValidations,
                contract_number_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                contract_number_valid: false
            };
        }

        if (formAgreement.validity === "") {
            newValidations = {
                ...newValidations,
                validity_valid: true
            };

            statusValidation = true;
        }
        else {
            newValidations = {
                ...newValidations,
                validity_valid: false
            };
        }

        setValidationsAgreement(newValidations);

        return statusValidation;
    }
    //#endregion

    //FORMAT NUMBERS
    const number_format = (cantidad, decimals) => {
        cantidad += '';
        cantidad = parseFloat(cantidad.replace(/[^0-9\.]/g, ''));

        decimals = decimals || 0;

        if (isNaN(cantidad) || cantidad === 0) {
            return parseFloat(0).toFixed(decimals);
        }

        cantidad = '' + cantidad.toFixed(decimals);

        let cantidad_parts = cantidad.split('.'),
            regexp = /(\d+)(\d{3})/;

        while (regexp.test(cantidad_parts[0]))
            cantidad_parts[0] = cantidad_parts[0].replace(regexp, '$1' + ',' + '$2');

        return cantidad_parts.join('.');
    }

    const _handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {
            return response.data;
        }
        else if (response.code === 401) {
            validarSesion(history, response.code, _handleRequest);
        }
        else if (response.code === 500) {
            createSweet("error", "error", nameMethod, "Ocurrio un error en el servidor");
        }
        else {
            createSweet("warning", "warning", nameMethod, response.data.msg);
        }

        return null;
    }

    //#region sanitaria
    const on_check_sanitary = async () => {
        if (formAgreement.is_micro == true) {
            handleUpdateValuesA({
                ...formAgreement,
                is_micro: false
            });
        } else {
            handleUpdateValuesA({
                ...formAgreement,
                is_micro: true
            });
        }
    }

    const handleCant = (i, _id_exam = null, _id_profile = null) => {

        let e = i.target.value;

        let find_test = list_agrement_test;
        find_test.map(x => {
            if (_id_exam !== null) {
                if (x.id_exam == _id_exam) {
                    x.cant_sample = e;
                }

            } else {
                if (x.id_profile == _id_profile) {
                    x.cant_sample = e;
                }
            }

            return x;
        })
        handleUpdateValuesA({
            ...formAgreement,
            list_agrement_test: [...find_test]
        });

        setExamProfileState({});
    }
    //#endregion

    return {
        //#region AGREEMENTS LIST
        listCompanies, listPayForms, listFinancingMethods, listUseCfdi, listSellers, listCommercialLines,
        ListBranchesTree, listDoctors,
        //#endregion

        //#region AGREEMENTS MODELS
        informationCompany, informationDoctor, examProfileState, loading, globalPercentage, loadingAgreement, typeFilterAgreement,
        pageSizeSelectDoctor,

        //FORM
        id_agreement, id_company, id_doctor, id_pay_method, id_financing_methods, id_commercial_line, id_seller, id_use_cfdi,
        contract_number, validity, sales_name, sales_phone, sales_email, payment_name, payment_phone,
        payment_email, observations, swift_code, is_global, payment_periodicity, credit_limit, percentage_credit_limit,
        checkSpecimenQuantity, quantity_sample, list_agrement_test, is_pay_for_compamy, required_invoice_company,

        //FORM METHODS
        handleInputChangeA, handleSelectValuesA,

        //REFS
        treeViewRef,
        //#endregion

        //#region VALIDATIONS AGREEMENTS
        validationsAgreement, visibilityInputs,
        //#endregion    

        //#region AGREEMENTS METHODS
        handleChangeCompany, handleChangeDoctor, handleSelectTest, handleChangeRange, handleChangePercentage,
        handleDeleteRange, handleAddRange, handleDeleteTest, handleChangePrice, handleGoBack,
        saveOrUpdateAgreement, handleCalculateGlobalPercentage, handleChangeTypeFilter, handleScrollPaginingDoctor,

        //#endregion

        number_format,
        setDatacsv, handleCsvLoading,

        //SANITARY
        check_sanitary, on_check_sanitary, handleCant, sample_periodicity, is_micro,

         //RETURN PAGINATION
         goNextPage, goPreviousPage, totalPages, actualPage, goToPage, 
         prevPage, nextPage, totalRecords, resultCount, handleSearch,

         modalListEgress,toggleModalListEgress,
         listCode,handlemove,listRepit,priceTarifarion,profileT,
         //* MODAL DELETE TEST
         modalCancellation,method,toggleModalCancellation,handleTest,modalTitle,informationExams,
         handleDeleteTestCreate,
         //*CSV
         file,handleOnChange,ExamIndication,view_exams,

         //*Urgency
         charge_emergency,border_tax,

         address_sample_taking
    }
}
