import React from 'react'
import { Col, Modal, ModalBody, CardBody, Button,Row } from 'reactstrap';

export const ModalPreviewSamplings = (props) => {

    const {modalSamplings,handleToggleSamplings,saveSamplings,loadingSamplings,formMicSampling} = props;

    return (
        <Modal isOpen={modalSamplings} toggle={handleToggleSamplings} keyboard={false} centered={true} >
            <ModalBody>
            <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
            {loadingSamplings &&<Row className='mt-2'>
                <Col sm="12" md="12" lg="12" className='text-center p-l-0 p-r-0'>
                <img alt="flask" className='animate__animated animate__pulse animate__infinite'  src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: "100px", height: "100px" }} />
                <br />
                <label className='txt-secondary f-w-600 f-14 pt-4'>Guardando datos de la muestra:</label>&nbsp;&nbsp;
                <Col sm="3" className='badge-detail-micro' >
                    <Row>
                    <Col sm="3" className='p-r-0' >
                    <i className="fa fa-angle-right f-12"></i>
                    </Col>
                    <Col sm="9" className='p-l-0 p-r-0' style={{textAlign:"justify"}} >
                    {formMicSampling.sample_Form.folio ?? "--"}
                    </Col>
                    </Row>
                </Col>
                </Col>
                </Row>}
            {!loadingSamplings && <Row>
                <Col sm="12" style={{textAlign:"center"}}>
                <img alt="flask"  src={require("../../../assets/images/price/warningRecollected.svg")} style={{ width: "90px", height: "90px" }} />
                </Col>
                <Col sm="12" className='pt-1' style={{textAlign:"center"}}>
                <label className='txt-danger f-14 f-w-600' >Esta seguro de guardar los siguientes cambios </label> <br />
                </Col> 
            </Row>} 
                <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                <Row>
                    <Col sm="8" className='p-r-0'>
                    <Button disabled={loadingSamplings ? true : false} color={loadingSamplings ? "primary text-center" : "warning text-center" }  type="button" onClick={() => saveSamplings()}   >{"Aceptar"}</Button>
                    </Col>
                    <Col sm="2">
                    <Button disabled={loadingSamplings ? true : false} color= "danger text-center" type="button" onClick={handleToggleSamplings} >{"cancelar"}</Button>
                    </Col>
                </Row>
                </Col> 
            </CardBody>
            </ModalBody>
        </Modal>
    )
}