
const calculateWithTax = (_price,_iva) => {

    const formatAmount = (amount, decimals) => {
        return parseFloat(
            (Math.trunc(amount * 100000000) / 100000000).toFixed(decimals)
        );
    };

    let price = 0;

    let _valueIva = 1 + (_iva / 100);

    price = (_price / _valueIva);

    price =  formatAmount(price,  2)

    return price;
}
    
export default calculateWithTax;

export function OperationsAmounts(
    quantity,
    price,
    isObjectIva,
    valueOfTaxIva,
    amountDiscount,
) {


    let unitaryPrice = price;
    const decimalIva = 1 + (valueOfTaxIva / 100); //! 1.16
    const valueOfTax =  (valueOfTaxIva / 100); //! 0.16
    let importP = 0;
    let subtotal = 0;
    let discount = 0;
    let importIva = 0;
    let total = 0;

    // ? REMOVE TAXES
    if (isObjectIva)
    {
        unitaryPrice = parseFloat(
            (
                Math.trunc( (unitaryPrice / decimalIva) *  100000000) / 100000000
            ).toFixed(6)
        )
    }

    importP = parseFloat(
        (Math.trunc(unitaryPrice * quantity * 100000000) / 100000000).toFixed(6),
    );

    subtotal = importP;

    // * CALCULATE DISCOUNT AFTER REMOVE TAXES
    if (amountDiscount > 0 && unitaryPrice > 1)
    {
        const amountDiscountByPercentage = parseFloat(
            (
                Math.trunc(((subtotal * amountDiscount) / 100) * 10000) / 10000
            ).toFixed(2)
        );

        subtotal = parseFloat(
            (
                Math.trunc((subtotal - amountDiscountByPercentage) * 10000) / 10000
            ).toFixed(2)
        );

        discount = amountDiscountByPercentage;
    }
    

    importIva = parseFloat(
        (Math.trunc(subtotal * valueOfTax * 100000000) / 100000000).toFixed(6),
    );

    total = parseFloat(
        (Math.trunc((subtotal + importIva) * 100000000) / 100000000).toFixed(6),
      );

    return {
        unitary_price: unitaryPrice,
        import: importP,
        subtotal: subtotal,
        discount: discount,
        total_tax: importIva,
        total: total
    };
}