

const filterValidations = (_start_date,_end_date,_id_company_search,_id_patient_search,_code_quatation) => {

    let query = "";
   
    if (_start_date !== null && _end_date !== null && _id_company_search === null  && _id_patient_search === null && _code_quatation === null  ) {
        query += `from=${_start_date}&to=${_end_date}`;          
    }

    if (_code_quatation !== null  && _start_date === null  && _end_date === null ) {
        query += `code=${_code_quatation}`;                      
    }

    if (_id_patient_search !== null && _start_date === null  && _end_date === null  && _id_company_search === null ) {
        query += `id_patient=${_id_patient_search}`;             
    }

    if (_id_company_search !== null && _start_date === null  && _end_date === null && _id_patient_search === null  ) {                
        query += `id_company=${_id_company_search.value}`;                   
    }

    if (_id_company_search !== null && _start_date !== null && _end_date !== null ) {
        query += `id_company=${_id_company_search.value}&from=${_start_date}&to=${_end_date}`;             
    }

    if (_id_patient_search !== null && _start_date !== null && _end_date !== null ) {
        query += `patient=${_id_patient_search}&from=${_start_date}&to=${_end_date}`; 
    }

    if (_code_quatation !== null && _code_quatation !== "" && _start_date !== null && _end_date !== null) {
        query += `code=${_code_quatation}&from=${_start_date}&to=${_end_date}`;                      
    }

    if (_id_patient_search !== null && _id_company_search  !== null  ) {        
        query += `1`;       
    }

    if (_start_date === null && _end_date !== null) {
        query += `2`;       
    }

    if (_start_date !== null && _end_date === null) {
        query += `3`;                  
    }
    return query;
}
    
export default filterValidations;