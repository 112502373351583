import React from 'react'
import { Col, Modal, ModalBody, CardBody, Button } from 'reactstrap';

export const ModalViewExamsQuoter = (props) => {

    const {modalExams, handleToggleExams, listExams} = props;

    return (
        <Modal isOpen={modalExams} toggle={handleToggleExams} keyboard={false} centered={true} >
            <ModalBody>
            <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                <Col sm="12">
                <h6 className='txt-secondary'>Exámenes que conforman el perfil</h6>
                <div className='pt-3'>
                {
                    listExams.map( (_exam, _key) => {
                        return (
                            <div key={'exam-'+_key}>
                                <img alt="flask" className="img-fluid text-info mr-2" src={require("../../../assets/images/price/typeService.svg")} style={{ width: "28px", height: "28px" }} />&nbsp; <label style={{fontSize:"12px"}}>{_exam?.name ?? "-----"}</label> 
                            </div>
                        )
                    })
                }
                </div>
                </Col>
                <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                    <div className="btngroup">
                        <Button color="danger text-center" type="button" onClick={handleToggleExams}>{"Aceptar"}</Button>
                    </div> 
                </Col>
            </CardBody>
            </ModalBody>
        </Modal>
    )
}
