import { sendRequest } from "../../hooks/requests/useRequest";

export async function getTypeSamples ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `TypeSamples/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function saveSampling({
    date_collected, factor_correction,folio, id_mic_detail_sample, id_mic_sample, id_mic_sampling, id_mic_site,id_mic_site_areas,id_mic_type_sample,id_patient,id_quoter,id_quoter_type_sample,id_thermometer,not_collected,observations,quantity,required_temperature,temperature
}) {
    const body = {
        date_collected,
        factor_correction, 
        folio,
        id_mic_detail_sample,
        id_mic_sample,
        id_mic_sampling,
        id_mic_site,
        id_mic_site_areas,
        id_mic_type_sample,
        id_patient,
        id_quoter,
        id_quoter_type_sample,
        id_thermometer,
        not_collected,
        observations,
        quantity,
        required_temperature,
        temperature
    };


    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "QuoterDeterminations/SaveIndividualSample"
    );

    if (response.code === 200)
        return {
            code: response?.code,
            data: response?.data,
        }

    else if (response.code === 401)
        return response.data;
    else
    return response.data;

}

export async function getSites ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Sites/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getAreas (_id_mic_site)
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `SitesAreas/List/${_id_mic_site}`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getSamples ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `SampleMic/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getThermometers ()
{
    const requestOptions = {
        method: "GET",
    };

    const response = await sendRequest(requestOptions, `Thermometers/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}


export async function saveQuoter({
    cotizacion,observations,firm_sampler,firm_client,firm_superv,sampling_plan
}) {
    const body = {
        cotizacion,
        observations,
        firm_sampler,
        firm_client,
        firm_superv,
        sampling_plan  
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "QuoterDeterminations/SaveQuoter"
    );

    if (response.code === 200)
        return {
            code: response?.code,
            data: response?.data,
        }

    else if (response.code === 401)
        return response.data;
    else
    return response.data;

}


export async function saveWorkOrderMicro({
    id_branch, id_commercial_line,id_quoter, iva, listPayMethods, required_invoice_patient
}) {
    const body = {
        id_branch,
        id_commercial_line, 
        id_quoter,
        iva,
        listPayMethods,
        required_invoice_patient,
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "WorkOrder/CreteMicro"
    );

    if (response.code === 200)
        return {
            code: response?.code,
            data: response?.data,
        }

    else if (response.code === 401)
        return response.data;
    else
    return response.data;

}








