export const NameExam = "¿Cuál es el motivo del estudio? ";

export const hourExam = "¿A qué hora fue su última comida?" ;

export const hour2Exam = "¿A qué hora comió por última vez?" ;

export const activity ="¿Realizo alguna actividad deportiva previa a venir al laboratorio?";

export const activity2 ="¿Realizó alguna actividad física previa a venir al laboratorio?";

export const medicine ="¿Toma algún medicamento? ";

export const medicine2 ="¿Tomó algún tipo de medicamento (antibiótico, analgésico, vitaminas, etc).? ";

export const pomade ="¿Está aplicando solución antiséptica o pomada?";

export const pomade2 ="¿Está tomando algún tipo de medicamento?";

export const pregnancy = "¿Usted se encuentra en este momento embarazada?";

export const procedure = "¿Se le notificó cuál es el procedimiento de la toma?";

export const relatives = "¿Padece o tiene antecedentes familiares con?";

export const symptoms = "¿Ha presentado eventos de mareo, vomito, desmayo durante la toma de muestra?";

export const notification = "¿Se le notificó cuál es el procedimiento de la toma?";

export const alergy = "Presenta alergia a (marque todas las opciones que apliquen):";







